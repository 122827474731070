import supportedCountries from '@nike/web-shell-locale-data/data/supportedCountries.json';
import { PREVIEW_REGEXP, segmentProdKey, segmentTestKey } from './constants';

export const shouldBePlacedAppsFlyer = (countryCode, isHomepage) =>
  countryCode === 'cn' && isHomepage;

export const shouldBePlacedSmartBanner = (countryCode, isHomepage) =>
  countryCode === 'cn' && isHomepage;

function removeCloudProxyPart(url) {
  return url.replace(/^\/browse\/cloud_proxy\/v1\/[^/]*/, '');
}

export const isWeb = () => typeof window !== 'undefined';

export const isProdDomain = () => {
  if (isWeb()) {
    return window.location.host === process.env.NEXT_PUBLIC_HOST_NAME;
  }
  return false;
};

export const isPreview = () => {
  if (isWeb()) {
    return PREVIEW_REGEXP.test(removeCloudProxyPart(window.location.pathname));
  }
  return false;
};

export const getSegmentKey = () => {
  if (isWeb() && isProdDomain() && !isPreview()) {
    return segmentProdKey;
  }

  return segmentTestKey;
};

export const shouldLoadNikeShopClientScript = countryCode => {
  const { nikedotcom } = supportedCountries;
  return nikedotcom.includes(countryCode);
};
