import { createSelector } from 'reselect';
import { ownPropsSelector } from '../../utils/ownPropsSelector';

export const storageSelector = state => state.storage;

export const subscriptionStatusSelector = createSelector(
  storageSelector,
  ownPropsSelector,
  (storage, productId) => storage?.[productId],
);
