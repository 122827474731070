import { getThreads } from './getThreads';

export const getThreadByCollectionId = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: Thread.RequestFilters & {
    collections: string[];
    layout: boolean;
  };
}): Promise<Thread.Response> =>
  getThreads({
    fetchFn,
    params: {
      count: 1,
      sort: 'publishedContent.publishStartDateDesc',
      ...params,
    },
  });
