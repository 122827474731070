export const PDP_URL_TYPES = {
  NIKEDOTCOM: 'NIKEDOTCOM',
  NIKEDOTCOM_LEGACY: 'NIKEDOTCOM_LEGACY',
  NIKEID: 'NIKEID',
  SNKRS: 'SNKRS',
};
export const CARD_DYNAMIC_TYPES = {
  EDITORIAL: 'editorial',
  EXTERNAL_COLLECTION: 'external_collection',
  INTERESTS: 'interests',
  LOCAL_NAV: 'local_menu',
  MERCH_MENU: 'merch_menu',
  PRODUCT_RECOMMENDER: 'product_recommender',
  PRODUCT_RECOMMENDER_TAXONOMY: 'product_recommender_taxonomy',
  PRODUCT_WALL: 'productWall',
  SIZE_CHARTS: 'size_chart',
  SNKRS_DROPS: 'snkrs_drops',
  TEAM_SELECTOR: 'team_selector_menu',
  TOP_TRENDING: 'top_trending',
};

export const CARD_ACTION_TYPES = {
  BUTTON: 'button',
  BUYING_TOOLS: 'cta_buying_tools',
  CARD_LINK: 'card_link',
  LINK: 'link',
  STACKED_CTA: 'stacked_cta',
};

export const CARD_ACTION_DESTINATION_TYPES = {
  POPUP: 'popup',
  PRODUCT_COLLECTION: 'product_collection',
};

export const BUYING_TOOLS_LABEL = {
  AVAILABLE: 'shopLabel',
  COMING_SOON: 'notifyMeLabel',
  SOLD_OUT: 'soldOutLabel',
};

export const BUYING_TOOLS_STATUS = {
  AVAILABLE: 'AVAILABLE',
  COMING_SOON: 'COMING_SOON',
  SOLD_OUT: 'SOLD_OUT',
};

export const MEDIA_QUERIES = {
  desktop: 'large',
  mobile: 'small',
  tablet: 'medium',
};

export const URL_TYPE = {
  ARTICLE: 'ARTICLE',
  GRIDWALL: 'GRIDWALL',
  PAGE: 'PAGE',
  PDP: 'PDP',
  PDP_NBY: 'PDP_NBY',
};

export const IMAGE_SIZES_MAP = {
  EXTRA_LARGE: 'extra_large',
  EXTRA_SMALL: 'extra_small',
  LARGE: 'large',
  MAINTAIN: 'maintain',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const EDITORIAL_TYPES = {
  ARTICLE_FOOTER: 'article_footer',
  DYNAMIC_CAROUSEL: 'dynamic_carousel',
  DYNAMIC_GRID: 'dynamic_grid',
  TOOLS_AND_SUPPLIES: 'tools_and_supplies',
};

export const TEMPLATE_TYPES = {
  FILMSTRIP: 'filmstrip',
  GRID: 'grid',
};

export const TEXT_TEMPLATE_TYPES = {
  PULL_QUOTE: 'Pull Quote',
};

export const CARD_TYPES = {
  BRA_SIZE_FINDER: 'sizewizard',
  CAROUSEL: 'carousel',
  CMS_COLLECTION: 'cms_collection',
  DYNAMIC: 'dynamiccontent',
  DYNAMIC_RELATED_FILMSTRIP: 'dynamic_related_content',
  EDITORIAL: 'editorial',
  EDITORIAL_V2: 'editorial_taxonomy',
  FAQ: 'faq',
  FILMSTRIP: 'filmstrip',
  IMAGE: 'image',
  LISTICLE: 'steps',
  META_BOX: 'meta_box',
  RELATED_FILMSTRIP: 'related_content',
  SECTION_HEADLINE: 'section_headline',
  SHOP_THE_LOOK: 'shop_the_look',
  SLIDESHOW: 'slideshow',
  TEXT: 'text',
  TITLE: 'title',
  VIDEO: 'video',
  ...CARD_DYNAMIC_TYPES,
  ...EDITORIAL_TYPES,
};

export const GALLERY_CARD_TYPES = [
  CARD_TYPES.FILMSTRIP,
  CARD_TYPES.DYNAMIC_CAROUSEL,
  CARD_TYPES.RELATED_FILMSTRIP,
  CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP,
  CARD_TYPES.SLIDESHOW,
  CARD_TYPES.BRA_SIZE_FINDER,
];

export const EDITORIAL_MAPPER = {
  [TEMPLATE_TYPES.FILMSTRIP]: EDITORIAL_TYPES.DYNAMIC_CAROUSEL,
  [TEMPLATE_TYPES.GRID]: EDITORIAL_TYPES.DYNAMIC_GRID,
};

export const SHOP_THE_LOOK_CARD_PREFIX = 'shop-the-look/';

export const CAPTION_POSITIONS = {
  BELOW: 'below',
  OVERLAY: 'overlay',
};

export const SUBSCRIPTION_STATUSES = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
};

export const LOCAL_NAV_PLACEHOLDER_CARD_ID = 'local_nav_placeholder';
export const ARTICLE_FOOTER_PLACEHOLDER_CARD_ID = 'article_footer_placeholder';
export const META_BOX_PREFIX = 'meta_box_card';
export const TOOLS_AND_SUPPLIES_GRID_PREFIX = 'tools-and-supplies-text-card-';

export const DYNAMIC_CONTENT_SUPPORTED_CARD_TYPES = [
  CARD_TYPES.IMAGE,
  CARD_TYPES.VIDEO,
];

export const ROLES_UUIDS = {
  AGENCY: '28fb1604-e9bd-490d-b53e-3574b62df610',
  ANIMATOR: '91f76a3e-390d-4bbb-bbf6-63f1bb0b7f1a',
  BEHIND_THE_SCENES_PHOTOGRAPHER: '52bb810b-4984-447e-96f9-146bedfd2b23',
  CREATOR_ROLE_PARENT: '25db71da-773e-4cfe-8ffb-8787e9120c02',
  DIRECTOR: '176e2a86-4ae7-4b22-9c8c-632296dbb991',
  DIRECTOR_OF_PHOTOGRAPHY: '582495cd-b4c1-4cbc-8a88-f27a8897d9fd',
  GRAPHIC_DESIGNER: '6c40eb82-91bb-41d1-b446-ffc908468ed5',
  ILLUSTRATOR: '9cd0b479-caae-40c3-aba0-85245b52400b',
  PHOTOGRAPHER: '1557cec2-d8c5-4e54-a980-a1e5f2883ee7',
  PRODUCTION_COMPANY: 'aa2cf6bf-04dd-4303-bbce-2caa4c21fc55',
  SECONDARY_PHOTOGRAPHER: '5203d14c-79a9-4208-8c7c-d3c4b7c5ad2c',
  STOCK_FOOTAGE_HOUSE: '38da110d-6418-42d2-b3fc-5fa664d3cf1b',
  WRITER: '978c1a33-a6c3-4174-9929-3669a34dcf6a',
};

export const PRODUCT_IMAGE_CLOUDINARY_PARAMS = [
  'q_auto:eco',
  't_product_v1',
  'f_auto',
  'w_330,c_limit',
];

export { DEFAULT_TEXT_LOCATION } from '../store/data/constants';

export const PRODUCT_AVAILABILITY = {
  IN_STOCK: 'inStock',
  UPCOMING: 'upcoming',
};

export const ANALYTICS_TAB_INDEXES = {
  [PRODUCT_AVAILABILITY.IN_STOCK]: 1,
  [PRODUCT_AVAILABILITY.UPCOMING]: 2,
};

export const LAUNCH_SLUGS = {
  [PRODUCT_AVAILABILITY.IN_STOCK]: 'launch?s=in-stock',
  [PRODUCT_AVAILABILITY.UPCOMING]: 'launch?s=upcoming',
};

export const POPUP_MARKER = '#popup';
