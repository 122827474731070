import { CARD_TYPES } from '../../../constants';

import { getTextLocation } from '../helpers';
import { prepareGalleryCardData } from '../helpers/carouselHelper';
import { getGalleryActions, getGalleryPlacements } from '../helpers/analytics';
import { analyticsProperties } from '../../../analytics/constants';

export const prepareCardData = (cardData, data) => ({
  ...prepareGalleryCardData(cardData, data, CARD_TYPES.FAQ),
  textLocation: getTextLocation(cardData),
});

export { getBuyingToolsIngredients } from './common';

export const prepareTranslations = () => ({});

export {
  getGalleryUrlIngredients as getUrlIngredients,
  mergeGalleryProperties as mergeProperties,
} from '../helpers/carouselHelper/carouselHelper';

export { getGalleryPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card => [
  ...getGalleryActions(card),
  ...card.slides.map(slide => ({
    id: `${slide.id}_title`,
    [analyticsProperties.CLICK_ACTIVITY]: `con:0:nocta:${
      slide?.titleProps?.text ?? 'null'
    }`,
  })),
];
