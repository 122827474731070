import {
  syncActionsCreator,
  asyncActionsCreator,
} from '../utils/reduxActionHelper';

export const requestMetaActions = syncActionsCreator(['update'], 'requestMeta');

export const previewTokenActions = syncActionsCreator(
  ['update'],
  'previewToken',
);

export const optimizationActions = syncActionsCreator(
  ['loadLocations'],
  'optimization',
);

export const locationActions = syncActionsCreator(['update'], 'location');

export const translationActions = syncActionsCreator(
  ['update'],
  'translations',
);

export const analyticsActions = syncActionsCreator(
  ['init', 'page', 'track', 'initUser', 'prepareData'],
  'analytics',
);

export const featureFlagsActions = syncActionsCreator(
  ['update'],
  'featureFlags',
);

export const productApiAction = asyncActionsCreator('error', 'productApi');

export const bootstrapActions = asyncActionsCreator('bootstrap', 'bootstrap');

export const logActions = syncActionsCreator(['init', 'error', 'warn', 'info']);

export const privacyActions = syncActionsCreator(
  ['set', 'setNikePrivacy'],
  'privacy',
);

export const routerActions = syncActionsCreator(['init', 'update'], 'router');

export const dynamicContentActions = syncActionsCreator(
  ['insertItems', 'insertFeatured'],
  'dynamicContentActions',
);

export const snkrsDropsActions = syncActionsCreator(
  ['updateItems'],
  'snkrsDrops',
);

export const recommendationActions = syncActionsCreator(
  [
    'ready',
    'usePRSFallback',
    'putPRSSlides',
    'usePRSFallbackPartly',
    'withoutFallback',
  ],
  'personalization',
);

export const productsApiActions = asyncActionsCreator(
  'LOAD_PRODUCTS',
  'loadProducts',
);

export const productRecommenderApiAction = asyncActionsCreator(
  'LOAD_USER_RECOMMENDATIONS',
  'loadUserRecommendations',
);

export const pageThreadApiActions = asyncActionsCreator(
  'LOAD_PAGE_THREAD',
  'loadPageThread',
);

export const bannerFeedApiActions = asyncActionsCreator(
  'LOAD_BANNER_FEED',
  'loadLoadBannerFeed',
);

export const previewThreadApiActions = asyncActionsCreator(
  'LOAD_PREVIEW_THREAD',
  'loadPreviewThread',
);

export const urlAnalysis = syncActionsCreator(['set'], 'analysis');

export const urlGeneratorApiActions = asyncActionsCreator(
  'URL_GENERATOR',
  'urlGenerator',
);

export const productStatusesApiActions = asyncActionsCreator(
  'LOAD_PRODUCT_STATUSES',
  'loadProductStatuses',
);

export const previewPageApiActions = asyncActionsCreator(
  'PAGE_PREVIEW',
  'pagePreview',
);

export const notifiMeApiActions = asyncActionsCreator(
  'NOTIFY_ME_SUBSCRIBE',
  'notifyMeSubscribe',
);

export const cmsCollectionActions = syncActionsCreator(
  ['loaded', 'insert'],
  'cms_collections',
);

export const measurementActions = syncActionsCreator(['set'], 'measurement');

export const pathToRootActions = syncActionsCreator(['set'], 'pageData');

export const canonicalUrlActions = syncActionsCreator(['set'], 'canonicalUrl');

export const dynamicLocalNavActions = syncActionsCreator(
  ['insert', 'removePlaceholder'],
  'dynamicLocalNav',
);

export const identityStatusActions = syncActionsCreator(
  ['initialized', 'timedout'],
  'identityStatus',
);

export const impressionObserverActions = syncActionsCreator(
  ['init'],
  'impressionObservers',
);

export const variantPatchesActions = syncActionsCreator(
  ['setStatePatches', 'setRenderedVariantId'],
  'variantPatches',
);

export const contentFeatureActions = syncActionsCreator(
  ['setContentFeatures'],
  'contentFeatures',
);

export { optimizelyActions } from './optimizely';

export {
  shopTheLookProductsActions,
  shopTheLookProductsLoadedAction,
  shopTheLookSizesLoadAction,
  shopTheLookAddToCart,
  shopTheLookCartActions,
} from './shopTheLook';
