import { createSelector } from 'reselect';
import { dataSelector } from './dataSelector';
import { pageDataPropertiesSelector } from './pageSelectors';

export const showPromoBannerSelector = createSelector(
  pageDataPropertiesSelector,
  pageData => pageData?.showBanner ?? true,
);

export const bannerNodesSelector = createSelector(
  dataSelector,
  appData => appData?.banner?.nodes,
);
