import { getProducts } from './getProducts';
import { FetchParams } from '../../common';

export const getProductsByIds = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ProductFeed.RequestParamsFiltersByIds & FetchParams;
}): Promise<ProductFeed.Response> =>
  getProducts({
    fetchFn,
    params: {
      sort: 'productInfo.merchProduct.idGiven',
      ...params,
    },
  });
