import {
  APIError,
  DEFAULT_API_URL,
  customFetch,
  ErrorMessage,
  FetchParams,
} from '../../common';

export const getRecommendedProductList = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ProductRecommender.FetchOptions & FetchParams;
}): Promise<ProductRecommender.Response> => {
  const {
    marketplace,
    upmId,
    uniteJWT,
    callerId,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
  } = params;

  const path = `aiml/serving/productrecs/${marketplace.toLowerCase()}_landing_pages_v1_01`;
  const url = `${baseUrl}/${path}`;
  const requestOptions = {
    body: JSON.stringify({ upmId }),
    headers: {
      Authorization: `Bearer ${uniteJWT}`,
      'Content-Type': 'application/json',
      'nike-api-caller-id': callerId,
    },
    method: 'POST',
    path,
    timeout,
  };

  const result = await customFetch({
    fetchFn,
    options: requestOptions,
    url,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      originalMessage: e.message,
      requestBody: JSON.parse(requestOptions.body),
      requestOptions,
      url,
    });
  }
};
