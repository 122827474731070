import {
  both,
  complement,
  defaultTo,
  filter,
  is,
  isEmpty,
  map,
  pipe,
  prop,
} from 'ramda';

export const extractBuyingIngredientsFromList = pipe(
  defaultTo([]),
  map(prop('buyingToolsIngredients')),
  filter(both(complement(isEmpty), is(Object))),
);
