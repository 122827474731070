import { CARD_TYPES } from '../../../constants';
import { formatArticleDate, prepareBodyText } from '../helpers';

export const prepareCardData = (cardData = {}) => {
  const { attributions, originallyPublishedStamp } = cardData;
  const bodyText = prepareBodyText(attributions);

  return {
    bodyProps: {
      text: bodyText.join(''),
      textColor: '#757575',
    },
    containerType: CARD_TYPES.ARTICLE_FOOTER,
    originallyPublishedStamp,
    subType: CARD_TYPES.ARTICLE_FOOTER,
  };
};

export const prepareTranslations = translations => ({
  originallyPublishedTemplate:
    translations?.originally_published_label ?? 'Originally published: [date]',
});

export const mergeProperties = ({
  cardData,
  translations,
  languageMappings,
}) => {
  const mergedCard = {
    ...cardData,
    ...translations,
  };

  if (cardData.originallyPublishedStamp) {
    mergedCard.originallyPublished = formatArticleDate(
      new Date(cardData.originallyPublishedStamp),
      languageMappings,
    );
  }

  return mergedCard;
};

export {
  getAnalyticsPlacements,
  getAnalyticsActions,
  getBuyingToolsIngredients,
  getUrlIngredients,
} from './common';
