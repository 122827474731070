import { takeEvery, select } from 'redux-saga/effects';

/////////////////////
import clientLogger from '@nike/ciclp-utils/logger';
import { getClientTransports } from '@nike/ciclp-utils/logger/helpers';
import { FEATURE_FLAGS } from '@nike/ciclp-config';
import { featureFlagsSelector } from '../store/state/stateSelector';
/////////

import { logActions } from '../actions';
import { reportError } from '../utils/error';
import { logLevels } from '../enum/errors';

export const loggingSaga = function (logger, { type, payload }) {
  let level = logLevels.INFO;
  if (type === logActions.error.type) {
    level = logLevels.ERROR;
  } else if (type === logActions.warn.type) {
    level = logLevels.WARN;
  }
  reportError({ errorObj: payload, level, logger });
};

export const watchLoggingSaga = function* ({ payload: { logger } } = {}) {
  yield takeEvery(
    [logActions.error.type, logActions.warn.type, logActions.info.type],
    loggingSaga,
    logger,
  );
};

export const clientLoggerBootstrapSaga = function* () {
  const featureFlags = yield select(featureFlagsSelector);
  const isConsoleLoggingEnabled =
    featureFlags[FEATURE_FLAGS.ENABLE_CONSOLE_LOGGING];

  const clientLoggerTransports = getClientTransports({
    isConsoleLoggingEnabled,
  });
  clientLoggerTransports.forEach(transport =>
    clientLogger.addTransport(transport),
  );
};
