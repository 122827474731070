import { addIndex, chain, flatten, map } from 'ramda';
import { CARD_TYPES } from '../../../constants';
import {
  extractIngredientsFromList,
  getMerchMenuItems,
  mergeLinkWithIngredients,
} from '../helpers';
import { getClickActivity } from '../helpers/analytics';
import { analyticsProperties, eventNames } from '../../../analytics/constants';

export const prepareCardData = ({ id }, { nodes }) => ({
  containerType: CARD_TYPES.MERCH_MENU,
  items: getMerchMenuItems({ id, nodes }),
});

export const mergeProperties = ({ cardData, urls }) => ({
  ...cardData,
  items: cardData.items.map(({ links, ...header }) => ({
    ...mergeLinkWithIngredients({ action: header, property: 'url', urls }),
    ...(links && {
      links: links.map(link =>
        mergeLinkWithIngredients({ action: link, property: 'url', urls }),
      ),
    }),
  })),
});

export const getUrlIngredients = card =>
  flatten(
    card?.items.map(item => extractIngredientsFromList([item, ...item.links])),
  );

const chainWithIndex = addIndex(chain);
const mapWithIndex = addIndex(map);
export const getAnalyticsActions = card =>
  chainWithIndex(
    (menu, menuIndex) =>
      mapWithIndex(
        (link, linkIndex) => ({
          eventName: eventNames.NAVIGATION_CLICK,
          id: link.id,
          [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
            link.destinationType,
            null,
            link.label,
            'merch menu',
          ),
          [analyticsProperties.LOCAL_PLACEMENT_ID]: linkIndex + 1,
          [analyticsProperties.LOCAL_POSITION_ID]: menuIndex + 1,
        }),
        menu.links,
      ),
    card.items,
  );

export {
  getAnalyticsPlacements,
  getBuyingToolsIngredients,
  prepareTranslations,
} from './common';
