import { featureFlagsActions } from '../../../actions';
import createReducer from '../../../utils/createReducer';

export default createReducer(
  {},
  {
    [featureFlagsActions.update.type]: (state, { payload = {} }) => {
      return {
        ...payload,
      };
    },
  },
);
