import {
  addCloudinaryParams,
  removeAllCloudinaryParams,
} from '../../utils/cloudinaryUtils';
import { PRODUCT_IMAGE_CLOUDINARY_PARAMS } from '../../constants';

import {
  getPDPType,
  getPDPIngredients,
  isMemberExlcusiveProduct,
  isNikeByYouProduct,
  formatPrice,
} from './helpers';

/**
 *    @description prepares the slides props for product container
 *    @param {Object} productSlide normalized API response part related to one slide
 *    @param {string} locale locale string for price currency formatting (e.g. 'en-US')
 *    @param {string} country 2-symbol country code to be used for composing product card price data and addendum text
 *    @param {Object} translations Podium-related translations to be used for product card addendum text
 *    @returns {Object} preparedSlide re-mapped object for product container slide
 *    prepareProductSlide: productSlide => preparedSlide,
 * */
export const prepareProductSlide = (
  productSlide,
  locale,
  country,
  translations,
) => {
  const productInfo = productSlide?.productInfo?.[0];
  const merchProduct = productInfo?.merchProduct;
  const productCard =
    productSlide?.publishedContent?.properties?.productCard ||
    productSlide?.publishedContent?.properties?.coverCard;
  const merchPrice = productInfo?.merchPrice;
  const linkParams = {
    legacySlug: productInfo?.customizedPreBuild?.groups?.[0]?.legacy?.slug,
    pbid: productInfo?.customizedPreBuild?.legacy?.pbid,
    pdpType: getPDPType(merchProduct?.styleType, merchProduct?.channels),
    pid: merchProduct?.pid,
    piid: productInfo?.customizedPreBuild?.groups?.[0]?.legacy?.piid,
    productGroupId: merchProduct?.productGroupId,
    slug: productSlide?.publishedContent?.properties?.seo?.slug,
    styleColor: productInfo?.merchProduct?.styleColor,
  };
  return {
    altText: productCard?.properties?.altText ?? '',
    aspectRatio: productCard?.properties?.squarish?.aspectRatio ?? '1/1',
    country,
    currency: merchPrice?.currency ?? '',
    formattedListPrice: formatPrice(
      merchPrice?.fullPrice,
      locale,
      merchPrice?.currency,
    ),
    formattedSalePrice: formatPrice(
      merchPrice?.currentPrice,
      locale,
      merchPrice?.currency,
    ),
    imgUrl: addCloudinaryParams(
      removeAllCloudinaryParams(productCard?.properties?.squarishURL) ?? '',
      PRODUCT_IMAGE_CLOUDINARY_PARAMS,
    ),
    isMemberExclusive: isMemberExlcusiveProduct(productSlide),
    isNikeByYou: isNikeByYouProduct(productSlide),
    isOnSale: merchPrice?.discounted ?? '',
    listPrice: merchPrice?.fullPrice,
    merchGroup: merchProduct?.merchGroup,
    pdpType: getPDPType(merchProduct?.styleType, merchProduct?.channels),
    // productId is required for analytics data
    productId: productSlide?.productInfo?.[0]?.merchProduct?.pid,
    productThreadId: productSlide?.id,
    salePrice: merchPrice?.currentPrice,
    squarishId: productCard?.properties?.squarishId ?? '',
    styleColor: merchProduct?.styleColor,
    subtitle:
      productInfo?.contentCopy?.displayNames?.customizationTwoLineName?.line2 ??
      productSlide?.publishedContent?.properties?.subtitle ??
      '',
    title:
      productInfo?.contentCopy?.displayNames?.customizationTwoLineName?.line1 ??
      productSlide?.publishedContent?.properties?.title ??
      '',
    translations,
    urlIngredients: getPDPIngredients(linkParams),
  };
};
