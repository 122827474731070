import { createSelector } from 'reselect';
import { SHOP_THE_LOOK_NAMESPACE } from './constants';
import { stateSelector } from '../stateSelector';
import { ownPropsSelector } from '../../../utils/ownPropsSelector';

export const shopTheLookStateSelector = createSelector(
  stateSelector,
  state => state[SHOP_THE_LOOK_NAMESPACE],
);

export const selectedSkusSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.selectedSkus,
);

export const stlAddToCartStateSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.addToCartRequestState,
);

export const stlCartProductsSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.cartProducts,
);

export const selectedSkuSelector = createSelector(
  selectedSkusSelector,
  ownPropsSelector,
  (selectedSkus, productId) => selectedSkus?.[productId],
);

export const sizesDataSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.sizesByProductId,
);

export const sizesLoadingSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.sizesLoading,
);

export const sizesLoadingErrorSelector = createSelector(
  shopTheLookStateSelector,
  shopTheLookState => shopTheLookState.sizesLoadingError,
);

export const sizesSelector = createSelector(
  sizesDataSelector,
  ownPropsSelector,
  (sizes, productId) => sizes?.[productId],
);
