import { ServerErrorDetails, ErrorMessage } from './types';

/**
 * Extends Error object to provide more detailed errors.
 * The error will contain
 * Error message
 * Stack trace
 * details - everything we know about the context where the error was thrown
 */
export class APIError extends Error {
  public readonly details: ServerErrorDetails;

  constructor(message: ErrorMessage, details: ServerErrorDetails) {
    super(`[DFL] ${message}`);
    this.details = details;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
