import { addIndex, chain, flatten, map } from 'ramda';
import { COLLECTIONS } from '@nike/ciclp-config';
import { CARD_TYPES } from '../../../constants';
import {
  extractIngredientsFromList,
  getLocalNavItems,
  getLocalNavTitle,
  getPageThread,
  isArticle,
  mergeLinkWithIngredients,
} from '../helpers';
import { nodeIdLinkReplacer } from '../utils';
import { getClickActivity } from '../helpers/analytics';
import { analyticsProperties, eventNames } from '../../../analytics/constants';

export const prepareCardData = (
  { id, nodes: cardDataNodes, collectionName },
  { threads, nodes = {} },
) => {
  const localNavNode = nodes?.[nodeIdLinkReplacer(cardDataNodes?.[0])];
  const thread = getPageThread(threads);
  const threadTitle = thread?.properties?.title;
  const isNikeJournal =
    thread?.properties?.tagging?.taxonomyAttributes?.includes(
      COLLECTIONS.nikeJournal.taxonomyTag,
    );

  return {
    collectionName,
    containerType: CARD_TYPES.LOCAL_NAV,
    items: getLocalNavItems({ id, nodes }),
    title: getLocalNavTitle({ localNavNode, threadTitle }),
    ...(isArticle(threads) && {
      containerClass: 'narrow-layout',
    }),
    isNikeJournal,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  landmarkLabel: translationsStrings.secondary,
});

export const mergeProperties = ({
  cardData,
  urls = [],
  translations = {},
}) => ({
  ...cardData,
  items: cardData.items.map(({ links, ...header }) => ({
    ...mergeLinkWithIngredients({ action: header, property: 'url', urls }),
    ...(links && {
      links: links.map(link =>
        mergeLinkWithIngredients({ action: link, property: 'url', urls }),
      ),
    }),
  })),
  landmarkLabel: translations?.landmarkLabel,
});

export const getUrlIngredients = card =>
  flatten(
    card?.items.map(item => extractIngredientsFromList([item, ...item.links])),
  );

export { getAnalyticsPlacements, getBuyingToolsIngredients } from './common';

const chainWithIndex = addIndex(chain);
const mapWithIndex = addIndex(map);
export const getAnalyticsActions = card =>
  chainWithIndex(
    (menu, menuIndex) => [
      {
        eventName: eventNames.NAVIGATION_CLICK,
        id: menu.id,
        [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
          menu.destinationType,
          null,
          menu.label,
          'local nav',
        ),
        [analyticsProperties.LOCAL_PLACEMENT_ID]: 1,
        [analyticsProperties.LOCAL_POSITION_ID]: menuIndex + 1,
      },
      ...mapWithIndex(
        (link, linkIndex) => ({
          eventName: eventNames.NAVIGATION_CLICK,
          id: link.id,
          [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
            link.destinationType,
            null,
            link.label,
            'local nav',
          ),
          [analyticsProperties.LOCAL_PLACEMENT_ID]: linkIndex + 1,
          [analyticsProperties.LOCAL_POSITION_ID]: menuIndex + 1,
        }),
        menu.links,
      ),
    ],
    card.items,
  );
