import { delay } from 'redux-saga';
import { put, call, race, select } from 'redux-saga/effects';
import { ADOBE_TARGET_COOKIE_NAME } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import { getCookie, setCookie, parseAtCookie } from '@nike/ciclp-utils/cookies';
import { optimizationActions } from '../../actions';
import { waitForIdentityInitialization } from '../identity/sagas';
import { hasUserAllowedPersonalization } from '../personalization';
import {
  OPTIMIZATION_INITIALIZATION_TIMEOUT_MS,
  OPTIMIZATION_COOKIE_DAYS_TO_EXPIRE,
} from './constants';
import {
  applyVariationPatch,
  getDefaultOptimizationLocations,
  getAboveTheFoldOptimizationLocations,
  saveAnalyticsFromATCookie,
  resetAtCookie,
} from './helpers';
import {
  isPreviewSelector,
  routeParamsSelector,
} from '../../store/router/routerSelector';
import { urlSelectorWithoutParams } from '../../store/state/stateSelector';

const runDefaultOptimization = function* () {
  const { defaultOptimizationLocationsResult } = yield race({
    defaultOptimizationLocationsResult: call(getDefaultOptimizationLocations),
    timeout: delay(OPTIMIZATION_INITIALIZATION_TIMEOUT_MS),
  });

  let optimizationLocations = [];
  if (defaultOptimizationLocationsResult) {
    optimizationLocations = defaultOptimizationLocationsResult.locations;

    const activeVariation = optimizationLocations?.[0]?.content?.variantId;
    if (activeVariation) {
      yield call(applyVariationPatch, activeVariation);
    }
  }

  return optimizationLocations;
};

const runOptimizationBasedOnActiveVariation = function* (pagePath, atCookie) {
  const { vid: cookieVID } = atCookie;
  const { aboveTheFoldOptimizationResult } = yield race({
    aboveTheFoldOptimizationResult: call(getAboveTheFoldOptimizationLocations),
    timeout: delay(OPTIMIZATION_INITIALIZATION_TIMEOUT_MS),
  });

  const aboveTheFoldActiveVariation =
    aboveTheFoldOptimizationResult?.locations?.[0]?.content?.variantId;
  const state = yield select();
  const { renderedVariantId } = state.appData;
  // To be reverted. Adding logging to check that the content is rendered according
  // to a cookie variation
  if (renderedVariantId && cookieVID !== renderedVariantId) {
    logger.warn({
      details: {
        cookieVID,
        renderedVariantId,
      },
      message: 'Cookie variantId and rendered id differ',
    });
  }

  // if user is not in the aboveTheFold experiment, deactivate it and proceed with default optimization flow
  let optimizationLocations = [];
  if (!aboveTheFoldActiveVariation) {
    resetAtCookie(pagePath);
    optimizationLocations = yield call(runDefaultOptimization);
  } else if (aboveTheFoldActiveVariation !== cookieVID) {
    logger.warn({
      details: {
        activeVid: aboveTheFoldActiveVariation,
        cookieVID,
      },
      message: 'Active variantId and cookie variantId differ',
    });

    const tnta = aboveTheFoldOptimizationResult?.locations?.[0]?.analytics;
    setCookie(
      ADOBE_TARGET_COOKIE_NAME,
      `page=${pagePath}&active=1&vid=${aboveTheFoldActiveVariation}&tnta=${tnta}`,
      OPTIMIZATION_COOKIE_DAYS_TO_EXPIRE,
    );
  }

  return optimizationLocations;
};

export const optimizationInitialization = function* () {
  const isAdobeTargetEnabled = process.env.ADOBE_TARGET_ENABLED === 'true';
  let optimizationLocations = [];

  if (!isAdobeTargetEnabled) {
    yield put(optimizationActions.loadLocations(optimizationLocations));
    return;
  }

  const isPreview = yield select(isPreviewSelector);

  if (isPreview) {
    const routeParams = yield select(routeParamsSelector);

    yield call(applyVariationPatch, routeParams.variationId);
    yield put(optimizationActions.loadLocations(optimizationLocations));
    return;
  }

  // identity needs to be initialized before trying to initialize optimization module
  yield call(waitForIdentityInitialization);

  const canRunOptimization = yield call(hasUserAllowedPersonalization);

  if (!canRunOptimization) {
    yield put(optimizationActions.loadLocations(optimizationLocations));
    return;
  }

  const pagePath = yield select(urlSelectorWithoutParams);
  const atCookieValue = getCookie(ADOBE_TARGET_COOKIE_NAME);
  const atCookie = parseAtCookie(atCookieValue);
  const { page: cookiePagePath, active } = atCookie;

  const isNoAtCookieSet = !atCookieValue;
  if (isNoAtCookieSet) {
    optimizationLocations = yield call(
      runOptimizationBasedOnActiveVariation,
      pagePath,
      atCookie,
    );
  } else if (pagePath !== cookiePagePath || active === '0') {
    logger.warn({
      details: {
        active,
        cookieName: ADOBE_TARGET_COOKIE_NAME,
        cookiePagePath,
        pagePath,
      },
      message: `Page path and cookie page path differ or active is set to 0`,
    });

    resetAtCookie(pagePath);
    optimizationLocations = yield call(runDefaultOptimization);
  } else {
    yield call(saveAnalyticsFromATCookie, atCookie);
    optimizationLocations = yield call(
      runOptimizationBasedOnActiveVariation,
      pagePath,
      atCookie,
    );
  }

  yield put(optimizationActions.loadLocations(optimizationLocations));
};
