import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { PAGE_TYPES, COLLECTIONS } from '@nike/ciclp-config';
import {
  containerTypeCardSelector,
  listicleCardSelector,
  coverCardSelector,
} from './getCardDataSelector';
import { pageDataPropertiesSelector, pageTypeSelector } from './pageSelectors';

export const isHowToCollectionSelector = createSelector(
  pageDataPropertiesSelector,
  pageData => Object.values(COLLECTIONS.howTo).includes(pageData?.collection),
);

export const isHowToPage = createSelector(
  listicleCardSelector,
  isHowToCollectionSelector,
  coverCardSelector,
  pageTypeSelector,
  containerTypeCardSelector,
  (listicleCard, isHowToCollection, coverCard, pageType) => {
    const hasCoverCardData = !!coverCard?.title;
    const isArticleType = pageType === PAGE_TYPES.ARTICLE;
    const hasListicleCard = !isEmpty(listicleCard);

    return (
      isHowToCollection && hasCoverCardData && isArticleType && hasListicleCard
    );
  },
);
