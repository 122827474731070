import { CARD_TYPES } from '../../../constants';

import { prepareGalleryCardData } from '../helpers/carouselHelper';
import { prepareTranslations as videoTranslations } from './video';

export const prepareCardData = (cardData, data) =>
  prepareGalleryCardData(cardData, data, CARD_TYPES.FILMSTRIP);

export const prepareTranslations = translationsStrings => ({
  ...videoTranslations(translationsStrings),
});

export {
  getGalleryUrlIngredients as getUrlIngredients,
  mergeGalleryProperties as mergeProperties,
} from '../helpers/carouselHelper/carouselHelper';

export {
  getGalleryActions as getAnalyticsActions,
  getGalleryPlacements as getAnalyticsPlacements,
} from '../helpers/analytics';

export { getBuyingToolsIngredients } from './common';
