import { addCloudinaryParams } from '../../../utils/cloudinaryUtils';

const getPreferredOrientation = (imgProps, size) =>
  imgProps?.preferredOrientation?.[size];

export const getImageOverride = (imgProps, size, defaultURL) => {
  const orientation = getPreferredOrientation(imgProps, size);
  const url = orientation ? imgProps?.[orientation]?.url : defaultURL;
  const isGIF = url?.includes('.gif');
  const formatParam = isGIF ? ['f_mp4'] : ['f_auto,cs_srgb'];
  // cs_srgb option is a workaround for Safari and it will be reverted as part of https://jira.nike.com/browse/CICLP-2738
  return url ? addCloudinaryParams(url, formatParam) : defaultURL;
};

export const getFocalPoint = (cardData, size, defaultOrientation) =>
  cardData?.properties?.[
    getPreferredOrientation(cardData.properties, size) ?? defaultOrientation
  ]?.focalPoint ?? { horizontal: 'center', vertical: 'middle' };

export const getAssetsAspectRatio = (cardData, size, defaultOrientation) =>
  cardData?.properties?.[
    getPreferredOrientation(cardData.properties, size) ?? defaultOrientation
  ]?.aspectRatio;
