import {
  asyncActionsCreator,
  syncActionsCreator,
} from '../utils/reduxActionHelper';

export const shopTheLookProductsActions = syncActionsCreator(
  [
    'selectSku',
    'resetSelectedSkus',
    'resetShopTheLook',
    'resetProductSelection',
  ],
  'shopTheLook',
);

export const shopTheLookSizesLoadAction = asyncActionsCreator(
  'LOAD_SIZES_SHOP_THE_LOOK',
  'loadSizesShopTheLook',
);

export const shopTheLookAddToCart = asyncActionsCreator(
  'SHOP_THE_LOOK_ADD_TO_CART',
  'shopTheLookAddToCart',
);

export const shopTheLookCartActions = syncActionsCreator(
  ['setAddedItems'],
  'shopTheSyncLookCart',
);

export const shopTheLookProductsLoadedAction = asyncActionsCreator(
  'LOAD_PRODUCTS_SHOP_THE_LOOK',
  'loadProductsShopTheLook',
);
