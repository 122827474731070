import {
  customFetch,
  FetchParams,
  ErrorMessage,
  DEFAULT_API_URL,
  APIError,
} from '../../common';
import { getSkuData } from './getSkuData';
import { getLocalizedSize } from './helpers';

export const fetchGtins = async (
  styleColor: string,
  merchGroup: string,
  fetchFn: typeof fetch,
  timeout: number,
  baseUrl: string,
): Promise<Inventory.Gtin[]> => {
  const path = 'deliver/available_gtins/v3/';
  const url = `${baseUrl}/${path}?filter=styleColor(${styleColor})&filter=merchGroup(${merchGroup})`;
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    path,
    timeout,
  };
  const fetchOptions = {
    fetchFn,
    options: requestOptions,
    url,
  };
  const gtinsDataResponse = await customFetch(fetchOptions);
  try {
    const gtinsData = await gtinsDataResponse.json();
    const gtins = gtinsData?.objects;
    return gtins || [];
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      originalMessage: e.message,
      requestOptions,
      url,
    });
  }
};

export const getAvailability = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: Inventory.AvailabilityFetchOptions & FetchParams;
}): Promise<Inventory.Response> => {
  const {
    styleColor,
    merchGroup,
    country,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
  } = params;
  try {
    const gtins = await fetchGtins(
      styleColor,
      merchGroup,
      fetchFn,
      timeout,
      baseUrl,
    );

    const availableSkus = await Promise.all(
      gtins.map(async (gtin: Inventory.Gtin): Promise<Inventory.SizeData> => {
        const skuData = await getSkuData({
          fetchFn,
          params: {
            country,
            fetchParams: {
              baseUrl,
              timeout,
            },
            gtin: gtin.gtin,
            merchGroup,
          },
        });
        const hasNoSkuData =
          !skuData?.productId || !skuData?.id || !skuData?.nikeSize;
        if (hasNoSkuData) {
          return null;
        }
        return <Inventory.SizeData>{
          availabilityId: skuData.productId,
          available: gtin.available,
          localizedSize: getLocalizedSize(skuData, country),
          skuId: skuData.id,
          styleColor: gtin.styleColor,
        };
      }),
    );

    return {
      sizes: availableSkus.filter(sku => sku !== null),
    };
  } catch (e) {
    e.details = {
      merchGroup,
      styleColor,
    };
    throw e;
  }
};
