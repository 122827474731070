import createReducer from '../../../utils/createReducer';
import {
  shopTheLookProductsActions,
  shopTheLookSizesLoadAction,
  shopTheLookAddToCart,
} from '../../../actions';
import { getCartItems } from '../../../services/nikeShop';

const initialState = {
  addToCartRequestState: {
    isError: false,
    isLoading: false,
    isSuccess: false,
  },
  cartProducts: [],
  selectedSkus: {},
  sizesByProductId: {},
  sizesLoading: false,
  sizesLoadingError: null,
};

export const shopTheLookReducer = createReducer(initialState, {
  [shopTheLookProductsActions.resetShopTheLook.type]: () => initialState,
  [shopTheLookProductsActions.resetSelectedSkus.type]: state => ({
    ...state,
    selectedSkus: {},
  }),
  [shopTheLookProductsActions.resetSelectedSkus.type]: state => {
    return {
      ...state,
      selectedSkus: {},
    };
  },
  [shopTheLookProductsActions.resetProductSelection.type]: (
    state,
    { payload },
  ) => {
    const selectedSkus = { ...state.selectedSkus };
    delete selectedSkus[payload?.productId];
    return {
      ...state,
      selectedSkus,
    };
  },
  [shopTheLookProductsActions.selectSku.type]: (state, { payload }) => {
    return {
      ...state,
      selectedSkus: {
        ...state.selectedSkus,
        [payload?.productId]: payload,
      },
    };
  },
  [shopTheLookSizesLoadAction.request.type]: state => {
    return {
      ...state,
      sizesByProductId: {},
      sizesLoading: true,
      sizesLoadingError: null,
    };
  },
  [shopTheLookSizesLoadAction.success.type]: (state, { payload }) => {
    return {
      ...state,
      sizesByProductId: payload?.sizesByProductId,
      sizesLoading: false,
      sizesLoadingError: null,
    };
  },
  [shopTheLookSizesLoadAction.error.type]: (state, { payload }) => {
    return {
      ...state,
      sizesByProductId: {},
      sizesLoading: true,
      sizesLoadingError: payload.error,
    };
  },
  [shopTheLookAddToCart.request.type]: state => ({
    ...state,
    addToCartRequestState: {
      isError: false,
      isLoading: true,
      isSuccess: false,
    },
  }),
  [shopTheLookAddToCart.success.type]: state => ({
    ...state,
    addToCartRequestState: {
      isError: false,
      isLoading: false,
      isSuccess: true,
    },

    cartProducts: getCartItems(),
  }),
  [shopTheLookAddToCart.error.type]: state => ({
    ...state,
    addToCartRequestState: {
      isError: true,
      isLoading: false,
      isSuccess: false,
    },
  }),
});
