import { createSelector } from 'reselect';
import {
  curry,
  filter,
  fromPairs,
  is,
  join,
  last,
  map,
  pipe,
  values,
} from 'ramda';
import { PAGE_TYPES } from '@nike/ciclp-config';
import {
  analyticsProperties,
  trackEventProperties,
  impressionEventProperties,
  combinedValueProperties,
  eventNames,
  schemaIds,
  viewEventProperties,
  navigationTrackEventProperties,
  bannerTrackEventProperties,
  videoEventProperties,
  productAddedEventProperties,
  clickActivityProperties,
} from './constants';
import { routeNames } from '../enum/routeNames';
import {
  routeNameSelector,
  slugSelector,
  pageNumberSelector,
} from '../store/router/routerSelector';
import { ownPropsSelector } from '../utils/ownPropsSelector';
import {
  pageTypeSelector,
  pageDataPropertiesSelector,
  isNikeJournalSelector,
} from '../store/data/pageSelectors';
import { analyticsDataSelector } from '../store/data/analyticsDataSelector';
import { seoOfficialPathSelector } from '../store/data/seoSelectors';

export const HP_VIEW_NAME = 'homepage';
export const LP_VIEW_NAME = 'landing page';
export const EDITORIAL_VIEW_NAME = 'editorial';
export const SIZECHART_VIEW_NAME = 'sizechart';
export const ERR_VIEW_NAME = 'error';
export const CONTENT_PATTERN_PLACEHOLDER = 'X';

const pageTypeMap = {
  [PAGE_TYPES.ARTICLE]: 'editorial',
  [PAGE_TYPES.PAGE]: 'land',
  [PAGE_TYPES.SIZE_CHART]: 'sizechart',
};

const viewNamesMap = {
  [PAGE_TYPES.ARTICLE]: EDITORIAL_VIEW_NAME,
  [PAGE_TYPES.PAGE]: LP_VIEW_NAME,
  [PAGE_TYPES.SIZE_CHART]: SIZECHART_VIEW_NAME,
};

/**
 * Selectors related to getting right data into the analytics payload
 */

/**
 * Information on whether we are currently on the homepage or not
 * @returns {Boolean}
 */
export const isHomepageSelector = createSelector(
  routeNameSelector,
  slugSelector,
  isNikeJournalSelector,
  (routeName, slug, isNikeJournal) => {
    if (isNikeJournal) return false;
    return (
      !slug ||
      routeName === routeNames.PREVIEW ||
      routeName === routeNames.PREVIEW_V2
    );
  },
);

export const routeToErrorCodeMap = {
  [routeNames.NOT_FOUND]: '404',
  [routeNames.SERVER_ERROR]: '503',
};
/**
 * Error code for error routes or null for not error routes
 * @return {String}
 */
export const errorCodeSelector = createSelector(
  routeNameSelector,
  routeName => routeToErrorCodeMap[routeName],
);

/**
 * Current view type
 * @returns {String}
 */
export const viewTypeSelector = createSelector(
  errorCodeSelector,
  isHomepageSelector,
  (errorCode, isHomepage) => {
    if (errorCode) {
      return ERR_VIEW_NAME;
    }

    return isHomepage ? HP_VIEW_NAME : LP_VIEW_NAME;
  },
);

/**
 * First segment of the 'officialpath'
 * Ie, given '>mens>something' will return 'mens'
 * @returns {String}
 */
export const officialPathL1Selector = createSelector(
  isHomepageSelector,
  seoOfficialPathSelector,
  (isHomepage, officialPath) =>
    isHomepage || typeof officialPath !== 'string'
      ? undefined
      : officialPath.split('>')[1],
);

/**
 * Give us a page name
 * @returns {String}
 */
export const pageNameSelector = createSelector(
  errorCodeSelector,
  isHomepageSelector,
  pageTypeSelector,
  pageNumberSelector,
  isNikeJournalSelector,
  seoOfficialPathSelector,
  (
    errorCode,
    isHomepage,
    pageType,
    pageNumber,
    isNikeJournal,
    officialPath = '',
  ) => {
    if (errorCode) {
      return `${errorCode} error`;
    }

    const viewNamePart = isHomepage ? `>${HP_VIEW_NAME}` : officialPath;
    const pageTypeName = isNikeJournal
      ? EDITORIAL_VIEW_NAME
      : pageTypeMap[pageType];
    const pageTypePart =
      pageTypeMap[pageType] && !isHomepage ? `:${pageTypeName}` : '';
    const pageNumberPart = pageNumber > 1 ? `:${pageNumber}` : '';

    return `nikecom${viewNamePart}${pageNumberPart}${pageTypePart}`;
  },
);

/**
 * Give us a page name for global nav (without channel)
 * @returns {String}
 */
export const globalNavPageNameSelector = createSelector(
  errorCodeSelector,
  isHomepageSelector,
  seoOfficialPathSelector,
  (errorCode, isHomepage, officialPath = '') => {
    if (errorCode) {
      return `${errorCode} error`;
    }
    // seoOfficialPathSelector returns the property with '/' as first symbol, but data-capture place it itself
    // (https://github.nike.com/data-capture/events-api/blob/d2c14d97856ddc0aa1e96786abb6ebe8e50aa1c0/packages/modules/omniture-transformer/index.js#L134)
    return isHomepage ? '' : `${officialPath.replace(/^>/, '')}:land`;
  },
);

/**
 * Gives us channel name
 * @returns {String}
 */
export const analyticsPageTypeSelector = createSelector(
  isHomepageSelector,
  pageTypeSelector,
  isNikeJournalSelector,
  (isHomepage, pageType, isNikeJournal) => {
    if (isHomepage) return HP_VIEW_NAME;
    if (isNikeJournal) return EDITORIAL_VIEW_NAME;

    return viewNamesMap[pageType] ?? LP_VIEW_NAME;
  },
);

/**
 * Gives us sports category name
 * @returns {String}
 */
export const viewSportCategorySelector = createSelector(
  isHomepageSelector,
  seoOfficialPathSelector,
  (isHomepage, officialPath) =>
    !isHomepage && typeof officialPath === 'string'
      ? last(officialPath.split('>'))
      : undefined,
);

/**
 * Gives us page detail
 * @returns {String}
 */
export const pageDetailSelector = createSelector(
  isHomepageSelector,
  seoOfficialPathSelector,
  (isHomepage, officialPath) =>
    isHomepage
      ? HP_VIEW_NAME
      : officialPath
          .split('>')
          .filter(i => i)
          .slice(0, 2)
          .join(':'),
);

export const commonCMSpropertiesSelector = createSelector(
  pageDataPropertiesSelector,
  thread => ({
    [analyticsProperties.THREAD_ID]: thread?.threadId,
    [analyticsProperties.THREAD_KEY]: thread?.threadKey,
  }),
);

const propertyModifier = (property, propertyName, params) => {
  // updating contentClickActivity with button content if clickContentLabel is passed
  if (
    is(String, property) &&
    propertyName === analyticsProperties.CLICK_ACTIVITY &&
    params.clickContentLabel
  ) {
    const splitProperty = property.split(':');
    splitProperty[splitProperty.length - 1] = params.clickContentLabel;
    return splitProperty.join(':');
  }

  return property;
};

const getProperty = curry(
  ({ isMobile, clickContentLabel }, obj, propertyName) => {
    const property = propertyModifier(obj[propertyName], propertyName, {
      clickContentLabel,
    });
    if (is(String, property)) {
      return property || CONTENT_PATTERN_PLACEHOLDER;
    }
    if (is(Number, property)) {
      return property;
    }
    if (is(Array, property)) {
      return property;
    }
    if (is(Object, property)) {
      return (
        (property[isMobile ? 'mobile' : 'desktop'] ||
          property[isMobile ? 'desktop' : 'mobile']) ??
        'X'
      );
    }
    return CONTENT_PATTERN_PLACEHOLDER;
  },
);

const getCombinedValue = curry((isMobile, placement) =>
  pipe(
    map(getProperty({ isMobile }, placement)),
    join(':'),
  )(combinedValueProperties),
);

const hasPosition = curry(
  (isMobile, placement) =>
    placement[analyticsProperties.POSITION_ID][isMobile ? 'mobile' : 'desktop'],
);

export const allContentPresentStringSelector = createSelector(
  analyticsDataSelector,
  ownPropsSelector,
  (analytics, isMobile) => {
    const { placements } = analytics;
    return pipe(
      values,
      filter(hasPosition(isMobile)),
      map(getCombinedValue(isMobile)),
      join('|'),
    )(placements);
  },
);

export const segmentViewPayloadSelector = createSelector(
  commonCMSpropertiesSelector,
  allContentPresentStringSelector,
  ownPropsSelector,
  (commonCMSProperties, allContentPresentString, isMobile) => {
    const data = {
      [analyticsProperties.ALL_CONTENT_PRESENT_STRING]: allContentPresentString,
      ...commonCMSProperties,
    };
    return {
      ...pipe(
        map(property => [property, getProperty({ isMobile }, data, property)]),
        fromPairs,
      )(viewEventProperties),
    };
  },
);

const filteredProps = [
  analyticsProperties.STYLE_COLOR,
  analyticsProperties.PLACEMENT_GROUP,
  analyticsProperties.LOCAL_PLACEMENT_ID,
  analyticsProperties.BANNER_CARD_ID,
  analyticsProperties.ACTION_KEY,
  analyticsProperties.CURRENT_CURRENCY,
  analyticsProperties.CURRENT_PRICE,
  analyticsProperties.PRICING_STATUS,
  analyticsProperties.PRODUCT_ID,
  analyticsProperties.VIDEO_ID,
  analyticsProperties.COMPONENT_EXPIREMENT_ID,
  analyticsProperties.MODEL_VERSION,
  analyticsProperties.MODEL_ID,
  analyticsProperties.MODEL_INFO,
  analyticsProperties.SELECTED_CONCEPTS,
  analyticsProperties.SELECTED_CONCEPTS_IDS,
];

/**
 * Returns returns whether value is correct or not
 *
 * @param value
 * @return boolean
 */
export const isCorrectValue = value =>
  !!(value && value !== CONTENT_PATTERN_PLACEHOLDER);

/**
 * Returns required content properties for event payload according to event payload scheme
 *
 * @param {Object} params - a list of params, that could make influence on property value
 * @param {Boolean} params.isMobile - is mobile view or desktop
 * @param {String} params.clickContentLabel - label value, that should be placed instead of action button label
 * @param data - object { propName: value }, analytics data
 * @param eventProperties - array of event properties
 * @return array - content properties for event payload [[propName, value]]
 */
export const getContentProperties = curry((params, data, eventProperties) =>
  map(
    property => [property, getProperty(params, data, property)],
    eventProperties,
  ),
);

/**
 * Returns filtered content properties for event payload
 *
 * @param eventName - string, name of event
 * @param formattedContentProps - array [[propName, value]]
 * @return array - filtered content props
 */
export const filterContentProperties = curry(
  (eventName, formattedContentProps) =>
    filter(
      ([prop, value]) =>
        !(filteredProps.includes(prop) && !isCorrectValue(value)),
      formattedContentProps,
    ),
);

export const formatVideoProperties = curry(({ videoData = {} }, properties) => {
  const { videoId, ...content } = properties;

  if (!videoId) {
    return content;
  }

  return {
    ...content,
    video: {
      ...videoData,
      videoId,
    },
  };
});

export const formatProductProperties = curry(
  (
    {
      productData,
      clickActivity = clickActivityProperties[eventNames.PRODUCT_ADDED],
    },
    properties,
  ) => {
    if (!productData) {
      return properties;
    }

    return {
      product: {
        clickActivity,
        currency: productData.currency,
        estimatedDeliveryDate: 'null',
        products: [
          {
            brand: productData.brand,
            cloudProductId: 'null',
            inventoryStatus: 'NONE',
            isMembershipExclusive: productData.isMemberExclusive,
            price: productData.isOnSale
              ? productData.salePrice
              : productData.listPrice,
            priceStatus: productData.isOnSale ? 'reduced' : 'regular',
            prodigyProductId: 'null',
            productId: productData.productId,
            publishType: 'null',
            quantity: 1,
            sku: productData.skuId,
          },
        ],
      },
    };
  },
);

/**
 * Returns final payload content properties for event
 *
 * @param eventName
 * @param object - { isMobile, data }
 * @return function that accepts event payload scheme
 */
export const prepareContentProperties = curry(
  (eventProperties, eventName, params, data) =>
    pipe(
      getContentProperties(params, data),
      filterContentProperties(eventName),
      fromPairs,
      formatVideoProperties(params),
      formatProductProperties(params),
    )(eventProperties),
);

const eventPropertiesMap = {
  [eventNames.IMPRESSION]: impressionEventProperties,
  [eventNames.NAVIGATION_CLICK]: navigationTrackEventProperties,
  [eventNames.BANNER_CLICK]: bannerTrackEventProperties,
  [eventNames.CLICK]: trackEventProperties,
  [eventNames.PRODUCT_ADDED]: productAddedEventProperties,
};

export const segmentTrackPayloadSelector = createSelector(
  analyticsDataSelector,
  commonCMSpropertiesSelector,
  pageNameSelector,
  ownPropsSelector,
  (
    analytics,
    commonCMSProperties,
    pageName,
    {
      placementId,
      actionId,
      isMobile,
      eventName,
      clickActivity,
      clickContentLabel,
      videoData,
      productData,
    },
  ) => {
    const analyticsAction = analytics.actions?.[actionId];
    const data = {
      ...analytics.placements?.[placementId],
      ...commonCMSProperties,
      [analyticsProperties.PAGE_OF_CLICK]: pageName,
      ...analyticsAction,
    };

    const eventProperties =
      eventPropertiesMap[eventName] ?? videoEventProperties;

    return prepareContentProperties(
      eventProperties,
      eventName,
      {
        clickActivity,
        clickContentLabel,
        isMobile,
        productData,
        videoData,
      },
      data,
    );
  },
);

export const classificationSelector = eventName => {
  if (
    eventName === eventNames.PAGE_VIEWED ||
    eventName === eventNames.PRODUCT_ADDED
  ) {
    return 'core buy flow';
  }
  return values(eventNames).includes(eventName)
    ? 'experience event'
    : undefined;
};

export const schemaSelector = eventName => schemaIds[eventName];

export const eventNameSelector = createSelector(
  analyticsDataSelector,
  ownPropsSelector,
  (analytics, { placementId, actionId }) => {
    const placement = analytics.placements?.[placementId];
    const action = analytics.actions?.[actionId];
    return placement?.eventName ?? action?.eventName;
  },
);
