import createReducer from '../../utils/createReducer';
import { routerActions } from '../../actions';

export const routerReducer = createReducer(
  {},
  {
    [routerActions.init.type]: (state, { payload = {} }) => {
      return {
        route: payload,
      };
    },
    [routerActions.update.type]: (state, { payload = {} }) => {
      return {
        route: {
          ...state.route,
          ...payload,
        },
      };
    },
  },
);
