import { select } from 'redux-saga/effects';
import logger from '@nike/ciclp-utils/logger';
import { prepareProductSlide } from './transforms/prepareProductSlide';
import {
  countryCodeSelector,
  localeHrefLangSelector,
} from '../store/state/stateSelector';
import { podiumTranslationsSelector } from '../store/state/translations/translationsStringsSelector';

export const normaliseProducts = function (
  products,
  locale,
  country,
  translations,
  normalisationFunction,
) {
  const readyProducts = [];
  const errors = [];
  /* eslint-disable no-restricted-syntax */
  for (const product of products) {
    try {
      readyProducts.push(
        normalisationFunction(product, locale, country, translations),
      );
    } catch (error) {
      const styleColor = product.productInfo?.[0]?.merchProduct?.styleColor;

      errors.push({
        details: {
          ...error,
          productId: product.id,
          styleColor,
        },
        message: 'Error while normalizing product',
        stack: error.stack,
      });
    }
  }
  /* eslint-enable no-restricted-syntax */
  return { errors, readyProducts };
};

const logErrorsOnClient = errors => errors.forEach(error => logger.warn(error));

export const normaliseRecommendationsList = function* (products) {
  const localeForCurrency = yield select(localeHrefLangSelector);
  const country = yield select(countryCodeSelector);
  const translations = yield select(podiumTranslationsSelector);
  const { readyProducts, errors } = normaliseProducts(
    products,
    localeForCurrency,
    country,
    translations,
    prepareProductSlide,
  );

  logErrorsOnClient(errors);

  return readyProducts;
};
