import { pipe, values, find, defaultTo, includes } from 'ramda';
import { createSelector } from 'reselect';
import {
  PAGE_TYPES,
  COLLECTIONS,
  BRAND_NAMES,
  BRANDS,
} from '@nike/ciclp-config';
import { dataSelector } from './dataSelector';
import { isPreviewSelector, slugSelector } from '../router/routerSelector';
import { pathToRootSelector } from './pathToRootSelector';

export const pageDataPropertiesSelector = createSelector(
  dataSelector,
  appData => appData?.pageData,
);

export const pageTypeSelector = createSelector(
  pageDataPropertiesSelector,
  pageData => pageData?.pageType ?? PAGE_TYPES.PAGE,
);

/**
 * Information on whether we are currently on the nike journal or not
 * @returns {Boolean}
 */
export const isNikeJournalSelector = createSelector(
  pageDataPropertiesSelector,
  thread =>
    thread?.tagging?.taxonomyAttributes?.includes?.(
      COLLECTIONS.nikeJournal.taxonomyTag,
    ),
);

const findBrand = predicate =>
  pipe(values, find(predicate), defaultTo(BRAND_NAMES.NIKE))(BRAND_NAMES);

export const brandNameSelector = createSelector(
  isNikeJournalSelector,
  slugSelector,
  isPreviewSelector,
  pathToRootSelector,
  (isNikeJournal, slug, isPreview, pathToRoot) => {
    if (isNikeJournal) return BRAND_NAMES.NIKE_JOURNAL;
    if (!isPreview) {
      return findBrand(brandName =>
        new RegExp(`^/?${brandName}($|/)`).test(slug),
      );
    }

    return findBrand(brandName =>
      includes(BRANDS[brandName]?.collectionId, pathToRoot),
    );
  },
);

export const isDarkThemeSelector = createSelector(
  pageDataPropertiesSelector,
  pageDataProperties => !!pageDataProperties?.subTitle?.includes('#dark-theme'),
);
