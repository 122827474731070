import { pipe, values, head, chain, uniq, without, map } from 'ramda';
import { createSelector } from 'reselect';
import { cardsSelector } from './getCardDataSelector';
import cardMethods from '../../services/transforms/cardMethods';
import { buyingToolsListSelector } from './buyingToolsListSelector';

export const buyingToolsIngredientsSelector = createSelector(
  cardsSelector,
  buyingToolsListSelector,
  (cards, buyingTools) => {
    const getIngredients = card =>
      cardMethods[card.containerType]?.getBuyingToolsIngredients?.(card);

    return pipe(
      values,
      chain(getIngredients),
      uniq,
      without(map(head, buyingTools)),
    )(cards);
  },
);
