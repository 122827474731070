import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { noop } from '@nike/ciclp-utils/noop';
import { mcMiddleware, analyticsClientMiddleware } from '../../../analytics';
import { rootReducer } from '../../rootReducer';
import rootSaga from '../../../sagas/rootSaga';

export const createClientStore = initialState => {
  const sagaMware = createSagaMiddleware();
  let composeEnhancers = compose;
///////////////////////////
////////////////////////////////////////////////////////////////////

////////////////////
///////////////////////////////////////////////////////////////////////
///
///////////

  const middleware = [].concat([
    // Required to handle async actions from nike shop client (CICLP-2817)
    thunk,
    analyticsClientMiddleware,
    mcMiddleware,
    sagaMware,
  ]);

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  persistStore(store, null, noop);

  store.runSaga = sagaMware.run(rootSaga, store.dispatch);

  return store;
};
