import constants from '@nike/ciclp-react-components/constants';
import {
  extractIngredientsFromList,
  getCarouselWithTitle,
  getProductCarouselTranslations,
  getTitle,
  mergeCarouselProperties,
} from '../helpers';
import {
  getActions,
  getProductContainerPlacements,
} from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';
import { CARD_TYPES } from '../../../constants';

export const prepareCardData = cardData => {
  const sectionHeadline = getTitle(cardData);
  const maxResults =
    cardData?.properties?.custom?.maxResults ??
    constants.MAX_PRODUCT_LIST_LENGTH;

  return {
    ...getCommonProps(cardData),
    containerType: CARD_TYPES.TOP_TRENDING,
    dataQa: 'top_trending',
    isLoading: true,
    maxResults,
    sectionHeadline,
    slides: [],
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  ...getProductCarouselTranslations(translationsStrings),
  title: translationsStrings.productContainer_top_trending,
});

export const mergeProperties = ({
  cardData,
  translations,
  urls,
  countryCode,
  languageRegion,
  languageCountryPath,
  languageMappings,
}) =>
  mergeCarouselProperties({
    cardData: getCarouselWithTitle({ cardData, translations }),
    countryCode,
    languageCountryPath,
    languageMappings,
    languageRegion,
    translations,
    urls,
  });

export const getUrlIngredients = card => [
  ...extractIngredientsFromList(card?.sectionHeadline?.actions),
  ...extractIngredientsFromList(card?.slides),
];

export { getProductContainerPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card =>
  getActions(card.sectionHeadline.actions);

export { getBuyingToolsIngredients } from './common';
