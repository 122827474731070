import {
  DEFAULT_API_URL,
  customFetch,
  ExtendedFetchOptions,
  transformParamsToString,
  FetchParams,
  ErrorMessage,
  APIError,
} from '../../common';

export const getBannerNodes = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ContentApi.BannerFetchOptions & FetchParams;
}): Promise<ContentApi.BannerResponse> => {
  const {
    language,
    marketplace,
    channelId,
    location,
    resource,
    fetchParams: { timeout = 1000, baseUrl = DEFAULT_API_URL } = {},
  } = params;
  const paramsString = transformParamsToString({
    channelId,
    language,
    location,
    marketplace,
  });

  const requestOptions: ExtendedFetchOptions['options'] = {
    method: 'GET',
    timeout,
  };

  const url = `${baseUrl}/content/published/v2/banner${paramsString}`;

  const result = await customFetch({
    fetchFn,
    options: requestOptions,
    resource,
    url,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      originalMessage: e.message,
      requestOptions,
      url,
    });
  }
};
