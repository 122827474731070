import { logActions } from '../../../actions';
import createReducer from '../../../utils/createReducer';
import { logLevels } from '../../../enum/errors';

export default createReducer(null, {
  [logActions.error.type]: (state, action) => ({
    ...action.payload,
    level: action.payload.level || logLevels.INFO,
  }),
});
