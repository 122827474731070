import {
  values,
  pick,
  path,
  pipe,
  head,
  map,
  propEq,
  mergeLeft,
  find,
  pathOr,
} from 'ramda';
import { PAGE_TYPES } from '@nike/ciclp-config';
import { dateTimeFormat } from '@nike/i18n-core';
import { sanitizeText } from './textHelpers';
import {
  CARD_ACTION_TYPES,
  CARD_TYPES,
  EDITORIAL_MAPPER,
  CAPTION_POSITIONS,
  CARD_ACTION_DESTINATION_TYPES,
  POPUP_MARKER,
} from '../../../constants';
import { URL_INGREDIENTS, BUYING_TOOLS_INGREDIENTS } from './constants';

/**
 * Overrides the authored color theme with the dark theme when the text position is after.
 * We don't allow white CTA buttons on a white background.
 * @param {string} colorTheme the authored color theme
 * @param {string} textLocation the text location
 */
export const getOverriddenColorTheme = (colorTheme, textLocation) =>
  textLocation?.vertical !== 'after' ? colorTheme : 'dark';

export const getTextLocation = cardData => ({
  horizontal:
    cardData?.properties?.style?.defaultStyle?.textLocation?.horizontal ??
    'start',
  vertical:
    cardData?.properties?.style?.defaultStyle?.textLocation?.vertical ??
    'start',
});

export const getColorTheme = cardData => {
  const textLocation = getTextLocation(cardData);
  return getOverriddenColorTheme(
    cardData?.properties?.colorTheme,
    textLocation,
  );
};

export const getPageType = threads =>
  pipe(values, head)(threads)?.properties.pageType;

export const isArticle = threads => getPageType(threads) === PAGE_TYPES.ARTICLE;

export const getGlyph = threads => {
  const glyph = pipe(values, head, path(['properties', 'glyph']))(threads);
  return {
    glyph: glyph?.name,
    glyphSize: glyph?.size,
  };
};

export const getPageThread = pipe(values, head);

const isPopupDestination = destinationId =>
  destinationId?.includes(POPUP_MARKER);

const preparePopupDestination = destinationId => {
  if (destinationId) {
    return destinationId.replace(POPUP_MARKER, '');
  }
  return destinationId;
};

const getDestinationType = action => {
  if (isPopupDestination(action.destinationId)) {
    return CARD_ACTION_DESTINATION_TYPES.POPUP;
  }

  if (action.destination?.type) {
    return action.destination.type.toLowerCase();
  }

  return action.destinationType;
};

const getDestinationId = ({ destinationId }, destinationType) => {
  if (destinationType === CARD_ACTION_DESTINATION_TYPES.POPUP) {
    return preparePopupDestination(destinationId);
  }
  return destinationId;
};

const extractUrlIngredients = (ingredients, actionType) => {
  const isBuyingTools = actionType === CARD_ACTION_TYPES.BUYING_TOOLS;

  if (isBuyingTools) {
    return {
      buyingToolsIngredients: pick(BUYING_TOOLS_INGREDIENTS)(ingredients),
    };
  }
  return {
    urlIngredients: pick(URL_INGREDIENTS)(ingredients),
  };
};

export const prepareActionButton = ({
  destination = {},
  product = {},
  ...action
}) => {
  const destinationType = getDestinationType({ destination, ...action });
  const ingredients = {
    ...destination,
    ...product,
  };

  const extractedUrlIngredients = extractUrlIngredients(
    ingredients,
    action.actionType,
  );

  const destinationId = getDestinationId(action, destinationType);

  return {
    ...action,
    destinationId,
    openInNewTab: Boolean(destination.openInNewTab),
    ...extractedUrlIngredients,
    destinationType,
    productCollectionId: destination.productCollectionId,
  };
};

export const getCardLinkObject = callToActions => {
  const link = prepareActionButton(
    callToActions.find(
      action => action.actionType === CARD_ACTION_TYPES.CARD_LINK,
    ) || {},
  );
  const destinationType = getDestinationType(link);
  const destinationId = getDestinationId(link, destinationType);

  return {
    cardLinkCollectionId: link.productCollectionId,
    cardLinkId: link.id,
    cardLinkMemberOnly: link.memberOnly,
    cardLinkUrl: destinationId,
    destinationType,
    openInNewTab: link.openInNewTab,
    urlIngredients: link.urlIngredients,
  };
};

const actionButtonsForRender = {
  [CARD_ACTION_TYPES.BUTTON]: () => true,
  [CARD_ACTION_TYPES.STACKED_CTA]: () => true,
  [CARD_ACTION_TYPES.LINK]: () => true,
  [CARD_ACTION_TYPES.BUYING_TOOLS]: ({ product = {} }) =>
    product.styleColor && product.productId,
};

export const getActionButtons = (callToActions, textLocation) =>
  callToActions
    .filter(action => actionButtonsForRender[action.actionType]?.(action))
    .map(action =>
      action.actionType === CARD_ACTION_TYPES.STACKED_CTA
        ? {
            ...prepareActionButton(action),
            actions: action.actions.map(prepareActionButton),
            textLocation,
          }
        : prepareActionButton(action),
    );

/**
 *    @description gets container type of the card
 *    @param {Object} cardData normalized API response part
 *    @returns {string} containerType returns the type of the card that indicates how to treat the card
 *    prepareTextData: cardData => containerType,
 * */
export const getContainerType = (cardData = {}) => {
  const { subType } = cardData;
  if (!subType || !values(CARD_TYPES).includes(subType)) {
    return null;
  }

  const isEditorialCardType = [
    CARD_TYPES.EDITORIAL,
    CARD_TYPES.EDITORIAL_V2,
  ].includes(cardData?.properties?.containerType);

  if (isEditorialCardType) {
    return EDITORIAL_MAPPER?.[cardData?.properties?.templateType] ?? null;
  }

  return subType === CARD_TYPES.DYNAMIC || subType === CARD_TYPES.CAROUSEL
    ? cardData?.properties?.containerType
    : subType;
};

const BLACK_COLOR = '#111111';
const BLACK_COLOR_SHORT = '#111';
const WHITE_COLOR = '#FFFFFF';
const WHITE_COLOR_SHORT = '#FFF';
const GREY_COLOR = '#757575';

const TEXT_COLOR_MAP = {
  [BLACK_COLOR]: 'var(--podium-cds-color-text-primary)',
  [BLACK_COLOR_SHORT]: 'var(--podium-cds-color-text-primary)',
  // TODO: replace with Podium var
  [GREY_COLOR]: '#757575',
  [WHITE_COLOR]: 'var(--podium-cds-color-text-primary-inverse)',
  [WHITE_COLOR_SHORT]: 'var(--podium-cds-color-text-primary-inverse)',
};

export const ALLOWED_COLORS = [
  BLACK_COLOR,
  BLACK_COLOR_SHORT,
  WHITE_COLOR,
  WHITE_COLOR_SHORT,
  GREY_COLOR,
];

const colorThemeMapping = {
  dark: 'primary',
  light: 'primaryInverse',
};

/**
 *    @description resolves text color for a11y purposes
 *    @param {String} textColor textColor from CMS
 *    @param {String} colorTheme theme from CMS
 *    @returns {String} resolved text color
 * */
export const setCustomTextColor = (textColor, colorTheme) =>
  ALLOWED_COLORS.includes(textColor?.toUpperCase())
    ? TEXT_COLOR_MAP[textColor]
    : colorThemeMapping[colorTheme];

/**
 *    @description prepare text for cardOverlay (could be title, subtitle or body)
 *    @param {Object} cardData normalized API response part
 *    @param {string} key the name of property to handle (title, subtitle or body)
 *    @returns {Object} preparedText prepared title and ready for CardOverlay
 *    prepareTextData: (cardData, key) => preparedText,
 * */
export const prepareTextData = (cardData, key) => {
  const { textColor, ...textData } =
    cardData?.properties?.style?.properties?.[key] ?? {};
  const colorTheme = getColorTheme(cardData);
  return {
    isTextColorSet: !!textColor,
    text: sanitizeText(cardData?.properties?.[key] ?? ''),
    ...textData,
    textColor: setCustomTextColor(textColor, colorTheme),
  };
};

export const prepareCaptionData = cardData => {
  const captionStyle = cardData?.properties?.style?.properties?.caption;
  return {
    position: captionStyle?.position ?? CAPTION_POSITIONS.OVERLAY,
    text: sanitizeText(cardData?.properties?.imageCaption ?? ''),
    textColor: colorThemeMapping[captionStyle?.colorTheme ?? 'light'],
  };
};

/**
 *    @description prepared title to be rendered as section headline or as carousel title
 *    @param {Object} cardData normalized API response part related to title
 *    @returns {Object} preparedTitle prepared title and ready for SectionHeadline
 *    getTitle: cardData => preparedTitle,
 * */
export const getTitle = cardData => {
  const textColor = cardData?.properties?.style?.title?.textColor;
  const colorTheme = getColorTheme(cardData);

  return {
    actions: cardData?.properties?.actions?.map(prepareActionButton) ?? [],
    textLocation: cardData?.properties?.style?.defaultStyle?.textLocation,
    title: cardData?.properties?.title,
    titleProps: {
      ...cardData?.properties?.style?.properties?.title,
      isTextColorSet: !!textColor,
      textColor: setCustomTextColor(textColor, colorTheme),
    },
  };
};

/**
 *    @description prepared templateType to be rendered
 *    @param {Object} cardData normalized API response part related to title
 *    @returns {Object} templateType prepared template type
 * */
export const getTemplateType = cardData => cardData?.properties?.templateType;

/**
 *    @description re-maps common parts for image, video and text card components
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} [data] normalized API response
 *    @returns {Object} commonPreparedData prepared data as component properties
 *    prepareGenericCardData: (cardData, data) => commonPreparedData,
 * */
export const prepareGenericCardData = cardData => {
  const textLocation = getTextLocation(cardData);
  const callToActions =
    map(action => {
      const styledAction = find(
        propEq('id', action?.id),
        cardData?.properties?.style?.properties?.actions?.items ?? [],
      );
      return styledAction ? mergeLeft(styledAction, action) : action;
    }, cardData?.properties?.actions ?? []) ?? [];
  const cardLinkObject = getCardLinkObject(callToActions);
  const actionButtons = getActionButtons(callToActions, textLocation);
  const colorTheme = getColorTheme(cardData);
  const bodyProps = prepareTextData(cardData, 'body') ?? '';
  const titleProps = prepareTextData(cardData, 'title');
  const subtitleProps = prepareTextData(cardData, 'subtitle');
  const templateType = getTemplateType(cardData);

  return {
    actionButtons,
    bodyProps,
    colorTheme,
    subtitleProps,
    templateType,
    textLocation,
    titleProps,
    ...cardLinkObject,
  };
};

/**
 * Returns props that could be a part of any card
 * @param {Object} cardData
 * @returns {{id: string, copyId: string, hashKey: string, id: *, version: string, containerType: string, styles: {Object}}}
 */
export const getCommonProps = cardData => ({
  containerType: getContainerType(cardData),
  copyId: cardData.properties?.copyId,
  hashKey: cardData.analytics?.hashKey,
  id: cardData.id,
  styles: {
    topMargin: cardData.properties?.style?.defaultStyle?.margin?.top,
  },
  version: cardData.version,
});

export const getProfilesForRole = roleUuid => thread => {
  const pageAttributions = pathOr([], ['properties', 'attributions'], thread);
  const roleTitle = pathOr('', ['roleTitles', roleUuid], pageAttributions);
  const profiles = pathOr([], ['profiles'], pageAttributions);
  const filteredProfiles = pathOr(
    [],
    ['roles', roleUuid],
    pageAttributions,
  ).reduce((acc, id) => {
    const profile = profiles.find(prf => prf.id === id);

    return profile ? [...acc, { ...profile }] : acc;
  }, []);

  return {
    profiles: filteredProfiles,
    roleTitle,
  };
};

export const formatArticleDate = (targetDate, languageMappings) =>
  dateTimeFormat(languageMappings.langRegion, targetDate, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const getButtonTitle = card => {
  let title = '';
  if (card.actionButtons?.length) {
    title = card.actionButtons[0].actionText;
  }

  return title;
};
