const constants = {
  ANALYTICS_ACTION_SELECTOR: '[data-analytics-action-id]',
  ANALYTICS_ALLOWED_ELEMENTS_SELECTOR: 'a, [data-analytics-allow="true"]',
  ANALYTICS_PLACEMENT_SELECTOR: '[data-analytics-placement-id]',
  CAROUSEL_NAVIGATION_MODE: {
    button: 'button',
    buttonAbove: 'buttonAbove',
    hybrid: 'hybrid',
    scroll: 'scroll',
  },
  FULL_CONTROL_OPTIONS: 'full',
  INSET_COLUMN_SELECTOR:
    '.ncss-col-lg-offset-2.ncss-col-md-offset-2.ncss-col-sm-offset-0',
  MAX_GRID_LENGTH: 18,
  MAX_PRODUCT_LIST_LENGTH: 10,
  MAX_PRODUCT_REQUEST_LIST_LENGTH: 20,
  MIN_PRODUCT_LIST_LENGTH: 3,
  NONE_CONTROL_OPTIONS: 'none',
};

export default constants;
