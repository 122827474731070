import { pipe } from 'ramda';
import stripjs from 'strip-scripts';
import striptags from 'striptags';
import {
  DEFAULT_SUPPORTED_TAGS,
  EXTENDED_SUPPORTED_TAGS,
} from '../../../store/data/constants';

export const replaceZeroWidthSpaceWithWbr = text =>
  text.replace(/\u200b|&#8203;/g, '<wbr>');

export const replaceNewLineSymbolWithBrForPlainText = text =>
  text.replace(/\r\n|\n|\r|\\n/gm, '<br>');

export const replaceNewLineSymbolWithBrForRichText = text =>
  text.replace(/&#8232;/g, '<br>');

export const sanitizeText = pipe(
  replaceZeroWidthSpaceWithWbr,
  replaceNewLineSymbolWithBrForPlainText,
  stripjs,
  text => striptags(text, DEFAULT_SUPPORTED_TAGS),
);

export const sanitizeRichText = pipe(
  replaceZeroWidthSpaceWithWbr,
  replaceNewLineSymbolWithBrForRichText,
  stripjs,
  text => striptags(text, EXTENDED_SUPPORTED_TAGS),
);
