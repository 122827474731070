import { numberFormat } from '@nike/i18n-core';

import {
  MEMBER_EXCLUSIVE_COLLECTION_ID,
  NIKE_BY_YOU_STYLE_TYPE,
  PRICE_MAXIMUM_FRACTION_DIGITS,
  PRICE_MINIMUM_FRACTION_DIGITS,
} from './constants';

export const isNikeByYouProduct = productCard => {
  return (
    productCard?.productInfo?.[0]?.merchProduct?.styleType ===
    NIKE_BY_YOU_STYLE_TYPE
  );
};

export const isMemberExlcusiveProduct = productCard => {
  return (productCard?.collectionsv2?.collectionTermIds ?? []).includes(
    MEMBER_EXCLUSIVE_COLLECTION_ID,
  );
};

export const formatPrice = (price, locale = 'en-US', currency = '') => {
  if (!price) {
    return '';
  }

  const minimumFractionDigits =
    locale === 'en-IN' ? 2 : PRICE_MINIMUM_FRACTION_DIGITS;

  /**
   * This formatting is built based on PDP example https://github.com/nike-internal/dotcom.pdp/blob/3ed7b6b5db3b0809ad4c0c5a045ab7faa5676407/src/client/utils/priceHelper.js#L49
   */
  const options = Intl.NumberFormat.prototype.formatToParts
    ? {
        currency,
        encourageWholeNumbers: false,
        maximumFractionDigits: PRICE_MAXIMUM_FRACTION_DIGITS,
        minimumFractionDigits,
      }
    : {
        currency,
        maximumSignificantDigits: PRICE_MAXIMUM_FRACTION_DIGITS,
      };

  return numberFormat(locale, price, options);
};
