import { variantPatchesActions } from '../../actions';
import createReducer from '../../utils/createReducer';

export const storePatchesReducer = createReducer(
  {},
  {
    [variantPatchesActions.setStatePatches.type]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
);
