const REGEX_SELF_CLOSING_TAG = /<([^/>]+)\/>/g;
const REGEX_HTML_TAG = /<\s*[a-zA-Z][^>]*>(.*?)<\s*\/\s*[a-zA-Z]>/g;
const REGEX_BROKEN_TAG = /<([^/>]+)$/g;
const OPEN_TAG_CHAR = '<';

/**
 *    @description cut string within the limit and add "..." before the word that exceeds character limit.
 *                 If cut edge contains broken tag (<b class="bold" ) the function removes it.
 *    @param {String} str string to cut (required)
 *    @param {Number} limit number of characters to cut (required)
 *    @returns {String} cut string
 * */

export const smartTextCut = (str, limit) => {
  if (typeof str !== 'string') {
    return '';
  }

  if (str.length <= limit) {
    return str;
  }

  const hasHtmlTag = str.match(REGEX_HTML_TAG);
  const hasSelfClosingTag = str.match(REGEX_SELF_CLOSING_TAG);

  const subString = str.substring(0, limit - 1);
  const lastSpaceIndex = subString.lastIndexOf(' ');
  let cutByLastSpace = subString.substring(0, lastSpaceIndex);

  if (hasHtmlTag || hasSelfClosingTag) {
    const hasBrokenTag = cutByLastSpace.match(REGEX_BROKEN_TAG);

    cutByLastSpace = hasBrokenTag
      ? str.substring(0, cutByLastSpace.lastIndexOf(OPEN_TAG_CHAR))
      : cutByLastSpace;
  }

  return `${cutByLastSpace}...`;
};
