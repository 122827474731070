import { requestMetaActions } from '../../../actions';
import createReducer from '../../../utils/createReducer';

export const requestMetaReducer = createReducer(
  {},
  {
    [requestMetaActions.update.type]: (state, { payload = {} }) => {
      return {
        ...payload,
      };
    },
  },
);
