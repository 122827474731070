import { all, take } from 'redux-saga/effects';
import { map } from 'ramda';
import constants from '@nike/ciclp-react-components/constants';

export const canRunAnimation = (isRefetch, slides) =>
  isRefetch && slides.length > 0;

export const prepareProductRecommenderSlides = (
  recommenderResults,
  isRefetch,
  initialRecommenderCards = {},
) => {
  const cardsFromPayload =
    recommenderResults?.cardsWithSlides?.payload?.cards ??
    initialRecommenderCards;

  return Object.keys(cardsFromPayload).reduce((acc, cardId) => {
    const cardFromPayload = cardsFromPayload[cardId];
    const recommenderCard = initialRecommenderCards?.[cardId];
    const slides = cardFromPayload.slides || [];

    if (isRefetch && slides.length === 0) {
      return acc;
    }

    const processedCard = {
      ...cardFromPayload,
      canRunAnimation: canRunAnimation(isRefetch, slides),
      slides: slides.slice(0, recommenderCard?.maxResults),
      useFallback: !cardFromPayload.slides,
      userFirstName:
        recommenderResults?.cardsWithSlides?.payload?.userFirstName,
    };
    return {
      ...acc,
      [cardId]: processedCard,
    };
  }, {});
};

export const takeAll = function* (arr) {
  return yield all(map(a => take(a), arr));
};

export const adjustMaxResult = map(card => {
  return {
    ...card,
    maxResults: Math.max(
      card.maxResults,
      constants.MAX_PRODUCT_REQUEST_LIST_LENGTH,
    ),
  };
});
