import createReducer from '../../../utils/createReducer';
import { optimizelyActions } from '../../../actions';

const initialState = {
  activeExperiments: {},
};

export const reducer = createReducer(initialState, {
  [optimizelyActions.activateExperiment.type]: (state, action) => ({
    activeExperiments: {
      ...state.activeExperiments,
      [action.payload.experimentName]: action.payload,
    },
  }),
});
