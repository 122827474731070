import { map, pipe, filter } from 'ramda';
import { extractNodeProps, findLinkNodes } from './index';

export const getTeamSelectorNodes = ({ nodes, id }) =>
  pipe(
    menuId => nodes?.[nodes?.[menuId]?.nodes?.[0]]?.id,
    findLinkNodes(nodes),
    map(node => ({
      altText: node?.properties?.image?.altText,
      assetId: node?.properties?.image?.squarish?.id,
      image: node?.properties?.image?.squarish?.url?.replace(/w_\d+/, 'w_144'),
      ...extractNodeProps(node),
    })),
    filter(({ label, altText, image }) => (label || altText) && image),
  )(id);
