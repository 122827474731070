import { filter, keys, map, pipe } from 'ramda';
import { ROLES_UUIDS } from '../../../constants';
import { getProfilesForRole } from './commonHelpers';

export const prepareRoleForRender = role =>
  `<div>${role.roleTitle}: ${role.profiles
    .map(profile => profile.fullName)
    .join(', ')}</div>`;

export const prepareBodyText = attributions =>
  pipe(
    keys,
    filter(key => key !== ROLES_UUIDS.WRITER),
    map(getProfilesForRole),
    map(fn => fn({ properties: { attributions } })),
    map(prepareRoleForRender),
  )(attributions.roles);
