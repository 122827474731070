import { chain, curry, head, pick, propEq, pipe } from 'ramda';
import { URL_INGREDIENTS } from './constants';

export const findNodes = curry((predicate, nodes, nodeId) => {
  const currentNode = nodes?.[nodeId];
  if (!currentNode) {
    return [];
  }
  return [
    ...(predicate(currentNode) ? [currentNode] : []),
    ...(currentNode.nodes
      ? chain(findNodes(predicate, nodes), currentNode.nodes)
      : []),
  ];
});

export const findNodesBySubType = curry((subType, nodes, nodeId) =>
  findNodes(propEq('subType', subType), nodes, nodeId),
);

export const findGroupNodes = findNodesBySubType('group');
export const findHeaderNodes = findNodesBySubType('header');
export const findHeaderNode = pipe(findHeaderNodes, head);
export const findLinkNodes = findNodesBySubType('link');

// data object skimmer function
export const extractNodeProps = node => {
  const { destination, label } = node?.properties ?? {};
  const { type, openInNewTab } = destination ?? {};
  if (!node) return {};

  return {
    ...pick(['id'], node),
    ...(label && { label }),
    ...(destination &&
      (destination.url
        ? {
            url: destination?.url,
          }
        : {
            urlIngredients: pick(URL_INGREDIENTS, destination ?? {}),
          })),
    ...(type && { destinationType: type }),
    ...(openInNewTab && { openInNewTab }),
  };
};

export { getLocalNavItems, getLocalNavTitle } from './localNav';
export { getTeamSelectorNodes } from './teamSelector';
export {
  getImageOverride,
  getFocalPoint,
  getAssetsAspectRatio,
} from './imageHelpers';
export { getMerchMenuItems } from './merchMenu';
export { sanitizeText, sanitizeRichText } from './textHelpers';
export { smartTextCut } from './smartTextCut';
export {
  getProductCarouselTranslations,
  getPaginationTranslations,
} from './translation';
export {
  getPDPType,
  getUrlIngredients,
  getPDPIngredients,
  pickUrlByIngredients,
  getPDPUrl,
  getPDPURL,
  extractIngredientsFromList,
  mergeLinkWithIngredients,
} from './url';
export { extractBuyingIngredientsFromList } from './buyingTools';
export {
  mergeSectionHeadlineTitle,
  mergeCarouselProperties,
  getCarouselWithTitle,
  prepareDynamicContentCarousel,
} from './carouselHelper';
export {
  prepareGenericCardData,
  getTitle,
  getContainerType,
  getActionButtons,
  prepareActionButton,
  getPageType,
  isArticle,
  getGlyph,
  getPageThread,
  getProfilesForRole,
  getTextLocation,
  formatArticleDate,
  getCommonProps,
  getButtonTitle,
  prepareTextData,
} from './commonHelpers';

export {
  mergeSnkrsCarouselProperties,
  getCountryLangParams,
  getSnkrsProductStatus,
} from './snkrsDropsHelpers';

export { getBaseFilters } from './feedApiHelpers';
export { prepareRoleForRender, prepareBodyText } from './bylineHelpers';

export {
  isMemberExlcusiveProduct,
  isNikeByYouProduct,
  formatPrice,
} from './productHelpers';
