// replacer for JSON.stringify that gives a possibility
// to include not enumerable properties in string version of Error object
export default (key, value) => {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce((acc, name) => {
      acc[name] = value[name];
      return acc;
    }, {});
  }
  return value;
};
