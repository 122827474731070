import { CARD_TYPES } from '../constants';

export const analyticsViewProperties = {
  APP_BACKEND_PLATFORM: 'cloud',
  ENVIRONMENT: 'production',
  PAGE_DIVISION: 'brand',
  SITE: 'nikecom',
  VERSION: global.window?.PAGE_VERSION ?? 'development',
};

export const componentTypes = {
  [CARD_TYPES.IMAGE]: 'ic',
  [CARD_TYPES.VIDEO]: 'vc',
  [CARD_TYPES.FILMSTRIP]: 'cc',
  [CARD_TYPES.RELATED_FILMSTRIP]: 'cc',
  [CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP]: 'cc',
  [CARD_TYPES.SLIDESHOW]: 'cc',
  [CARD_TYPES.PRODUCT_RECOMMENDER]: 'pc',
  [CARD_TYPES.TOP_TRENDING]: 'pc',
  [CARD_TYPES.EXTERNAL_COLLECTION]: 'mc',
  [CARD_TYPES.PRODUCT_WALL]: 'mc',
  [CARD_TYPES.SECTION_HEADLINE]: 'sh',
  [CARD_TYPES.TEXT]: 'tc',
  [CARD_TYPES.MERCH_MENU]: 'merch_menu',
  BANNER: 'promo',
  [CARD_TYPES.LOCAL_NAV]: 'local_menu',
  [CARD_TYPES.TEAM_SELECTOR]: 'ts',
  [CARD_TYPES.SIZE_CHARTS]: 'dsc',
  [CARD_TYPES.DYNAMIC_CAROUSEL]: 'dca',
  [CARD_TYPES.SNKRS_DROPS]: 'dsd',
  [CARD_TYPES.DYNAMIC_GRID]: 'dcg',
  [CARD_TYPES.TOOLS_AND_SUPPLIES]: 'st',
  [CARD_TYPES.FAQ]: 'faq',
  [CARD_TYPES.LISTICLE]: 'cc',
  [CARD_TYPES.SHOP_THE_LOOK]: 'stl',
  [CARD_TYPES.BRA_SIZE_FINDER]: 'sf',
};

export const analyticsCardTypes = {
  [CARD_TYPES.IMAGE]: 'c_x_i',
  [CARD_TYPES.VIDEO]: 'c_x_v',
  [CARD_TYPES.FILMSTRIP]: 'c_c_x',
  [CARD_TYPES.RELATED_FILMSTRIP]: 'c_rc_x',
  [CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP]: 'c_drc_x',
  [CARD_TYPES.DYNAMIC_CAROUSEL]: 'c_dca_x',
  [CARD_TYPES.SLIDESHOW]: 'c_s_x',
  [CARD_TYPES.PRODUCT_RECOMMENDER]: 'c_dpr_x',
  [CARD_TYPES.PRODUCT_RECOMMENDER_TAXONOMY]: 'c_dpr_x',
  [CARD_TYPES.TOP_TRENDING]: 'c_dtt_x',
  [CARD_TYPES.EXTERNAL_COLLECTION]: 'c_dec_x',
  [CARD_TYPES.PRODUCT_WALL]: 'c_dpw_x',
  [CARD_TYPES.SECTION_HEADLINE]: 'c_x_sh',
  [CARD_TYPES.TEXT]: 'c_x_t',
  [CARD_TYPES.TEAM_SELECTOR]: 'c_ts_i',
  [CARD_TYPES.SIZE_CHARTS]: 'c_dsc_x',
  [CARD_TYPES.SNKRS_DROPS]: 'c_dsd_x',
  [CARD_TYPES.DYNAMIC_GRID]: 'c_dcg_x',
  [CARD_TYPES.FAQ]: 'c_faq_x',
  [CARD_TYPES.LISTICLE]: 'c_ol_x',
  [CARD_TYPES.TOOLS_AND_SUPPLIES]: 'c_st_t',
  [CARD_TYPES.SHOP_THE_LOOK]: 'c_stl_x',
  [CARD_TYPES.BRA_SIZE_FINDER]: 'c_sf_s',
};

export const videoEventsNames = {
  PREVIEW_STARTED: 'Video Preview Started',
  PREVIEW_STOPPED: 'Video Preview Stopped',
  VIDEO_ENDED: 'Video Ended',
  VIDEO_ENTERED_FULLSCREEN: 'Video Entered Fullscreen',
  VIDEO_EXITED_FULLSCREEN: 'Video Exited Fullscreen',
  VIDEO_MUTE: 'Video Muted',
  VIDEO_PAUSED: 'Video Paused',
  VIDEO_PLAYED: 'Video Played',
  VIDEO_RESTART: 'Video Restarted',
  VIDEO_REWOUND: 'Video Rewound',
  VIDEO_START: 'Video Started',
  VIDEO_SUBTITLES_OFF: 'Video Subtitles Turned Off',
  VIDEO_SUBTITLES_ON: 'Video Subtitles Turned On',
  VIDEO_UNMUTE: 'Video Unmuted',
};

export const iframeEventsNames = {
  IFRAME_MODAL_CLOSED: 'Iframe Modal Closed',
  IFRAME_MODAL_OPENED: 'Iframe Modal Opened',
  IFRAME_MODAL_REDIRECTED: 'Iframe Modal Redirected',
};

export const eventNames = {
  BANNER_CLICK: 'Banner Clicked',
  CLICK: 'CMS Content Clicked',
  IMPRESSION: 'Impression Tracked',
  NAVIGATION_CLICK: 'Navigation Clicked',
  NOTIFY_ME_CLICKED: 'Notify Me Clicked',
  NOTIFY_ME_DISMISSED: 'Notify Me Dismissed',
  NOTIFY_ME_SUBMITTED: 'Notify Me Submitted',
  PAGE_VIEWED: 'Page Viewed',
  PRODUCT_ADDED: 'Product Added',
  ...videoEventsNames,
  ...iframeEventsNames,
};

export const clickActivityProperties = {
  [eventNames.NOTIFY_ME_SUBMITTED]: 'lp:notify me submitted',
  [eventNames.NOTIFY_ME_CLICKED]: 'lp:notify me clicked',
  [eventNames.NOTIFY_ME_DISMISSED]: 'lp:notify me dismissed',
  [eventNames.PRODUCT_ADDED]: 'lp:product added',
};

export const eventTypes = {
  PAGE: 'page',
  TRACK: 'track',
};

const getSchemaUrl = (
  eventName,
  eventType = eventTypes.TRACK,
  isCoreBuyFlow = false,
) =>
  `https://${
    process.env.NEXT_PUBLIC_HOST_NAME
  }/assets/measure/schemas/digital-product/dotcom/platform/web/classification/${
    isCoreBuyFlow ? 'core-buy-flow' : 'experience-event'
  }/experience/landing-page/event-type/${eventType}/event-name/${eventName}/version/LATEST/schema.json`;
export const schemaIds = {
  [eventNames.CLICK]: getSchemaUrl('cms-content-clicked'),
  [eventNames.NAVIGATION_CLICK]: getSchemaUrl('navigation-clicked'),
  [eventNames.BANNER_CLICK]: getSchemaUrl('banner-clicked'),
  [eventNames.IMPRESSION]: getSchemaUrl('impression-tracked'),
  [eventNames.PAGE_VIEWED]: getSchemaUrl('page-viewed', eventTypes.PAGE, true),
  [eventNames.VIDEO_PAUSED]: getSchemaUrl('video-paused'),
  [eventNames.VIDEO_PLAYED]: getSchemaUrl('video-played'),
  [eventNames.VIDEO_REWOUND]: getSchemaUrl('video-rewound'),
  [eventNames.VIDEO_ENTERED_FULLSCREEN]: getSchemaUrl(
    'video-entered-fullscreen',
  ),
  [eventNames.VIDEO_EXITED_FULLSCREEN]: getSchemaUrl('video-exited-fullscreen'),
  [eventNames.VIDEO_MUTE]: getSchemaUrl('video-muted'),
  [eventNames.VIDEO_UNMUTE]: getSchemaUrl('video-unmuted'),
  [eventNames.VIDEO_SUBTITLES_OFF]: getSchemaUrl('video-subtitles-turned-off'),
  [eventNames.VIDEO_SUBTITLES_ON]: getSchemaUrl('video-subtitles-turned-on'),
  [eventNames.VIDEO_RESTART]: getSchemaUrl('video-restarted'),
  [eventNames.VIDEO_START]: getSchemaUrl('video-started'),
  [eventNames.VIDEO_ENDED]: getSchemaUrl('video-ended'),
  [eventNames.PREVIEW_STARTED]: getSchemaUrl('video-preview-started'),
  [eventNames.PREVIEW_STOPPED]: getSchemaUrl('video-preview-stopped'),
  [eventNames.NOTIFY_ME_CLICKED]: getSchemaUrl('notify-me-clicked'),
  [eventNames.NOTIFY_ME_DISMISSED]: getSchemaUrl('notify-me-dismissed'),
  [eventNames.NOTIFY_ME_SUBMITTED]: getSchemaUrl('notify-me-submitted'),
  [eventNames.PRODUCT_ADDED]: getSchemaUrl(
    'product-added',
    eventTypes.TRACK,
    true,
  ),
};

export const analyticsProperties = {
  ACTION_KEY: 'actionKey',
  ALL_CONTENT_PRESENT_STRING: 'allContentPresentString',
  ASSET_ID: 'assetId',
  BANNER_CARD_ID: 'bannerCardId',
  CARD_ID: 'cardId',
  CARD_KEY: 'cardKey',
  CLICK_ACTIVITY: 'clickActivity',
  COMPONENT_TYPE: 'componentType',
  CONTENT_CLICK_ACTIVITY: 'contentClickActivity',
  COPY_ID: 'copyId',
  CTA_TITLE: 'ctaTitle',
  CURRENT_CURRENCY: 'currentCurrency',
  CURRENT_PRICE: 'currentPrice',
  LANDMARK_X: 'landmarkX',
  LANDMARK_Y: 'landmarkY',
  LEGACY_COMPONENT_TYPE: 'legacyComponentType',
  LOCAL_PLACEMENT_ID: 'localPlacementId',
  LOCAL_POSITION_ID: 'localPositionId',
  MODEL_ID: 'modelId',
  MODEL_INFO: 'modelInfo',
  MODEL_VERSION: 'modelVersion',
  PAGE_OF_CLICK: 'pageOfClick',
  PLACEMENT_GROUP: 'placementGroup',
  PLACEMENT_ID: 'placementId',

  POSITION_ID: 'positionId',
  PRICING_STATUS: 'pricingStatus',

  PRODUCT_ID: 'productId',
  SELECTED_CONCEPTS: 'selectedConcepts',
  SELECTED_CONCEPTS_IDS: 'selectedConceptsIds',
  STYLE_COLOR: 'styleColor',

  THREAD_ID: 'threadId',
  THREAD_KEY: 'threadKey',
  TIMESTAMP: 'timestamp',
  TOTAL_POSITIONS: 'totalPositions',
  VIDEO_DATA: 'video',
  VIDEO_ID: 'videoId',
};

export const combinedValueProperties = [
  analyticsProperties.LEGACY_COMPONENT_TYPE,
  analyticsProperties.ASSET_ID,
  analyticsProperties.VIDEO_ID,
  analyticsProperties.CARD_ID,
  analyticsProperties.PRODUCT_ID,
  analyticsProperties.POSITION_ID,
  analyticsProperties.PLACEMENT_ID,
];

export const viewEventProperties = [
  analyticsProperties.ALL_CONTENT_PRESENT_STRING,
  analyticsProperties.THREAD_ID,
  analyticsProperties.THREAD_KEY,
];

export const trackEventProperties = [
  analyticsProperties.ASSET_ID,
  analyticsProperties.ACTION_KEY,
  analyticsProperties.CARD_ID,
  analyticsProperties.CARD_KEY,
  analyticsProperties.CLICK_ACTIVITY,
  analyticsProperties.COMPONENT_TYPE,
  analyticsProperties.COPY_ID,
  analyticsProperties.CTA_TITLE,
  analyticsProperties.CURRENT_CURRENCY,
  analyticsProperties.CURRENT_PRICE,
  analyticsProperties.PAGE_OF_CLICK,
  analyticsProperties.PLACEMENT_ID,
  analyticsProperties.PLACEMENT_GROUP,
  analyticsProperties.POSITION_ID,
  analyticsProperties.PRICING_STATUS,
  analyticsProperties.PRODUCT_ID,
  analyticsProperties.THREAD_ID,
  analyticsProperties.THREAD_KEY,
  analyticsProperties.VIDEO_ID,
  analyticsProperties.STYLE_COLOR,
  analyticsProperties.MODEL_VERSION,
  analyticsProperties.MODEL_ID,
  analyticsProperties.MODEL_INFO,
  analyticsProperties.SELECTED_CONCEPTS,
  analyticsProperties.SELECTED_CONCEPTS_IDS,
];

export const impressionEventProperties = [
  analyticsProperties.ASSET_ID,
  analyticsProperties.CARD_KEY,
  analyticsProperties.CURRENT_CURRENCY,
  analyticsProperties.CURRENT_PRICE,
  analyticsProperties.COMPONENT_TYPE,
  analyticsProperties.PLACEMENT_ID,
  analyticsProperties.PLACEMENT_GROUP,
  analyticsProperties.POSITION_ID,
  analyticsProperties.PRICING_STATUS,
  analyticsProperties.PRODUCT_ID,
  analyticsProperties.THREAD_ID,
  analyticsProperties.THREAD_KEY,
  analyticsProperties.VIDEO_ID,
  analyticsProperties.STYLE_COLOR,
  analyticsProperties.TOTAL_POSITIONS,
  analyticsProperties.MODEL_VERSION,
  analyticsProperties.MODEL_ID,
  analyticsProperties.MODEL_INFO,
  analyticsProperties.SELECTED_CONCEPTS,
  analyticsProperties.SELECTED_CONCEPTS_IDS,
];

export const videoEventProperties = [
  analyticsProperties.ASSET_ID,
  analyticsProperties.CARD_ID,
  analyticsProperties.CARD_KEY,
  analyticsProperties.COMPONENT_TYPE,
  analyticsProperties.COPY_ID,
  analyticsProperties.CTA_TITLE,
  analyticsProperties.PAGE_OF_CLICK,
  analyticsProperties.PLACEMENT_ID,
  analyticsProperties.POSITION_ID,
  analyticsProperties.THREAD_ID,
  analyticsProperties.THREAD_KEY,
  analyticsProperties.VIDEO_ID,
];

export const navigationTrackEventProperties = [
  analyticsProperties.CLICK_ACTIVITY,
  analyticsProperties.LOCAL_PLACEMENT_ID,
  analyticsProperties.LOCAL_POSITION_ID,
];

export const bannerTrackEventProperties = [
  analyticsProperties.CLICK_ACTIVITY,
  analyticsProperties.LOCAL_PLACEMENT_ID,
  analyticsProperties.BANNER_CARD_ID,
];

export const productAddedEventProperties = [];
