import { getProducts } from './getProducts';
import { FetchParams } from '../../common';

export const getProductsByAttributes = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ProductFeed.RequestParamsFiltersByAttributes & FetchParams;
}): Promise<ProductFeed.Response> =>
  getProducts({
    fetchFn,
    params: {
      anchor: 0,
      ...params,
    },
  });
