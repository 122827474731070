import { call, put, select, fork } from 'redux-saga/effects';
import {
  DEFAULT_IMPERIAL_MARKETPLACES,
  MEASUREMENT_TYPE,
  MEASUREMENTS_UNITS,
} from '@nike/ciclp-config';
import { marketplaceSelector } from '../store/state/stateSelector';
import { privacySaga } from './privacySaga';
import { identityInitialisation } from './identity';
import {
  analyticsActions,
  bootstrapActions,
  measurementActions,
} from '../actions';
import { waitForIdentityInitialization } from './identity/sagas';
import { optimizationInitialization } from './optimization';
import { bannerThreadSaga } from './banner';

const identityInitAttemptCount = 15;

export const bootstrapSaga = function* (dispatch) {
  // initialise analytics and privacy settings on the client
  yield fork(identityInitialisation, identityInitAttemptCount);
  yield fork(optimizationInitialization);
  yield put(analyticsActions.init());
  yield fork(privacySaga, dispatch);

  const marketplace = yield select(marketplaceSelector);
  yield put(
    measurementActions.set({
      [MEASUREMENT_TYPE]: DEFAULT_IMPERIAL_MARKETPLACES.includes(marketplace)
        ? MEASUREMENTS_UNITS.IMPERIAL
        : MEASUREMENTS_UNITS.METRIC,
    }),
  );

  yield call(bannerThreadSaga);
  yield call(waitForIdentityInitialization);

  yield put(bootstrapActions.success(true));
};
