/**
 * Util function used to manipulate image URLs so we can do Cloudinary Transformations on the image URLs
 * @param {String} originalUrl - the original image URL that we want to manipulate
 * @param {Array} params - Array of Clodinary param groups. E.g ['ar_32:17,c_pad','b_red']
 * Please read about Grouped vs Chained Transformations here: https://confluence.nike.com/display/CICAD/Transformation+Strategy
 * @returns {String} If the provided  params are in the right format it will return the new URL with the new params added in the URL
 */
export const addCloudinaryParams = (originalUrl, params) => {
  if (typeof originalUrl !== 'string') {
    return '';
  }
  if (!params || !Array.isArray(params) || !params.length) {
    return originalUrl;
  }
  const urlSplit = originalUrl.split('/images/');
  if (urlSplit.length === 2) {
    const urlParams = params.join('/');
    return `${urlSplit[0]}/images/${urlParams}/${urlSplit[1]}`;
  }
  return originalUrl;
};

/**
 * Util function used to remove a Cloudinary parameter from an image URL
 * @param {String} originalUrl - the original image URL that we want to manipulate
 * @param {String} param - the Clodinary param we want to remove from the URL. E.g: t_default
 * return {String} If the provided params are in the right format the new image url should be returned with the param removed
 */
export const removeCloudinaryParam = (originalUrl, param) => {
  if (typeof originalUrl !== 'string') {
    return '';
  }
  if (!param || typeof param !== 'string') {
    return originalUrl;
  }
  return originalUrl.replace(new RegExp(`,?/?${param}`), '');
};

/**
 * Util function used to remove a Cloudinary parameter from an image URL
 * @param {String} originalUrl - the original image URL that we want to manipulate
 * return {String} If the provided params are in the right format the new image url should be returned with the param removed
 */
export const removeAllCloudinaryParams = originalUrl => {
  if (typeof originalUrl !== 'string') {
    return '';
  }
  return originalUrl.replace(/(images\/).+\/([^/]+\/[^/]+$)/, '$1$2');
};
