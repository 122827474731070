import { nanoid } from 'nanoid';
import {
  getCarouselWithTitle,
  getCountryLangParams,
  getTitle,
  mergeSnkrsCarouselProperties,
} from '../helpers';
import { CARD_TYPES } from '../../../constants';
import {
  getActions,
  getProductContainerPlacements,
} from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = cardData => {
  const sectionHeadline = getTitle(cardData);

  sectionHeadline.actions[0] = {
    actionText: 'view all',
    actionType: 'link',
    destinationType: 'url',
    id: nanoid(),
    openInNewTab: true,
  };

  return {
    ...getCommonProps(cardData),
    dataQa: CARD_TYPES.SNKRS_DROPS,
    sectionHeadline,
    taxonomyAttributes: cardData?.properties?.valueMap?.snkrs_drops,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  labels: {
    a11y_switch_description: translationsStrings.snkrs_drops_a11y_switch_desc,
    available_date: translationsStrings.snkrs_drops_available_date,
    available_today: translationsStrings.snkrs_drops_available_today,
    available_tomorrow: translationsStrings.snkrs_drops_available_tomorrow,
    exclusive_text: translationsStrings.snkrs_drops_exclusive_text,
    heading: translationsStrings.snkrs_drops_heading,
    in_stock: translationsStrings.snkrs_drops_in_stock,
    sold_out: translationsStrings.snkrs_drops_sold_out,
    upcoming: translationsStrings.snkrs_drops_upcoming,
  },
  viewAllLabel: translationsStrings.view_all,
});

export const mergeProperties = ({
  cardData,
  translations,
  urls,
  countryCode,
  languageRegion,
  languageMappings = {},
}) => {
  const countryLangPath = getCountryLangParams(
    countryCode,
    languageMappings.urlParam,
  );
  return mergeSnkrsCarouselProperties({
    cardData: getCarouselWithTitle({ cardData, translations }),
    countryCode,
    // need to add trailing slash because getPDPURL fn supports it only in that format
    countryLangParams: `${countryLangPath}/`,
    languageMappings,
    languageRegion,
    translations,
    urls,
  });
};

export { getProductContainerPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card =>
  getActions(card.sectionHeadline.actions);

// We do not need to extract inredients from slides and view all since we are building it by ourself
export { getBuyingToolsIngredients, getUrlIngredients } from './common';
