import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { MEASUREMENTS_UNITS, MEASUREMENT_TYPE } from '@nike/ciclp-config';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { measurementActions } from '../../actions';
import createReducer from '../../utils/createReducer';

export const measurementReducer = createReducer(
  {
    [MEASUREMENT_TYPE]: MEASUREMENTS_UNITS.IMPERIAL,
  },
  {
    [measurementActions.set.type]: (state, { payload }) => payload,
  },
);

export const persistedMeasurementReducer = () => ({
  measurement: persistReducer(
    {
      key: 'measurement',
      stateReconciler: autoMergeLevel1,
      storage,
    },
    measurementReducer,
  ),
});
