import { createSelector } from 'reselect';
import { OPTIMIZELY_NAMESPACE } from './constants';
import { stateSelector } from '../stateSelector';

export const optimizelyStateSelector = createSelector(
  stateSelector,
  state => state[OPTIMIZELY_NAMESPACE],
);

export const optimizelyActiveExperimentsSelector = createSelector(
  optimizelyStateSelector,
  optimizelyState => optimizelyState.activeExperiments || {},
);

export const getOptimizelyAnalyticsPropertySelector = createSelector(
  optimizelyActiveExperimentsSelector,
  activeExperiments => {
    const experiments = Object.values(activeExperiments);
    if (experiments) {
      const experimentKeys = experiments
        .map(experiment => experiment.analyticsKey)
        .filter(Boolean);
      return {
        experiments: experimentKeys,
        experimentsConcatenated: experimentKeys.join('|'),
      };
    }
    return null;
  },
);
