import { pipe, head, values, chain, uniq, without, map } from 'ramda';
import { createSelector } from 'reselect';
import { cardsSelector } from './getCardDataSelector';
import {
  countryCodeSelector,
  languageRegionHrefSelector,
} from '../state/stateSelector';
import cardMethods from '../../services/transforms/cardMethods';
import { urlsSelector } from './urlSelectors';

export const urlIngredientsSelector = createSelector(
  cardsSelector,
  countryCodeSelector,
  languageRegionHrefSelector,
  urlsSelector,
  (cards, countryCode, languageRegion, urls) => {
    const getIngredients = card =>
      cardMethods[card.containerType].getUrlIngredients(
        card,
        countryCode,
        languageRegion,
      );
    return pipe(
      values,
      chain(getIngredients),
      uniq,
      without(map(head, urls)),
    )(cards);
  },
);
