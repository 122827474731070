/**
 * The Nessie-based app (Nessie was Nike Express-based engine) was replacing global fetch method
 * with Ace (Nike's module to make server requests within Nike infrastructure).
 * Web-Shell-Next doesn't provide such capability, so we need to replace it here if it's configured.
 * Ace is configured by Web-Shell-Next if shell.config.js exports serviceDiscovery property equal true.
 */
export const aceFetchFallback = (state, fetchFunction) => {
  if (!__IS_SERVER__) {
    return fetchFunction;
  }

  return async (url, options) => {
    let fetchFn = fetchFunction;

    if (options?.vipAddress && global && global.ace) {
      fetchFn = global.ace;
    }

    return fetchFn(url, options);
  };
};
