export const DEFAULT_TEXT_LOCATION = {
  horizontal: 'start',
  vertical: 'end',
};

export const DEFAULT_TEXT_CARD_SUBTITLE = {
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_small',
  fontStyle: 'medium',
  text: '',
  textColor: null,
};

export const DEFAULT_TEXT_CARD_TITLE = {
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_large',
  fontStyle: 'medium',
  text: '',
  textColor: null,
};

export const DEFAULT_TEXT_CARD_BODY = {
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'small',
  fontSizeSet: false,
  fontStyle: 'regular',
  text: '',
  textColor: null,
};

export const DEFAULT_SUPPORTED_TAGS = ['b', 'i', 'u', 'a', 'br', 'wbr'];

export const EXTENDED_SUPPORTED_TAGS = [
  ...DEFAULT_SUPPORTED_TAGS,
  'ul',
  'ol',
  'li',
  'strong',
  'em',
  'p',
];
