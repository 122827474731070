import createReducer from '../../../utils/createReducer';
import { identityStatusActions } from '../../../actions';

const initialState = {
  initialized: false,
  timedout: false,
};

export const identityReducer = createReducer(initialState, {
  [identityStatusActions.initialized.type]: () => ({
    initialized: true,
    timedout: false,
  }),
  [identityStatusActions.timedout.type]: () => ({
    initialized: false,
    timedout: true,
  }),
});
