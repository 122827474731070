import { fork, takeEvery, take, select } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';

import {
  bootstrapActions,
  productRecommenderApiAction,
  shopTheLookAddToCart,
  impressionObserverActions,
} from '../actions';
import { bootstrapSaga } from './bootstrapSaga';
import { analyticsSaga, impressionEventsSaga } from './analytics';
import { personalizationSaga } from './personalization';
import { notifyMeSubscriptionSaga } from './notifyMeSubscriptionSaga';
import { fetchUrlsSaga } from './fetchUrlsSaga';
import {
  watchShopTheLookSizesSaga,
  shopTheLookCartSaga,
  watchShopTheLookCartSaga,
} from './shopTheLook';
import { clientLoggerBootstrapSaga } from './loggingSaga';
import { getExtendedFetchFn } from '../services/dataLayerWrapper/extendedFetchGenerator';

export default function* rootSaga(dispatch) {
  /**
   * With nessie-based application root saga was run during store configuration with store being already hydrated.
   * See https://github.com/nike-internal/dotcom.ciclp-landing-pages/blob/7f83487975a95433323f0dc1da5d4058cf58fbea/packages/ciclp-react-client/src/isomorphic/index.js#L15
   * With webs-shell-next the next-redux-wrapper configures store with initial (empty) state and they hydrates it,
   * so we need to wait for HYDRATE action before running sagas.
   */

  yield take(HYDRATE);
  const state = yield select();
  globalThis.extendedFetch = getExtendedFetchFn(state);

  yield fork(clientLoggerBootstrapSaga);

  yield takeEvery(bootstrapActions.request.type, bootstrapSaga, dispatch);
  yield takeEvery(productRecommenderApiAction.success.type, fetchUrlsSaga);
  yield takeEvery(shopTheLookAddToCart.request.type, shopTheLookCartSaga);

  yield fork(analyticsSaga, dispatch);
  yield fork(personalizationSaga);
  yield fork(notifyMeSubscriptionSaga);
  yield fork(watchShopTheLookSizesSaga);
  yield fork(watchShopTheLookCartSaga);
  yield takeEvery(impressionObserverActions.init.type, impressionEventsSaga, {
    dispatch,
  });
}
