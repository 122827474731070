export const getNikeShop = () => {
  return window?.NikeShop;
};

export const getCartState = () => {
  return getNikeShop()?.get() ?? {};
};

export const getCartItems = () => {
  return getCartState().cart?.items ?? [];
};

export const addToCart = async product => {
  const nikeShop = getNikeShop();
  if (!nikeShop) {
    return Promise.reject(new Error('No NikeShop client found'));
  }
  const addToCartResponse = await nikeShop.addToCart(product);

  return addToCartResponse;
};

export const navigateToCheckout = () => {
  return getNikeShop()?.navigateToCheckout({
    redirectToCheckout: true,
  });
};

export const subscribeOnStoreUpdate = fn => {
  // it returns unsubscribe function
  return getNikeShop()?.internalStore.subscribe(() => {
    fn(getNikeShop().internalStore.getState());
  });
};
