import { map, pipe, filter } from 'ramda';
import {
  extractNodeProps,
  findGroupNodes,
  findHeaderNode,
  findLinkNodes,
} from '.';

export const getMerchMenuItems = ({ id, nodes }) =>
  pipe(
    findGroupNodes(nodes),
    filter(node => (node?.nodes?.length ?? 0) > 0),
    map(node => ({
      ...extractNodeProps(findHeaderNode(nodes, node.id)),
      links: findLinkNodes(nodes, node.id).map(extractNodeProps),
    })),
  )(id);
