import { logLevels, DetailedError } from '../index';

export const flattenObject = (obj, parentKey) => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value, key));
    } else if (parentKey) {
      // body duplicates requestBody properties; header properties are not needed
      if (parentKey !== 'headers' && key !== 'body') {
        flattened[`lp.${parentKey}.${key}`] = value || '';
      }
    } else {
      flattened[`lp.${key}`] = value || '';
    }
  });

  return flattened;
};

const LOG_EVENT_TYPES = {
  [logLevels.warn]: 'warn',
  [logLevels.info]: 'info',
};

const logError = (agent, event) => {
  if (event instanceof DetailedError) {
    const flattenedDetails = flattenObject(event.details);
    agent.noticeError(event, flattenedDetails);
  } else {
    agent.noticeError(event);
  }
};

const logEvent = (agent, event, logEventType) => {
  const { name, message, details = {} } = event;
  const flattenedDetails = flattenObject(details);
  const eventPayload = {
    logEventMessage: message,
    logEventName: name,
    logEventType,
    ...flattenedDetails,
  };
  if (__IS_SERVER__) {
    agent.recordCustomEvent('LPAppLogEvent', eventPayload);
  } else {
    agent.addPageAction('LPAppLogEvent', eventPayload);
  }
};

export const newRelicTransport = newRelicAgent => (level, event) => {
  if (level === logLevels.error) {
    logError(newRelicAgent, event);
    return;
  }

  const canLogInfo =
    process.env.NEXT_PUBLIC_LOG_INFO_TO_NEWRELIC === 'true' &&
    level === logLevels.info;
  if (level === logLevels.warn || canLogInfo) {
    logEvent(newRelicAgent, event, LOG_EVENT_TYPES[level]);
  }
};
