import {
  rewriteUrl,
  getRewritedPath,
  mockServerVip,
  isURLShouldBeRouted,
} from '@nike/ciclp-utils/fetchWrapper';
import { FEATURE_FLAGS } from '@nike/ciclp-config';
import { featureFlagsSelector } from '../../../store/state/stateSelector';

const mockServerForwardHandler = (state, fetchFunction) => {
  const featureFlags = featureFlagsSelector(state);
  const mocksEnabled = featureFlags[FEATURE_FLAGS.WITH_MOCKS];

  if (!mocksEnabled) {
    return fetchFunction;
  }

  return (url, options = {}) => {
    if (isURLShouldBeRouted(url)) {
      if (!options.headers) {
        options.headers = {};
      }

      options.path = getRewritedPath(url);
      options.vipAddress = mockServerVip;

      if (!options.fetchParams) {
        options.fetchParams = {
          timeout: 1000,
        };
      }
      options.fetchParams.timeout *= 10;

      if (process.env.MOCK_CLOUD_STACK) {
        options.headers.cloud_stack = process.env.MOCK_CLOUD_STACK;
        options.vipAddress = mockServerVip + process.env.MOCK_CLOUD_STACK;
      }
      // ToDo: think of better way to avoid CORS while using mock service: https://jira.nike.com/browse/CICLP-3001
      delete options.credentials;

      url = rewriteUrl(url);
    }
    return fetchFunction(url, options);
  };
};

export default mockServerForwardHandler;
