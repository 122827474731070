import * as jsonPatch from 'fast-json-patch';

export const applyPatch = (target, patch) => {
  const mutateDocument = false;
  const validatePatch = true;

  return jsonPatch.applyPatch(target, patch, validatePatch, mutateDocument)
    .newDocument;
};

export const getPatch = (original, variation) => {
  return jsonPatch.compare(original, variation);
};
