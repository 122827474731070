import { map, pipe } from 'ramda';
import { extractNodeProps, findHeaderNodes, findLinkNodes } from './index';

export const getLocalNavItems = ({ id, nodes }) =>
  pipe(
    findHeaderNodes(nodes),
    map(node => ({
      ...extractNodeProps(node),
      links: findLinkNodes(nodes, node.id).map(extractNodeProps),
    })),
  )(id);

export const getLocalNavTitle = ({ threadTitle, localNavNode }) => {
  const localNavCustomTitle =
    localNavNode?.properties?.metadataDecorations?.[0]?.payload;

  if (localNavCustomTitle?.localNavTitleStyle !== 'custom') {
    return threadTitle;
  }
  return localNavCustomTitle?.customTitle?.trim();
};
