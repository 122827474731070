import createReducer from '../../../utils/createReducer';
import { translationActions } from '../../../actions';

const initialState = {
  podiumTranslations: {},
  translations: {},
};

export const translationsReducer = createReducer(initialState, {
  [translationActions.update.type]: (state, { payload }) => {
    return {
      ...state,
      podiumTranslations: payload.podiumTranslations,
      translations: payload.translations,
    };
  },
});
