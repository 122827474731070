import { createSelector } from 'reselect';
import { stateSelector } from '../stateSelector';
import { getProductCarouselTranslations } from '../../../services/transforms/helpers';

const stringKeySelector = (state, stringKey) => stringKey;

// get all translation strings
export const translationStringsSelector = createSelector(
  stateSelector,
  appState => appState?.localization?.translations ?? {},
);

export const podiumTranslationsSelector = createSelector(
  stateSelector,
  appState => appState?.localization?.podiumTranslations ?? {},
);

export const productCardLabelsSelector = createSelector(
  translationStringsSelector,
  getProductCarouselTranslations,
);

// get specific key
export const translationSelector = createSelector(
  stringKeySelector,
  translationStringsSelector,
  (stringKey, translations = {}) => translations[stringKey],
);
