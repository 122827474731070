import { pipe, find, head, equals, pick, last } from 'ramda';
import { createSelector } from 'reselect';
import { ownPropsSelector } from '../../utils/ownPropsSelector';
import { dataSelector } from './dataSelector';

export const urlsSelector = createSelector(
  dataSelector,
  data => data?.urls ?? [],
);

const urlIngredientProperties = [
  'type',
  'styleColor',
  'prebuildId',
  'conceptIds',
  'pageId',
];

export const urlSelector = createSelector(
  urlsSelector,
  ownPropsSelector,
  (urls, destination = {}) => {
    const urlEntry = find(
      pipe(head, equals(pick(urlIngredientProperties, destination))),
      urls,
    );
    return urlEntry ? last(urlEntry) : null;
  },
);
