import { createSelector } from 'reselect';
import { CHANNEL_IDS } from '@nike/ciclp-config';
import { routeNames } from '../../enum/routeNames';

export const routerSelector = state => state.router;

export const routeSelector = createSelector(
  routerSelector,
  router => router?.route,
);

export const routeNameSelector = createSelector(
  routeSelector,
  route => route?.name,
);

export const routeParamsSelector = createSelector(
  routeSelector,
  route => route?.params ?? {},
);

export const slugSelector = createSelector(
  routeParamsSelector,
  ({ page = [] }) => {
    if (!Array.isArray(page)) {
      page = [];
    }

    const slug = page.join('/');
    return slug.replace(/^\//, '').toLowerCase();
  },
);

export const threadIdSelector = createSelector(
  routeParamsSelector,
  routeParams => routeParams?.threadId,
);

export const channelIdSelector = createSelector(
  routeParamsSelector,
  routeParams => routeParams?.channelId,
);

export const isBannerChannelIdSelector = createSelector(
  channelIdSelector,
  channelId => channelId === CHANNEL_IDS.BANNER,
);

export const transitionErrorSelector = createSelector(
  routerSelector,
  router => router?.transitionError,
);

export const isPreviewSelector = createSelector(
  routeNameSelector,
  routeName =>
    routeName === routeNames.PREVIEW || routeName === routeNames.PREVIEW_V2,
);

export const isBannerPreview = createSelector(
  isPreviewSelector,
  isBannerChannelIdSelector,
  (isPreview, isBannerChannelId) => isPreview && isBannerChannelId,
);

export const previewTokenSelector = createSelector(
  routeParamsSelector,
  params => params['cms-auth-token'],
);

export const pageNumberSelector = createSelector(routeParamsSelector, params =>
  parseInt(params?.p ?? 1, 10),
);
