import { put, call, select, take, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { identityStatusActions } from '../../actions';
import { identityStateSelector } from '../../store/state/identity/identitySelectors';

export const identityInitialisation = function* (maxInitAttempts) {
  for (let i = 0; i < maxInitAttempts; i++) {
    const isInitialized = yield call(
      window.webShellClient.identity.getInitialized,
    );

    if (isInitialized) {
      yield put(identityStatusActions.initialized());
      return;
    }
    yield delay(500);
  }
  yield put(identityStatusActions.timedout());
};

export const waitForIdentityInitialization = function* () {
  const identityStatus = yield select(identityStateSelector);
  if (!identityStatus.initialized && !identityStatus.timedout) {
    yield race({
      identity: take(identityStatusActions.initialized.type),
      timeout: take(identityStatusActions.timedout.type),
    });
  }
};
