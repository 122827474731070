import React from 'react';

import { NikeDotcomApp } from '@nike/web-shell-next/dist/components/NikeDotcomApp';
import { configureStore } from '@nike/ciclp-redux-app';
import { createWrapper } from 'next-redux-wrapper';

const serializeState = state => JSON.stringify(state);
const deserializeState = state => JSON.parse(state);
export const wrapper = createWrapper(() => configureStore(), {
  deserializeState,
  serializeState,
});

const setNavConfig = navConfig => config => ({
  ...config,
  appInfo: {
    ...config.appInfo,
    environment: 'production',
    name: navConfig?.pageDetail,
    version: process.env.NES_COMMIT_SHA ?? 'development',
    viewChannel: navConfig?.analyticsPageType,
    viewName: navConfig?.globalNavPageName,
  },
});

export default wrapper.withRedux(props => (
  <NikeDotcomApp
    {...props}
    beforeNavMount={setNavConfig(props.pageProps?.navConfig)}
  />
));
