import { call } from 'redux-saga/effects';
import logger from '@nike/ciclp-utils/logger';

/**
 * Web Shell Optimization initialization can fail due to network issue. It throws
 * an error then that should be handled to not stop the sagas due to unhandled error
 * exception.
 */
export const getOptimizationLocations = function* (
  optimizationName,
  optimizationParameters,
) {
  let optimizationResponse;
  try {
    optimizationResponse = yield call(
      window.webShellClient.optimization.__WS_UNSTABLE__.getLocations,
      {
        locations: [
          {
            name: optimizationName,
            parameters: optimizationParameters,
          },
        ],
      },
    );
  } catch (error) {
    const { name, stack, details } = error;
    logger.warn({
      details,
      message: 'Failed to get optimization locations',
      name,
      stack,
    });
  }
  return optimizationResponse;
};
