import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

// creates additional layer of cache only on the client
// because on server side it's not that useful and also could lead to memory leak
const createCachedSelectorOnClient =
  (...args) =>
  keySelector =>
    typeof window !== 'undefined' && typeof window.document !== 'undefined'
      ? createCachedSelector(...args)(keySelector)
      : createSelector(...args);

export default createCachedSelectorOnClient;
