import { identity } from 'ramda';
import { createSelector } from 'reselect';
import { ownPropsSelector } from '../../utils/ownPropsSelector';
import { getCardDataSelector } from './getCardDataSelector';

export const getFallbackDataSelector = createSelector(
  identity,
  ownPropsSelector,
  (state, fallbacks) => {
    return fallbacks?.map(fallback => getCardDataSelector(state, fallback));
  },
);
