import { nanoid } from 'nanoid';

export const setCookie = (cookieName, cookieValue, daysToExpire) => {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${cookieValue}; expires=${date.toGMTString()}`;
};

export const getCookie = cookieName => {
  const allCookies = document.cookie.split('; ');
  const cookie = allCookies.find(cookieString =>
    cookieString.startsWith(`${cookieName}=`),
  );
  if (cookie) {
    return cookie.split('=').slice(1).join('=');
  }

  return undefined;
};

export const getUserFromCookie = () => __IS_WEB__ && getCookie('anonymousId');

export const setAnonymousId = () => setCookie('anonymousId', nanoid(), 365);

export const parseAtCookie = cookieValue => {
  if (!cookieValue) {
    return {};
  }

  const splitValue = cookieValue.split('&').map(item => item.split('='));
  return Object.fromEntries(splitValue);
};
