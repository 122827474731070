import { createSelector } from 'reselect';
import { pageDataPropertiesSelector } from './pageSelectors';

export const seoSelector = createSelector(
  pageDataPropertiesSelector,
  properties => properties?.seoProperties,
);

export const seoOfficialPathSelector = createSelector(seoSelector, seo =>
  (seo?.officialPath ?? '').replace(/>\//i, ''),
);
