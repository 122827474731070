export interface FetchParams {
  fetchParams?: {
    timeout?: number;
    baseUrl?: string;
  };
  resource?: string;
}

export type ExtendedOptions = RequestInit & {
  vipAddress?: string;
  path?: string;
  timeout?: number;
};
export type ServerErrorDetails = {
  url: string;
  traceId?: string;
  resource?: string;
  requestOptions?: ExtendedOptions;
  requestBody?: BodyInit;
  responseBody?: string;
  responseStatus?: number;
  responseStatusText?: string;
  timeout?: number;
  originalMessage?: string;
};

export enum ErrorMessage {
  GENERIC = 'Service request failed',
  SERVICE_RESPONSE_FAILED = 'Service responded with HTTP code different from 200',
  TIMEOUT = 'Failed due to timeout',
  JSON_PARSE = 'Error converting response to JSON',
}
