export const updateScripts = attribute => {
  Array.prototype.slice
    .call(document.querySelectorAll(`[data-src][${attribute}]`))
    .forEach(script =>
      script.setAttribute('src', script.getAttribute('data-src')),
    );
};

export const documentComplete = () => {
  if (document.readyState === 'complete') {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    document.addEventListener('readystatechange', function (event) {
      if (event.target.readyState === 'complete') {
        resolve();
      }
    });
  });
};
