import { all, call, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { identity } from 'ramda';

import { analyticsProperties, eventNames } from '../../analytics/constants';

import {
  analyticsActions,
  optimizationActions,
  recommendationActions,
} from '../../actions';
import { computeAnalyticsData } from '../../services/analytics';

export const analyticsDataComputationSaga = function* () {
  const state = yield select(identity);
  const data = computeAnalyticsData(state);

  yield put(analyticsActions.prepareData(data));
};

const cardsState = {};
const landmarks = [
  { left: 50, top: 20 },
  { left: 50, top: 80 },
];

let observer = null;

export const createImpressionEventsHandler = dispatch => entries => {
  const timestamp = Date.now();
  entries.forEach(({ target, intersectionRect, boundingClientRect }) => {
    const placementId = target?.dataset?.analyticsPlacementId;
    const landmarksState = landmarks.map(
      ({ left, top }) =>
        boundingClientRect.left + (boundingClientRect.width * top) / 100 > 0 &&
        intersectionRect.left + intersectionRect.width >
          boundingClientRect.left + (boundingClientRect.width * left) / 100 &&
        boundingClientRect.top + (boundingClientRect.height * top) / 100 > 0 &&
        intersectionRect.top + intersectionRect.height >
          boundingClientRect.top + (boundingClientRect.height * top) / 100,
    );
    landmarksState.forEach((isLandmarkVisible, index) => {
      if (isLandmarkVisible && !cardsState?.[placementId]?.[index])
        window.requestIdleCallback(
          () => {
            dispatch(
              analyticsActions.track({
                eventName: eventNames.IMPRESSION,
                [analyticsProperties.LANDMARK_X]: landmarks[index].left,
                [analyticsProperties.LANDMARK_Y]: landmarks[index].top,
                [analyticsProperties.TIMESTAMP]: `${timestamp}`,
                placementId,
              }),
            );
          },
          { timeout: 300 },
        );
    });
    cardsState[placementId] = landmarksState;
  });
};

export const impressionEventsSaga = function ({ dispatch }) {
  const callback = createImpressionEventsHandler(dispatch);

  if (observer) {
    observer.disconnect();
    observer = null;
  }

  observer = new IntersectionObserver(callback, {
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  });

  const elements = Array.from(
    document.querySelectorAll('[data-analytics-has-landmark]'),
  );
  const placementIdsOnPage = elements.map(
    element => element?.dataset?.analyticsPlacementId,
  );

  elements.forEach(element => {
    Object.keys(cardsState).forEach(placementIdInCardState => {
      if (placementIdsOnPage.indexOf(placementIdInCardState) === -1) {
        cardsState[placementIdInCardState] = null;
      }
    });
    observer.observe(element);
  });
};

export function* processAnalyticsComputation(dispatch) {
  // Delay is needed to render active variation and calculate analytics properties only after this
  yield delay(0);
  yield call(analyticsDataComputationSaga);
  if (typeof IntersectionObserver !== 'undefined') {
    yield call(impressionEventsSaga, { dispatch });
  }
}

export const analyticsSaga = function* (dispatch) {
  yield all([
    take(recommendationActions.ready.type),
    take(optimizationActions.loadLocations.type),
  ]);
  yield call(processAnalyticsComputation, dispatch);
  yield put(analyticsActions.page());
};
