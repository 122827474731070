export const formatLocalizedSize = (
  countrySpecification?: Inventory.CountrySpecification,
): string => {
  if (!countrySpecification?.localizedSize) {
    throw new Error('No localized size provided');
  }

  const localizedSizePrefix = countrySpecification.localizedSizePrefix
    ? `${countrySpecification.localizedSizePrefix} `
    : '';
  return `${localizedSizePrefix}${countrySpecification.localizedSize}`;
};

export const getLocalizedSize = (
  skuData: Inventory.SkuDataObject,
  country: string,
): string => {
  const countrySpecification = skuData?.countrySpecifications?.find(
    ({ country: specificationCountry }) => country === specificationCountry,
  );
  let size = skuData.nikeSize;
  try {
    size = formatLocalizedSize(countrySpecification);
  } catch (e) {
    // no action required
  }
  return size;
};
