import { createSelector } from 'reselect';
import { stateSelector } from '../stateSelector';

export const privacySelector = createSelector(
  stateSelector,
  state => state.privacy,
);

// 1 === user allows performance but not marketing cookies
// 3 === user allows both performance and marketing cookies
// see privacySaga for more details
export const PERSONALIZATION_PRIVACY_LEVELS = [3];
export const PERFORMANCE_PRIVACY_LEVELS = [1, 3];

export const canPersonalizeSelector = createSelector(
  privacySelector,
  privacy =>
    privacy?.isNikePrivacyAllowed ??
    PERSONALIZATION_PRIVACY_LEVELS.includes(privacy?.level),
);

export const canUseAnalytics = createSelector(
  privacySelector,
  privacy =>
    privacy?.isNikePrivacyAllowed ??
    (privacy?.isSet && PERFORMANCE_PRIVACY_LEVELS.includes(privacy?.level)),
);

export const isPrivacySet = createSelector(
  privacySelector,
  privacy => privacy?.isNikePrivacyAllowed ?? privacy?.isSet,
);

export const canRunOptimizationSelector = createSelector(
  isPrivacySet,
  canPersonalizeSelector,
  (isSet, isAbleToPersonalize) => isSet && isAbleToPersonalize,
);
