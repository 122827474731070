import {
  findLast,
  pipe,
  equals,
  dissocPath,
  clone,
  map,
  propEq,
  values,
  mergeRight,
  mapObjIndexed,
} from 'ramda';
import createReducer from '../../utils/createReducer';
import {
  CARD_TYPES,
  EDITORIAL_TYPES,
  LOCAL_NAV_PLACEHOLDER_CARD_ID,
  SHOP_THE_LOOK_CARD_PREFIX,
} from '../../constants';
import {
  productsApiActions,
  pageThreadApiActions,
  urlGeneratorApiActions,
  bannerFeedApiActions,
  pathToRootActions,
  recommendationActions,
  productStatusesApiActions,
  dynamicContentActions,
  snkrsDropsActions,
  dynamicLocalNavActions,
  cmsCollectionActions,
  shopTheLookProductsLoadedAction,
  contentFeatureActions,
  variantPatchesActions,
} from '../../actions';
import { getShopTheLookImageProperties, getCardFromGallery } from './helpers';

const defaultState = {
  banner: {},
  buyingToolsIngredients: [],
  cards: {},
  pageData: {
    showBanner: true,
  },
  requestResponse: [],
  urls: [],
};

export const dataReducer = createReducer(defaultState, {
  [pageThreadApiActions.success.type]: (state, action) => ({
    ...state,
    ...action.payload.entities,
  }),
  [productsApiActions.success.type]: (state, { payload = [] }) =>
    pipe(clone, newState => ({
      ...newState,
      cards: map(c => {
        const { collectionId, ...restCard } = c;
        return {
          ...restCard,
          slides: collectionId
            ? findLast(propEq('id', collectionId), payload)?.slides
            : restCard.slides,
        };
      })(newState.cards),
    }))(state),
  [recommendationActions.putPRSSlides.type]: (state, { payload }) => {
    return pipe(clone, newState => {
      return {
        ...newState,
        cards: {
          ...newState.cards,
          ...mapObjIndexed(
            (c, key) => ({
              ...newState.cards[key],
              ...c,
              isLoading: false,
            }),
            payload,
          ),
        },
      };
    })(state);
  },
  [urlGeneratorApiActions.success.type]: (state, { payload } = {}) => ({
    ...state,
    urls: [...state.urls, ...payload],
  }),
  [productStatusesApiActions.success.type]: (state, { payload } = {}) => ({
    ...state,
    buyingToolsIngredients: [...state.buyingToolsIngredients, ...payload],
  }),
  [bannerFeedApiActions.success.type]: (state, { payload }) => ({
    ...state,
    banner: {
      nodes: payload,
    },
  }),
  [pathToRootActions.set.type]: (state, { payload: pathToRoot }) => ({
    ...state,
    pathToRoot,
  }),
  [dynamicContentActions.insertItems.type]: (state, { payload } = {}) => ({
    ...state,
    cards: map(card => {
      const areEditorialattributeIdsAllChanged =
        !equals(payload.attributeIdsAll, card.attributeIdsAll) ||
        !equals(payload.attributeIds, card.attributeIds) ||
        !equals(payload.excludeAttributeIds, card.excludeAttributeIds);

      if (
        !values(EDITORIAL_TYPES).includes(card.containerType) ||
        areEditorialattributeIdsAllChanged
      )
        return card;
      const { slides, pagination } = payload;

      return {
        ...card,
        pagination,
        slides,
      };
    }, state.cards),
  }),
  [dynamicContentActions.insertFeatured.type]: (state, { payload }) => ({
    ...state,
    cards: map(({ featured, ...card }) =>
      !values(EDITORIAL_TYPES).includes(card.containerType) ||
      payload.collectionId !== featured
        ? card
        : {
            ...card,
            featuredCard: payload.featured,
          },
    )(state.cards),
  }),
  [snkrsDropsActions.updateItems.type]: (state, { payload = {} }) => ({
    ...state,
    cards: map(card => {
      const snkrsCard = payload.find(({ cardId }) => card.id === cardId);
      return snkrsCard
        ? {
            ...card,
            ...{
              slides: snkrsCard.slides,
              slidesUpcoming: snkrsCard.slidesUpcoming,
            },
          }
        : card;
    }, state.cards),
  }),
  [dynamicLocalNavActions.removePlaceholder.type]: dissocPath([
    'cards',
    LOCAL_NAV_PLACEHOLDER_CARD_ID,
  ]),
  [dynamicLocalNavActions.insert.type]: (state, { payload } = {}) => {
    const shouldUpdateH1 =
      !!payload?.title &&
      !values(state.cards).some(c => c.containerType === CARD_TYPES.TITLE);

    return {
      ...state,
      cards: {
        ...state.cards,
        local_nav_placeholder: mergeRight(
          state.cards[LOCAL_NAV_PLACEHOLDER_CARD_ID],
          payload,
        ),
      },
      layoutItems: map(({ withH1, ...item }) =>
        shouldUpdateH1
          ? mergeRight(
              item,
              (withH1 || item?.data === LOCAL_NAV_PLACEHOLDER_CARD_ID) &&
                shouldUpdateH1 && {
                  withH1: item?.data === LOCAL_NAV_PLACEHOLDER_CARD_ID,
                },
            )
          : { ...item, withH1 },
      )(state.layoutItems),
    };
  },
  [cmsCollectionActions.loaded.type]: (
    state,
    { payload: cmsCollectionCards },
  ) => {
    return {
      ...state,
      cards: {
        ...state.cards,
        ...cmsCollectionCards,
      },
    };
  },
  [shopTheLookProductsLoadedAction.success.type]: (state, { payload = [] }) => {
    const generatedCards = payload.reduce((acc, card) => {
      const generatedCardId = `${SHOP_THE_LOOK_CARD_PREFIX}${card.actionId}`;
      const cardFromStore =
        state.cards[card.originalCardId] ||
        getCardFromGallery(
          state.cards[card.galleryCardId],
          card.originalCardId,
        );

      return {
        ...acc,
        [generatedCardId]: {
          containerType: CARD_TYPES.SHOP_THE_LOOK,
          id: generatedCardId,
          originalCardId: card.originalCardId,
          products: card.slides,
          ...getShopTheLookImageProperties(cardFromStore),
        },
      };
    }, {});

    return {
      ...state,
      cards: {
        ...state.cards,
        ...generatedCards,
      },
    };
  },
  [contentFeatureActions.setContentFeatures.type]: (
    state,
    { payload = {} },
  ) => {
    return {
      ...state,
      contentFeatures: payload,
    };
  },
  [variantPatchesActions.setRenderedVariantId.type]: (state, { payload }) => {
    return {
      ...state,
      renderedVariantId: payload,
    };
  },
});
