import { MEDIA_TABLET } from '@nike/ciclp-react-components/context/utils';
import packageJson from '../../../package.json';
import {
  countryCodeSelector,
  languageCodeSelector,
  languageMappingsSelector,
  localeSelector,
} from '../../store/state/stateSelector';
import {
  classificationSelector,
  analyticsPageTypeSelector,
  pageNameSelector,
  globalNavPageNameSelector,
  pageDetailSelector,
  schemaSelector,
} from '../analyticsSelectors';
import { optimizelySelectors } from '../../store/state/optimizely';
import { getAnalyticsEvent } from './analyticsEvent';
import {
  analyticsProperties,
  analyticsViewProperties,
  eventNames,
} from '../constants';
import { optimizationAnalyticsSelector } from '../../store/state/optimization/selectors';

const { APP_BACKEND_PLATFORM, PAGE_DIVISION, SITE, ENVIRONMENT, VERSION } =
  analyticsViewProperties;

/**
 * Get page load segment payload
 * @param {String} eventName analytics event name
 * @param {Object} state redux state object
 * @param {Object} eventData event details
 * @returns populated segment payload
 */

export const getSegmentPayload = ({ eventName, state, eventData }) => {
  const classification = classificationSelector(eventName);
  const {
    clickActivity,
    video,
    product = {},
    modelId,
    modelInfo,
    modelVersion,
    selectedConcepts,
    selectedConceptsIds,
    ...content
  } = getAnalyticsEvent(eventName, state, eventData);
  const isPageEvent = eventName === eventNames.PAGE_VIEWED;
  const analytics = optimizationAnalyticsSelector(state);

  const optimizelyAbTestData =
    optimizelySelectors.getOptimizelyAnalyticsPropertySelector(state);
  const abTest = {
    ...(isPageEvent && !!analytics && { analytics }),
    ...(optimizelyAbTestData.experiments?.length && optimizelyAbTestData),
  };

  const hasAbTest = Object.keys(abTest).length > 0;
  const hasModelData = modelInfo || modelId || modelVersion;
  const model = {
    modelId,
    modelInfo,
    modelVersion,
  };

  const contentData = {
    ...content,
    ...(eventName === eventNames.CLICK && {
      contentClickActivity: clickActivity,
    }),
  };

  return {
    app: {
      backendPlatform: APP_BACKEND_PLATFORM,
      domain: process.env.NEXT_PUBLIC_HOST_NAME,
      version: VERSION,
    },
    classification,
    clickActivity,
    content: contentData,
    language: languageMappingsSelector(state)?.langRegion,
    locale: {
      country: countryCodeSelector(state),
      language: languageCodeSelector(state),
    },
    video,
    view: {
      experienceType: SITE,
      pageName: pageNameSelector(state),
      pageType: analyticsPageTypeSelector(state),
    },
    ...(hasAbTest && { abTest }),
    ...(hasModelData && { model: [model] }),
    ...(selectedConcepts && {
      [analyticsProperties.SELECTED_CONCEPTS]: selectedConcepts,
    }),
    ...(selectedConceptsIds && {
      [analyticsProperties.SELECTED_CONCEPTS_IDS]: selectedConceptsIds,
    }),
    ...product,
  };
};

/**
 * Get options for segment payload
 * @param {String} eventName analytics event name
 * @returns populated segment options
 */

export const getSegmentOptions = ({ eventName }) => {
  const schemaId = schemaSelector(eventName);

  return {
    $schema: schemaId,
  };
};

/**
 * Get page load mcJS (formerly known as NeoJS or DCM) payload
 * @param {String} eventName analytics event name
 * @param {Object} state redux state object
 * @returns populated McJS payload
 */

export const getMarketingPayload = ({ eventName, state }) => ({
  application: {
    country: countryCodeSelector(state),
    device: window.innerWidth < MEDIA_TABLET ? 'Mobile' : 'Desktop',
    language: (languageMappingsSelector(state)?.langRegion ?? '')
      .toLowerCase()
      .replace('-', '_'),
    view: analyticsPageTypeSelector(state),
  },
  consumer: {
    currencyType: '',
  },
  event: eventName,
  productList: [],
});

/**
 * Get dot com nav payload piece
 * @param {Object} state redux state object
 * @returns populated segment payload
 */

export const getDotComNavPayload = ({ state }) => ({
  appInfo: {
    division: PAGE_DIVISION,
    domain: SITE,
    environment: ENVIRONMENT,
    name: pageDetailSelector(state),
    platform: APP_BACKEND_PLATFORM,
    version: packageJson.version,
    viewChannel: analyticsPageTypeSelector(state),
    viewName: globalNavPageNameSelector(state),
  },
  unite: {
    backendenvironment: 'identity',
    clientid: process.env.UNITE_CLIENT_ID,
    environment: ENVIRONMENT,
    locale: localeSelector(state),
    uxid: 'com.nike.commerce.nikedotcom.web',
  },
});
