import { logLevels } from '../index';
import errorReplacer from '../errorReplacer';

export const consoleTransport = (level, message) => {
  const messageString =
    typeof message === 'string'
      ? message
      : JSON.stringify(message, errorReplacer);
  if (level === logLevels.info) {
    console.log(messageString);
  } else if (level === logLevels.warn) {
    console.warn(messageString);
  } else if (level >= logLevels.error) {
    console.error(messageString);
  }
};

export default consoleTransport;
