import { getUrl } from '@nike/ciclp-data-fetch-layer';
import { API_BASE_URL, CHANNEL_IDS } from '@nike/ciclp-config';
import {
  prepareUrlIngredients,
  prepareUrlIngredientsResults,
} from './fetchUrlsUtils';

export const fetchUrls = async ({
  fetchFn,
  ingredients,
  language,
  marketplace,
  clientId,
  onFetchError,
}) => {
  const urlIngredients = prepareUrlIngredients(ingredients);
  const results = await Promise.all(
    urlIngredients.map(async ingredient => {
      try {
        const { type, ...ingredientWithoutType } = ingredient;
        const fetchResult = await getUrl({
          fetchFn,
          params: {
            callerId: clientId,
            consumerChannelId: CHANNEL_IDS.DEFAULT,
            languageTag: language,
            marketplace,
            urlType: type,
            ...ingredientWithoutType,
            fetchParams: {
              baseUrl: API_BASE_URL,
            },
          },
        });
        return [ingredient, fetchResult.url];
      } catch (error) {
        onFetchError(error);
        return null;
      }
    }),
  );
  return prepareUrlIngredientsResults(results);
};
