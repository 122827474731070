import constants from '@nike/ciclp-react-components/constants';
import { CARD_TYPES } from '../../../constants';
import {
  extractIngredientsFromList,
  getCarouselWithTitle,
  getProductCarouselTranslations,
  getTitle,
  mergeCarouselProperties,
} from '../helpers';
import {
  getActions,
  getProductContainerPlacements,
} from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = cardData => {
  const sectionHeadline = getTitle(cardData);
  const maxResults =
    cardData?.properties?.custom?.maxResults ??
    constants.MAX_PRODUCT_LIST_LENGTH;
  const taxonomies =
    cardData?.properties?.valueMap?.product_recommender_taxonomy;
  const clearance = cardData?.properties.custom?.clearance;

  return {
    ...getCommonProps(cardData),
    clearance,
    dataQa: CARD_TYPES.PRODUCT_RECOMMENDER,
    fallbacks: cardData?.properties?.fallbacks,
    isLoading: true,
    maxResults,
    sectionHeadline,
    slides: [],
    taxonomies,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  ...getProductCarouselTranslations(translationsStrings),
  personalizedTitle: translationsStrings.productContainer_personalized_title,
  title: translationsStrings.productContainer_recommended_for_you,
  topTrendingTitle: translationsStrings.productContainer_top_trending,
});

export const mergeProperties = ({
  cardData,
  translations,
  urls,
  countryCode,
  languageRegion,
  languageCountryPath,
  languageMappings,
}) =>
  mergeCarouselProperties({
    cardData: getCarouselWithTitle({ cardData, translations }),
    countryCode,
    languageCountryPath,
    languageMappings,
    languageRegion,
    translations,
    urls,
  });

export const getUrlIngredients = card => [
  ...extractIngredientsFromList(card?.sectionHeadline?.actions),
  ...extractIngredientsFromList(card?.slides),
];

export { getProductContainerPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card =>
  getActions(card.sectionHeadline.actions);

export { getBuyingToolsIngredients } from './common';
