import { createSelector } from 'reselect';
import { clientIds } from '@nike/ciclp-config';
import { isHomepageSelector } from '../../analytics/analyticsSelectors';
import { ownPropsSelector } from '../../utils/ownPropsSelector';

export const clientIdSelector = createSelector(
  isHomepageSelector,
  ownPropsSelector,
  (isHomepage, serviceName) =>
    clientIds[serviceName || (isHomepage ? 'homepage' : 'landingpage')],
);
