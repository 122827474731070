import {
  newRelicTransport,
  nessieTransport,
  consoleTransport,
} from './transports';

export const getServerTransports = ({ externalLogger, newrelic }) => {
  const transports = [];
  if (newrelic) {
    transports.push(newRelicTransport(newrelic));
  }

  if (externalLogger) {
    transports.push(nessieTransport(externalLogger));
  }
  return transports;
};

export const getClientTransports = ({ isConsoleLoggingEnabled }) => {
  const transports = [];

  if (window.newrelic) {
    transports.push(newRelicTransport(window.newrelic));
  }

  if (isConsoleLoggingEnabled) {
    transports.push(consoleTransport);
  }
  return transports;
};
