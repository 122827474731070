import {
  find,
  filter,
  keys,
  pipe,
  values,
  map,
  flatten,
  propOr,
  prop,
  uniq,
} from 'ramda';
import { createSelector } from 'reselect';
import { dataSelector } from './dataSelector';
import {
  countryCodeSelector,
  languageCountryPathSelector,
  languageMappingsSelector,
  languageRegionHrefSelector,
} from '../state/stateSelector';
import createCachedSelectorOnClient from '../../utils/createCachedSelectorOnClient';
import { translationStringsSelector } from '../state/translations/translationsStringsSelector';
import { ownPropsSelector } from '../../utils/ownPropsSelector';
import { buyingToolsListSelector } from './buyingToolsListSelector';
import { urlsSelector } from './urlSelectors';
import { pageTypeSelector, pageDataPropertiesSelector } from './pageSelectors';
import cardMethods from '../../services/transforms/cardMethods';
import { CARD_TYPES, EDITORIAL_TYPES, URL_TYPE } from '../../constants';
import { getCollectionsFromCard } from './helpers';

export const originalCardDataSelector = createCachedSelectorOnClient(
  dataSelector,
  ownPropsSelector,
  (appData, cardId) => appData?.cards?.[cardId],
)((_, cardId) => cardId);

export const getCardDataSelector = createCachedSelectorOnClient(
  translationStringsSelector,
  originalCardDataSelector,
  ownPropsSelector,
  countryCodeSelector,
  languageRegionHrefSelector,
  languageCountryPathSelector,
  urlsSelector,
  buyingToolsListSelector,
  pageTypeSelector,
  languageMappingsSelector,
  (
    translationStrings,
    cardData,
    cardId,
    countryCode,
    languageRegion,
    languageCountryPath,
    urls,
    buyingToolsList,
    pageType,
    languageMappings,
  ) => {
    if (!cardData) return {};
    const mergeProperties = card => {
      const { containerType } = cardData;
      const translations =
        cardMethods[containerType]?.prepareTranslations(translationStrings) ??
        {};

      return (
        cardMethods[containerType]?.mergeProperties({
          buyingToolsList,
          cardData: card,
          cardId,
          countryCode,
          languageCountryPath,
          languageMappings,
          languageRegion,
          pageType,
          translations,
          urls,
        }) ?? cardData
      );
    };
    return mergeProperties(cardData);
  },
)((_, cardId) => cardId);

export const getCardContainerType = createSelector(
  getCardDataSelector,
  cardData => cardData?.containerType,
);

export const cardDataSelector = createSelector(
  dataSelector,
  ownPropsSelector,
  (appData, ownProps) => {
    const { cardId } = ownProps;
    const card = appData?.cards?.[cardId];
    if (!card) {
      return undefined;
    }
    // PRS fallback solution for analytics
    if (card?.useFallback && card?.fallbacks?.length) {
      const fallbackId =
        find(
          fbId => appData?.cards?.[fbId]?.slides?.length >= 3,
          card?.fallbacks,
        ) || cardId;

      return appData?.cards?.[fallbackId];
    }

    return card;
  },
);

export const cardsSelector = createSelector(
  dataSelector,
  appData => appData?.cards,
);

export const containerTypeCardSelector = createSelector(
  dataSelector,
  ownPropsSelector,
  (appData, props) => {
    if (!appData?.cards || !keys(appData).length || !props?.containerType) {
      return [];
    }

    return filter(card => card.containerType === props.containerType)(
      appData?.cards,
    );
  },
);

export const listicleCardSelector = createSelector(dataSelector, appData =>
  pipe(
    values,
    filter(card => card.containerType === CARD_TYPES.LISTICLE),
  )(appData?.cards),
);

export const coverCardSelector = createSelector(
  pageDataPropertiesSelector,
  pageData => pageData?.coverCard,
);

export const getFirstFAQCardIdSelector = createSelector(
  dataSelector,
  appData =>
    Object.keys(appData?.cards)?.filter(
      cardId => appData.cards[cardId].containerType === CARD_TYPES.FAQ,
    )?.[0],
);

export const snkrsUpcomingDropsComponentsSelector = createSelector(
  dataSelector,
  appData =>
    pipe(
      values,
      filter(card => card.containerType === CARD_TYPES.SNKRS_DROPS),
    )(appData?.cards),
);

export const featureContentFetchParamsSelector = createSelector(
  dataSelector,
  appData =>
    pipe(
      values,
      filter(
        card =>
          values(EDITORIAL_TYPES).includes(card.containerType) &&
          card?.featured,
      ),
      map(card => card?.featured),
    )(appData?.cards),
);

export const dynamicContentFetchParamsSelector = createSelector(
  dataSelector,
  appData =>
    pipe(
      values,
      filter(
        card =>
          values(EDITORIAL_TYPES).includes(card.containerType) &&
          card?.attributeIdsAll,
      ),
      map(card => ({
        attributeIds: card.attributeIds,
        attributeIdsAll: card?.attributeIdsAll,
        count: card.maxResults,
        excludeAttributeIds: card.excludeAttributeIds,
        featured: card?.featured,
        hasOnePage: card.hasOnePage,
      })),
    )(appData?.cards),
);

export const getShopTheLookCollectionIdsSelector = createSelector(
  dataSelector,
  appData =>
    pipe(
      values,
      map(getCollectionsFromCard),
      filter(Boolean),
      flatten,
    )(appData.cards),
);

export const articlesCardLinksSelector = createSelector(dataSelector, appData =>
  pipe(
    values,
    map(card => {
      const { urlIngredients, slides = [] } = card;
      const carlLinkIds = slides
        .filter(
          s =>
            s?.urlIngredients?.type === URL_TYPE.ARTICLE &&
            s?.urlIngredients?.pageId,
        )
        .map(s => s?.urlIngredients?.pageId);

      if (urlIngredients?.type === URL_TYPE.ARTICLE && urlIngredients?.pageId) {
        carlLinkIds.push(urlIngredients?.pageId);
      }
      return carlLinkIds;
    }),
    flatten,
  )(appData.cards),
);

export const cmsCollectionsSelector = createSelector(
  dataSelector,
  pipe(
    propOr({}, 'cards'),
    filter(prop('collectionsId')),
    map(prop('collectionsId')),
  ),
);

export const externalCollectionIdsSelector = createSelector(
  dataSelector,
  appData => {
    if (!appData?.cards) {
      return [];
    }

    return pipe(
      filter(
        c =>
          [CARD_TYPES.EXTERNAL_COLLECTION, CARD_TYPES.PRODUCT_WALL].includes(
            c?.containerType,
          ) && c.collectionId,
      ),
      map(c => c.collectionId),
      values,
      uniq,
    )(appData.cards);
  },
);

export const hasVideoCardSelector = createSelector(
  cardsSelector,
  (cards = {}) => {
    return Object.values(cards).some(
      card =>
        card.containerType === CARD_TYPES.VIDEO ||
        card.slides?.some(slide => slide.containerType === CARD_TYPES.VIDEO),
    );
  },
);

export const variantContentFeaturesSelector = createSelector(
  hasVideoCardSelector,
  hasVideo => ({
    hasVideo,
  }),
);

export const globalContentFeaturesSelector = createSelector(
  dataSelector,
  appData => appData?.contentFeatures,
);

export const firstActionIdWithAutoopenSelector = createSelector(
  cardsSelector,
  ownPropsSelector,
  (storeCards, autoopen) => {
    let autoOpenActionId;
    if (autoopen) {
      const cards = Object.values(storeCards);
      let cardIndex = 0;
      while (!autoOpenActionId && cardIndex < cards.length) {
        const card = cards[cardIndex];
        if (card.cardLinkUrl?.includes(autoopen)) {
          autoOpenActionId = card.cardLinkId;
        } else {
          const actionWithAutoopen = card.actionButtons?.find(actionButton =>
            actionButton.destinationId?.includes(autoopen),
          );
          if (actionWithAutoopen) {
            autoOpenActionId = actionWithAutoopen.id;
          }
        }
        cardIndex++;
      }
    }
    return autoOpenActionId;
  },
);
