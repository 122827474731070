import { createClientStore } from './stores/client';
import { createServerStore } from './stores/server';
import { getInitialState } from './helpers';

export const configureStore = passedState => {
  const initialState = getInitialState(passedState);

  if (__IS_WEB__) {
    return createClientStore(initialState);
  }
  return createServerStore(initialState);
};
