import { CARD_TYPES } from '../../../constants';

export const prepareCardData = data => {
  return {
    cardId: data.id,
    collectionsId: data?.properties?.valueMap?.cms_collection,
    containerType: CARD_TYPES.CMS_COLLECTION,
  };
};

export {
  mergeProperties,
  prepareTranslations,
  getUrlIngredients,
  getBuyingToolsIngredients,
} from './common';
