import { createSelector } from 'reselect';
import { FEATURE_FLAGS } from '@nike/ciclp-config';

export const stateSelector = state => {
  return state.appState;
};

export const requestMetaSelector = createSelector(
  stateSelector,
  state => state?.requestMeta ?? {},
);

export const basenameSelector = createSelector(
  requestMetaSelector,
  meta => meta?.basename,
);

export const basepathSelector = createSelector(
  requestMetaSelector,
  meta => meta?.basepath,
);

export const locationSelector = createSelector(
  stateSelector,
  state => state?.location ?? {},
);

export const traceSelector = createSelector(
  locationSelector,
  state => state?.trace ?? {},
);

export const featureFlagsSelector = createSelector(
  stateSelector,
  state => state?.featureFlags || {},
);

export const disableUiExperimentsSelector = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.[FEATURE_FLAGS.DISABLE_UI_EXPERIMENTS],
);

export const urlSelector = createSelector(
  locationSelector,
  location => location.url,
);

export const urlSelectorWithoutFeatureFlags = createSelector(
  locationSelector,
  location => location.sanitizedUrl,
);

export const urlSelectorWithoutParams = createSelector(
  urlSelector,
  url => url.split('?')[0],
);

export const hostNameSelector = createSelector(
  locationSelector,
  location => location.headers.host,
);

export const languageSelector = createSelector(
  requestMetaSelector,
  meta => meta.language,
);

export const languageCodeSelector = createSelector(
  languageSelector,
  language => language.code,
);

export const languageMappingsSelector = createSelector(
  requestMetaSelector,
  meta => meta.languageMappings,
);

export const languageRegionSelector = createSelector(
  languageMappingsSelector,
  languageMappings => languageMappings?.langRegion,
);

export const languageRegionHrefSelector = createSelector(
  languageRegionSelector,
  languageRegion => languageRegion?.toLowerCase().replace('-', '_'),
);

export const localeHrefLangSelector = createSelector(
  languageMappingsSelector,
  languageMappings => languageMappings?.hreflang,
);

export const localeSelector = createSelector(localeHrefLangSelector, hreflang =>
  hreflang?.replace('-', '_'),
);

export const countrySelector = createSelector(
  requestMetaSelector,
  meta => meta?.country,
);

export const countryCodeSelector = createSelector(
  countrySelector,
  country => country?.code,
);

export const countryNameSelector = createSelector(
  countrySelector,
  country => country?.name,
);

export const marketplaceSelector = createSelector(countrySelector, country =>
  country.code.toUpperCase(),
);

export const canonicalUrlSelector = createSelector(stateSelector, state =>
  state.canonicalUrl.toLowerCase(),
);

/**
 * @returns the portion of the url that uniquely identifies the country/language.
 * it is an empty string for US for example, it is just the country for a mono-language country
 * (or for the default language of a country), or it is `country/language` otherwise.
 */
export const languageCountryPathSelector = createSelector(
  basenameSelector,
  basename => basename?.match(/(v\d)?\/(.*)$/)?.[2],
);

export const analyticsUserIdSelector = createSelector(
  stateSelector,
  state => state.analyticsUserId,
);

export const getAnalyticsPlacements = createSelector(
  stateSelector,
  appState => appState?.analytics?.placements,
);

export const pageIdSelector = createSelector(
  stateSelector,
  state => state?.analysis?.pageId,
);
