export const URL_INGREDIENTS = [
  'type',
  'pdpType',
  'styleColor',
  'prebuildId',
  'conceptIds',
  'pageId',
];

export const BUYING_TOOLS_INGREDIENTS = ['styleColor', 'productId'];

export const MEMBER_EXCLUSIVE_COLLECTION_ID =
  '698d17bc-daf4-42d9-aee7-15841c59798c';

export const NIKE_BY_YOU_STYLE_TYPE = 'NIKEID';

export const PRICE_MINIMUM_FRACTION_DIGITS = 0;
export const PRICE_MAXIMUM_FRACTION_DIGITS = 2;
