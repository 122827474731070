const snkrsTest = 'snkrs.test.commerce.nikecloud.com';
const snkrsProd = 'snkrs.prod.commerce.nikecloud.com';
const contentService = 'content.services.nike.com';
const apiNikeCom = 'api.nike.com';
const getMockServerUrl = () => {
  const mockServiceUrl = `https://${snkrsProd}/browse/clp_mock_service/v1/`;

  return process.env.MOCK_SERVER || mockServiceUrl;
};
const mockServerPath = 'browse/clp_mock_service/v1';

export const mockServerVip = 'browse-clp_mock_service-v1';
const whiteListedURLs = [
  `${snkrsTest}/user_navigation/urlanalysis/v2`,
  `${snkrsProd}/user_navigation/urlanalysis/v2`,
  `${apiNikeCom}/product_feed/threads/v2`,
  `${snkrsProd}/product_feed/threads/v2`,
  `${snkrsTest}/product_feed/threads/v2`,
  `${snkrsProd}/product_feed/threads/v2`,
  `${apiNikeCom}/user_navigation/urlanalysis/v2`,
  `${snkrsProd}/product_feed/rollup_threads/v2`,
  `${apiNikeCom}/product_feed/rollup_threads/v2`,
  `${apiNikeCom}/user_navigation/urlgenesis/v2`,
  `${snkrsTest}/user_navigation/urlgenesis/v2`,
  `${apiNikeCom}/recommend/products/top_trending/web`,
  `${apiNikeCom}/aiml/serving/productrecs/us_landing_pages_v1_01`,
  `${apiNikeCom}/aiml/serving/phoenix/top_trending_v1`,
  `${snkrsTest}/deliver/available_skus/v1`,
  `${apiNikeCom}/deliver/available_skus/v1`,
  `${snkrsTest}/merch/skus/v2`,
  `${apiNikeCom}/merch/skus/v2`,
  `${snkrsTest}/deliver/available_gtins/v3`,
  `${apiNikeCom}/deliver/available_gtins/v3`,
  `${contentService}/content/published/v2/banner`,
  `${snkrsProd}/content/previewproxy/v1/banner`,
];

export const isURLShouldBeRouted = url =>
  whiteListedURLs.some(value => url.includes(value));

export const rewriteUrl = url => url.replace('https://', getMockServerUrl());

export const getRewritedPath = url =>
  `${mockServerPath}/${url.replace(/^https?:\/\//, '')}`;
