import { DetailedError } from '@nike/ciclp-utils/logger';
import { logLevels } from '../enum/errors';

export const getErrorInstance = ({
  message = '',
  name = 'Error',
  stack = '',
  details,
} = {}) => {
  const errorObject = details
    ? new DetailedError(message, details)
    : new Error(message);
  errorObject.name = name;
  errorObject.stack = stack;
  return errorObject;
};

export const reportError = ({ logger, level, errorObj }) => {
  const error = getErrorInstance(errorObj);

  if (level === logLevels.INFO) {
    logger.info(error);
  } else if (level === logLevels.WARN) {
    logger.warn(error);
  } else {
    logger.error(error);
  }
};
