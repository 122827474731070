import { call, select, put, all } from 'redux-saga/effects';
import {
  take as takeFirst,
  pipe,
  map,
  intersection,
  values,
  flatten,
  uniq,
} from 'ramda';
import { API_BASE_URL } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import { productRecommenderApiAction, recommendationActions } from '../actions';
import { fetchProductRecommenderList } from '../services/productRecommender';
import { normaliseRecommendationsList } from '../services/productsApiNormalizer';
import { fetchProductsDetailsSaga } from './productsDetailsSaga';
import {
  marketplaceSelector,
  languageCodeSelector,
} from '../store/state/stateSelector';
import { clientIdSelector } from '../store/config/configSelector';
import { hasUserAllowedPersonalization } from './personalization';

const MIN_REQUIRED_PRODUCTS = 3;
const PRS_ENABLED_COUNTRIES = ['US', 'JP'];

const getCardWithTaxonomiesProducts = (card, productsList) => {
  const taxonomyProducts = productsList
    .filter(({ taxonomyId }) => card.taxonomies.includes(taxonomyId))
    .map(t => t.recommendations.map(tr => tr.styleColor));
  const commonProducts =
    taxonomyProducts.reduce(intersection, taxonomyProducts?.[0]) ?? [];

  return card.clearance
    ? commonProducts
    : takeFirst(card.maxResults, commonProducts);
};

const getCardProducts = (card, productsList) => {
  // taking `amountOfItemsFromTaxonomy` items from each products list
  // if there are not enough lists, start from the first again
  const products = [];
  const amountOfItemsFromTaxonomy = 2;
  const maxProductsLength = Math.max(
    ...productsList.map(p => p.recommendations.length),
  );
  let productListIndex = 0;
  let productIndex = 0;
  while (
    products.length < card.maxResults &&
    productIndex < maxProductsLength
  ) {
    productsList[productListIndex].recommendations
      .slice(productIndex, productIndex + amountOfItemsFromTaxonomy)
      .filter(item => !products.includes(item.styleColor))
      .forEach(item => products.push(item.styleColor));

    productListIndex++;
    if (productListIndex === productsList.length) {
      productListIndex = 0;
      productIndex += amountOfItemsFromTaxonomy;
    }
  }

  return takeFirst(card.maxResults, products);
};

const getUsedProducts = (recommendedCards, productsList = []) => {
  const recommenderWithProducts = [];
  const recommendedProducts = pipe(
    map(card => {
      const products = card.taxonomies
        ? getCardWithTaxonomiesProducts(card, productsList)
        : getCardProducts(card, productsList);

      const useFallback = products.length < MIN_REQUIRED_PRODUCTS;
      recommenderWithProducts.push({
        cardId: card.id,
        ...(!useFallback && { products }),
        clearance: card.clearance,
        maxResults: card.maxResults,
        useFallback,
      });
      return !useFallback ? products : [];
    }),
    flatten,
    uniq,
  )(recommendedCards);

  return {
    recommendedProducts,
    recommenderWithProducts,
  };
};

const remapIntoCardsWithSlides = cards => slides =>
  cards.reduce((cardsWithSlides, c) => {
    const filteredProductSlides = c.useFallback
      ? []
      : slides.filter(s => {
          if (c.clearance) {
            return c.products?.includes(s.styleColor);
          }
          return c.products?.includes(s.styleColor) && !s.isOnSale;
        });
    cardsWithSlides[c.cardId] =
      filteredProductSlides.length >= MIN_REQUIRED_PRODUCTS
        ? {
            id: c.cardId,
            slides: filteredProductSlides,
          }
        : { id: c.cardId, useFallback: true };

    return cardsWithSlides;
  }, {});

/**
 * It tries to fetch the recommended products if at least 3 products are available
 */
export const fetchProductRecommender = function* (recommenderCardsData) {
  const isMember = yield call(window.webShellClient.identity.getIsLoggedIn);
  const marketplace = yield select(marketplaceSelector);

  if (!isMember || !PRS_ENABLED_COUNTRIES.includes(marketplace)) {
    yield put(recommendationActions.usePRSFallback());
    return;
  }

  const canRunPersonalization = yield select(hasUserAllowedPersonalization);
  if (!canRunPersonalization) {
    yield put(recommendationActions.usePRSFallback());
    return;
  }

  const language = yield select(languageCodeSelector);
  const uniteJWT = yield call(window.webShellClient.identity.getAccessToken);
  const callerId = yield select(clientIdSelector, 'product_recommender');
  const { upmId, firstName } = yield call(
    window.webShellClient.identity.getUserProfile,
  );

  try {
    const prsResponse = yield call(fetchProductRecommenderList, {
      callerId,
      fetchParams: {
        baseUrl: API_BASE_URL,
        // adjusting timeout because it is made from client
        timeout: 5000,
      },
      marketplace,
      uniteJWT,
      upmId,
    });

    const { recommendedProducts, recommenderWithProducts } = getUsedProducts(
      recommenderCardsData,
      prsResponse?.insights,
    );

    const recommendedItemsLength = recommendedProducts?.length ?? 0;

    if (recommendedItemsLength > MIN_REQUIRED_PRODUCTS) {
      const recommendedProductsList = yield call(
        fetchProductsDetailsSaga,
        recommendedProducts,
        {
          fetchParams: {
            baseUrl: API_BASE_URL,
            // adjusting timeout because it is made from client
            timeout: 5000,
          },
        },
      );

      const normalizedProducts = yield call(
        normaliseRecommendationsList,
        recommendedProductsList,
      );
      const cardsWithSlides = remapIntoCardsWithSlides(recommenderWithProducts)(
        normalizedProducts,
      );
      if (values(cardsWithSlides).every(c => !c.useFallback)) {
        yield put(recommendationActions.withoutFallback());
      } else {
        yield put(recommendationActions.usePRSFallbackPartly());
      }
      yield put(
        productRecommenderApiAction.success({
          cards: cardsWithSlides,
          firstName,
        }),
      );
    } else {
      logger.warn({
        details: {
          itemsCount: recommendedItemsLength,
          language,
          marketplace,
        },
        message: `Product Recommender Service has returned less items than required`,
      });
      yield put(recommendationActions.usePRSFallback());
    }
  } catch (ex) {
    const {
      name = 500,
      message = 'Error fetching recommender products',
      stack = {},
      details,
    } = ex;

    logger.warn({
      details,
      message,
      name,
      stack,
    });

    yield all([
      put(recommendationActions.usePRSFallback()),
      put(
        productRecommenderApiAction.error({
          details,
          message,
          name,
          stack,
        }),
      ),
    ]);
  }
};
