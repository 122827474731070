export const errors = {
  ECONNREFUSED: 'ECONNREFUSED',
  ENOTFOUND: 'ENOTFOUND',
  ETIMEDOUT: 'ETIMEDOUT',
};

export const logLevels = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
};
