import {
  map,
  values,
  pipe,
  filter,
  propEq,
  chain,
  is,
  forEach,
  fromPairs,
  reduce,
} from 'ramda';
import cardMethods from '../transforms/cardMethods';
import { analyticsProperties, eventNames } from '../../analytics/constants';
import { cardDataSelector } from '../../store/data/getCardDataSelector';
import { CARD_TYPES } from '../../constants';
import {
  createPlacements,
  createSnkrsPlacements,
  createStlPlacements,
  findBlocks,
  getCalculatedPlacements,
  getShopTheLookCardsIds,
} from './helpers';

export const computeAnalyticsData = state => {
  const { appData } = state;
  const bannerNodes = appData?.banner?.nodes ?? [];

  const placements = {};
  const grids = pipe(
    values,
    filter(propEq('mode', 'grid')),
  )(appData.layoutItems);

  const totalPositions = reduce(
    (acc, grid) => ({
      desktop: Math.max(acc.desktop, grid.position?.large ?? 0),
      mobile: Math.max(acc.mobile, grid.position?.small ?? 0),
    }),
    { desktop: 0, mobile: 0 },
    grids,
  );

  pipe(
    map(grid => {
      const blocks = findBlocks(appData.layoutItems, grid.id);
      let placementIndex = 1;
      forEach(block => {
        const cardId = block.data;
        const card = cardDataSelector(state, { cardId });
        const cardPlacements = getCalculatedPlacements(card);

        const hasSeveralPlacements = is(Array, cardPlacements);
        const hasSinglePlacement = is(Object, cardPlacements);

        const isSnkrsDropsCard = card?.containerType === CARD_TYPES.SNKRS_DROPS;
        const hasSectionHeadline = !!cardPlacements?.[0]?.isSectionHeadline;
        const increasePlacementIndex = () => placementIndex++;

        // TODO: refactor: https://jira.nike.com/browse/CICLP-2999
        if (isSnkrsDropsCard) {
          const snkrsPlacements = createSnkrsPlacements({
            block,
            card,
            cardPlacements,
            grid,
            hasSectionHeadline,
            hasSeveralPlacements,
            totalPositions,
          });

          Object.assign(placements, snkrsPlacements);
        } else {
          const newPlacements = createPlacements({
            block,
            cardPlacements,
            grid,
            hasSectionHeadline,
            hasSeveralPlacements,
            hasSinglePlacement,
            increasePlacementIndex,
            totalPositions,
          });

          Object.assign(placements, newPlacements);
        }

        const shopTheLookCardIds = getShopTheLookCardsIds(card);

        if (shopTheLookCardIds.length) {
          shopTheLookCardIds.forEach(stlCardId => {
            const stlCard = cardDataSelector(state, { cardId: stlCardId });
            const stlCardPlacements = getCalculatedPlacements(stlCard);

            const stlPlacements = createStlPlacements({
              block,
              cardPlacements: stlCardPlacements,
              grid,
              increasePlacementIndex,
              totalPositions,
            });

            Object.assign(placements, stlPlacements);
          });
        }
      }, blocks);
    }),
  )(grids);

  const actions = pipe(
    values,
    chain(
      card =>
        cardMethods[card.containerType]?.getAnalyticsActions?.(card) ?? [],
    ),
    map(({ id, ...rest }) => [id, rest]),
    fromPairs,
  )(appData.cards);
  bannerNodes.forEach((node, index) => {
    forEach(action => {
      const actionId = action.replace('data-analytics-action-id="', '');
      const clickActivity = `promo:${node.title
        ?.toLowerCase()
        .replace(/\W/g, '-')}`;
      actions[actionId] = {
        eventName: eventNames.BANNER_CLICK,
        [analyticsProperties.LOCAL_PLACEMENT_ID]: index + 1,
        [analyticsProperties.BANNER_CARD_ID]: node.id,
        [analyticsProperties.CLICK_ACTIVITY]: clickActivity,
        [analyticsProperties.POSITION_ID]: 0,
        [analyticsProperties.PLACEMENT_ID]: 1,
      };
    }, node.msg?.match(/data-analytics-action-id="[^"]+/g) ?? []);
  });

  return {
    actions,
    placements,
  };
};
