import { select, call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { equals } from 'ramda';
import { getPreferences } from '@nike/ux-tread-privacy';
import { privacyActions } from '../actions';
import { countryCodeSelector } from '../store/state/stateSelector';
import { updateScripts, documentComplete } from '../utils/inlineScriptUtils';

export const PRIVACY_SETTINGS = {
  0: { allowMarketing: false, allowPerformance: false },
  1: { allowMarketing: false, allowPerformance: true },
  2: { allowMarketing: true, allowPerformance: false },
  3: { allowMarketing: true, allowPerformance: true },
};

const PURPOSE_LIMITATION_NAME = 'performance';

const updateRequiredScripts = async ({ level }) => {
  if (!__IS_WEB__ || level !== 3) {
    return;
  }

  await documentComplete();
  updateScripts('data-privacycheck');

  Array.prototype.slice
    .call(document.querySelectorAll('script[type="text/plain"]'))
    .forEach(script => {
      const clonedScript = document.createElement('script');
      clonedScript.textContent = script.textContent;
      script.parentNode.removeChild(script);
      document.body.appendChild(clonedScript);
    });
};

export const getPrivacyLevel = ({ allowPerformance, allowMarketing }) =>
  +Object.keys(PRIVACY_SETTINGS).find(
    level =>
      PRIVACY_SETTINGS[level].allowPerformance === allowPerformance &&
      PRIVACY_SETTINGS[level].allowMarketing === allowMarketing,
  );

export const formatPrivacyPayload = country => {
  const preferences = getPreferences(country);
  return {
    isSet:
      preferences.allowPerformance !== null &&
      preferences.allowMarketing !== null,
    ...preferences,
    level: getPrivacyLevel(preferences),
  };
};

export const handleSqPrivacy = function* ({ country }) {
  let _settings = {};

  while (true) {
    const payload = formatPrivacyPayload(country);
    if (payload.isSet && !equals(_settings, payload)) {
      _settings = payload;

      updateRequiredScripts(payload);

      yield put(privacyActions.set(payload));
    }
    yield call(delay, 500);
  }
};

const handleNikePrivacy = function* ({ dispatch }) {
  const permission = window.NikePrivacy?.permissions?.get(
    PURPOSE_LIMITATION_NAME,
  );
  // nikePrivacy is already initialized and we're ready to store the permission settings
  if (permission?.isEnabled !== undefined) {
    yield put(
      privacyActions.setNikePrivacy({
        isNikePrivacyAllowed: permission?.isEnabled,
      }),
    );
  } else {
    // add event listener for when nikePrivacy is initialized
    window.addEventListener('@nikePrivacyCoreCustomEvent/initialized', () => {
      const resolvedPermission = window.NikePrivacy?.permissions?.get(
        PURPOSE_LIMITATION_NAME,
      );
      dispatch(
        privacyActions.setNikePrivacy({
          isNikePrivacyAllowed: resolvedPermission?.isEnabled,
        }),
      );
    });
  }
};

export const privacySaga = function* (dispatch) {
  const country = yield select(countryCodeSelector);
  const isNikePrivacyEnabled = window.NikePrivacy?.isEnabled;

  isNikePrivacyEnabled
    ? yield call(handleNikePrivacy, { dispatch })
    : yield call(handleSqPrivacy, { country });
};
