export const getStyleColorsAndMerchGroups = payload => {
  if (!payload?.productStyleColors) {
    throw new Error('No product and availability ids provided.');
  }

  return payload.productStyleColors.map(({ styleColor, merchGroup }) => ({
    merchGroup,
    styleColor,
  }));
};

export const composeSizesByProductIds = (sizes, productStyleColors) => {
  return sizes
    ? sizes.reduce((acc, size) => {
        const productId = productStyleColors?.find(
          ({ styleColor }) => styleColor === size.styleColor,
        )?.productId;

        if (!productId) {
          return acc;
        }

        if (acc[productId]) {
          return {
            ...acc,
            [productId]: [...acc[productId], size],
          };
        }

        return {
          ...acc,
          [productId]: [size],
        };
      }, {})
    : {};
};

export const mergeSizes = sizesData => {
  const allSizes = [];
  sizesData.forEach(({ sizes }) => {
    allSizes.push(...sizes);
  });
  return allSizes;
};
