import { persistReducer } from 'redux-persist';
import { assoc } from 'ramda';
import storage from 'redux-persist/lib/storage';
import { notifiMeApiActions } from '../../actions';
import createReducer from '../../utils/createReducer';
import { SUBSCRIPTION_STATUSES } from '../../constants';

export const storageReducer = createReducer(
  {},
  {
    [notifiMeApiActions.success.type]: (state, { payload }) => ({
      ...state,
      [payload.productId]: SUBSCRIPTION_STATUSES.SUCCESS,
    }),
    [notifiMeApiActions.request.type]: (state, { payload }) =>
      assoc(payload.productId, undefined, state),
    [notifiMeApiActions.error.type]: (state, { payload }) => ({
      ...state,
      [payload.productId]: SUBSCRIPTION_STATUSES.FAILURE,
    }),
  },
);

const persistStore = () => ({
  storage: persistReducer(
    {
      key: 'storage',
      storage,
    },
    storageReducer,
  ),
});

export default persistStore;
