import { call, take, put, select, race } from 'redux-saga/effects';
import { values } from 'ramda';
import {
  bootstrapActions,
  privacyActions,
  recommendationActions,
} from '../../actions';
import { getProductCards, getRecommendationsSaga } from './sagas';
import { waitForIdentityInitialization } from '../identity/sagas';
import {
  canPersonalizeSelector,
  isPrivacySet as isPrivacySetSelector,
} from '../../store/state/privacy/privacySelector';

/**
 * This saga trigger productRecommenderApiAction when the app data is fully loaded
 * productRecommenderApiAction will start userRecommenderSaga which is expecting Identity to be loaded
 */

const hasCards = cards => values(cards).length > 0;

export const personalizationSaga = function* () {
  const [recommenderCards = {}] = yield call(getProductCards);

  const hasProductRecommender = hasCards(recommenderCards);
  const bootstrap = yield take(bootstrapActions.success.type);
  yield call(waitForIdentityInitialization);

  if (bootstrap?.payload) {
    if (hasProductRecommender) {
      yield call(getRecommendationsSaga, recommenderCards);
    }
  } else {
    yield take(bootstrapActions.success.type);
  }
  yield put(recommendationActions.ready());
};

/**
 * The code above should be not the "personalization" saga, but "product recommender saga".
 * The following code is thought related to personalization capabilities.
 */
export const hasUserAllowedPersonalization = function* () {
  const isPrivacySet = yield select(isPrivacySetSelector);
  if (!isPrivacySet) {
    yield race({
      nikePrivacy: take(privacyActions.setNikePrivacy.type),
      sqPrivacy: take(privacyActions.set.type),
    });
  }
  return yield select(canPersonalizeSelector);
};
