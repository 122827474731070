import { NIKE_BASE_URL } from '@nike/ciclp-config';
// webpack doesn't tree shake named JSON exports well, so to mitigate it we import json directly
// this saves some kbytes in app bundle
import locales from '@nike/web-shell-locale-data/data/locales.json';
import { dateTimeFormat } from '@nike/i18n-core';
import { getPDPUrl } from './url';
import { BUYING_TOOLS_STATUS } from '../../../constants';
import { mergeSectionHeadlineTitle } from './index';

const ONE_DAY_MS = 86400000;
const TODAY = 'TODAY';
const TOMORROW = 'TOMORROW';

const buildUrl = chunks => chunks.filter(Boolean).join('/');

const isDatesEqual = (currentDate, targetDate) =>
  targetDate.getDate() === currentDate.getDate() &&
  targetDate.getMonth() === currentDate.getMonth() &&
  targetDate.getFullYear() === currentDate.getFullYear();

const getWeekNumber = targetDate => {
  const firstDayOfYear = new Date(targetDate.getFullYear(), 0, 1);
  const pastDaysOfYear = (targetDate - firstDayOfYear) / ONE_DAY_MS;

  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const isDateTomorrow = targetDate => {
  const today = new Date();
  today.setDate(today.getDate() + 1);

  return isDatesEqual(today, targetDate);
};

const isDateThisWeek = (currentDate, targetDate) =>
  getWeekNumber(currentDate) === getWeekNumber(targetDate);

const getTime = (targetDate, langCountry) =>
  dateTimeFormat(langCountry, targetDate, {
    hour: 'numeric',
    minute: 'numeric',
  });

export const dateTimeStringResolver = (date, langCountry) => {
  const targetDate = new Date(date);
  const currentDate = new Date();

  const dateFormatOptions = {
    day: 'numeric',
    month: 'numeric',
  };

  const weekDayFormatOption = {
    weekday: 'long',
  };

  const time = getTime(targetDate, langCountry);

  switch (true) {
    case isDatesEqual(currentDate, targetDate):
      return [TODAY, time];
    case isDateTomorrow(targetDate):
      return [TOMORROW, time];
    case isDateThisWeek(currentDate, targetDate):
      return [
        dateTimeFormat(langCountry, targetDate, weekDayFormatOption),
        time,
      ];
    default:
      return [dateTimeFormat(langCountry, targetDate, dateFormatOptions), time];
  }
};

export const getProductStatusText = ({
  launchDate,
  productStatus,
  labels = {},
  language,
}) => {
  switch (productStatus) {
    case BUYING_TOOLS_STATUS.SOLD_OUT:
      return labels.sold_out;
    case BUYING_TOOLS_STATUS.COMING_SOON: {
      const [date, time] = dateTimeStringResolver(launchDate, language);
      if (date === TODAY) {
        return (labels.available_today || '').replace('{time}', time);
      }
      if (date === TOMORROW) {
        return (labels.available_tomorrow || '').replace('{time}', time);
      }
      return labels.available_date
        .replace('{date}', date)
        .replace('{time}', time);
    }
    default:
      return labels.exclusive_text;
  }
};

const getSlides = (
  slides,
  countryCode,
  translations,
  languageMappings,
  countryLangParams,
) =>
  slides.map(slide => ({
    ...slide,
    country: countryCode,
    isSoldOut: slide.productStatus === BUYING_TOOLS_STATUS.SOLD_OUT,
    statusText: getProductStatusText({
      country: countryCode,
      labels: translations.labels,
      language: languageMappings.langRegion,
      launchDate: slide.launchDate,
      productStatus: slide.productStatus,
    }),
    url: getPDPUrl({
      ...slide,
      languageCountryPath: countryLangParams,
    }),
  }));

export const mergeSnkrsCarouselProperties = ({
  cardData,
  translations = {},
  urls,
  countryCode,
  countryLangParams,
  languageMappings,
}) => {
  const {
    slides = [],
    slidesUpcoming = [],
    sectionHeadline,
    ...restCardData
  } = cardData;

  const sectionHeadlineProps = mergeSectionHeadlineTitle({
    cardData: sectionHeadline,
    translations,
    urls,
  });

  sectionHeadlineProps.actions[0] = {
    ...sectionHeadlineProps.actions[0],
    actionText: translations.viewAllLabel,
    destinationId: `${NIKE_BASE_URL}/${countryLangParams}`,
  };

  sectionHeadlineProps.title =
    translations.labels.heading || sectionHeadlineProps.title;

  return {
    ...restCardData,
    ...translations,
    localeForCurrency: languageMappings.hreflang,
    sectionHeadline: sectionHeadlineProps,
    slides: getSlides(
      slides,
      countryCode,
      translations,
      languageMappings,
      countryLangParams,
    ),
    slidesUpcoming: getSlides(
      slidesUpcoming,
      countryCode,
      translations,
      languageMappings,
      countryLangParams,
    ),
  };
};

export const getCountryLangParams = (countryCode = 'us', language = 'en') => {
  const isCurrentLangDefault = locales.find(
    mapping => mapping.urlParam === language && mapping.country === countryCode,
  )?.default;
  const isCurrentCountryDefault = countryCode === 'us';

  return buildUrl([
    !isCurrentCountryDefault && countryCode,
    !isCurrentLangDefault && language,
  ]);
};

export const getSnkrsProductStatus = ({ available, start }) => {
  const now = new Date().getTime();
  const startTime = new Date(start).getTime();
  if (now < startTime) return BUYING_TOOLS_STATUS.COMING_SOON;

  if (available && now > startTime) return BUYING_TOOLS_STATUS.AVAILABLE;

  return BUYING_TOOLS_STATUS.SOLD_OUT;
};
