import { CARD_TYPES } from '../../../constants';
import * as externalCollectionMethods from './externalCollection';
import * as filmstripMethods from './filmstrip';
import * as relatedFilmstripMethods from './relatedFilmstrip';
import * as imageMethods from './image';
import * as localNavMethods from './localNav';
import * as merchMenuMethods from './merchMenu';
import * as productRecommenderMethods from './productRecommender';
import * as sectionHeadlineMethods from './sectionHeadline';
import * as slideshowMethods from './slideshow';
import * as teamSelectorMethods from './teamSelector';
import * as textMethods from './text';
import * as topTrendingMethods from './topTrending';
import * as snkrsUpcomingDropsMethods from './snkrsUpcomingDrops';
import * as videoMethods from './video';
import * as productWallMethods from './productWall';
import * as titleMethods from './title';
import * as sizeChartsMethods from './sizeCharts';
import * as dynamicContentCarouselMethods from './dynamicContentCarousel';
import * as dynamicGridMethods from './dynamicGridMethods';
import * as articleFooterMethods from './articleFooter';
import * as cmsCollection from './cmsCollection';
import * as metaBoxMethods from './metaBox';
import * as toolsAndSupplies from './toolsAndSupplies';
import * as faqContainer from './faqContainer';
import * as listicleMethods from './listicle';
import * as shopTheLook from './shopTheLook';
import * as braSizeFinder from './braSizeFinder';
import * as interestsContainer from './interestsContainer';

/**
 * @typedef DataPreparationConfig
 * @type {object}
 * @property {boolean} withDefaultImageHeight - set default height if there is no value from CMS
 *
 * Interface for card methods that should be implemented for all CARD_TYPES:
 *  {
 *    @description re-maps normalized API data into data structure needed for rendering corresponding component
 *    @param {Object} cardData data structure received from API for current component, required
 *    @param {Object} normalizedData normalized API response to add nested nodes, like slides or items for local nav (required not for all cards)
 *    @param {DataPreparationConfig} config with options to change data preparation depending on context
 *    @returns preparedCardData in the format required for rendering in component and combined with nested properties
 *    prepareCardData: (cardData, normalizedData, config) => preparedCardData,
 *
 *    @description returns translated string constants from Bodega in the structure required for card render
 *    @param {Object} translationsStrings object with all translations (required)
 *    @returns {Object} translatedLabels object with filled in labels for specific component
 *    prepareTranslations: translationsStrings => translatedLabels,
 *
 *    @description extract all URL ingredients from card and returns them as array of urlIngredients
 *    @param {Object} card prepared component data (required)
 *    @returns {Object[]} urls array with all url ingredients object
 *    getUrlIngredients: card => urls,
 *
 *    @description extract all Buying Tools ingredients from card and returns them as array of buyingToolsIngredients
 *    @param {Object} card prepared component data (required)
 *    @returns {Object[]} v array with all buying tools ingredients object
 *    getBuyingToolsIngredients: card => buyingToolsIngredients,
 *
 *    @description merges together Urls, translations and card data into final props for the component
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} translations object with filled in labels for specific component
 *    @param {[Object, string][]} urls array with URL ingredients and corresponding URL
 *    @returns {Object} mergedData data merged with translated labels and URLs instead of ingredients, finally ready props for component
 *    mergeProperties: ({ cardData, translations, urls }) => mergedData,
 *
 *    @description prepares set of analytics data specific for all placements inside of card
 *    @param {Object} cardData prepared component data (required)
 *    @returns {(Object[]|Object)} array of analytics data for placements
 *    getAnalyticsPlacements: cardData => placements,
 *
 *    @description prepares set of analytics data specific for all actions inside of card
 *    @param {Object} cardData prepared component data (required)
 *    @returns {Object[]} array of analytics data for actions
 *    getAnalyticsActions: cardData => actions,
 *  }
 * */

const cardMethods = {
  [CARD_TYPES.IMAGE]: imageMethods,
  [CARD_TYPES.VIDEO]: videoMethods,
  [CARD_TYPES.TEXT]: textMethods,
  [CARD_TYPES.SECTION_HEADLINE]: sectionHeadlineMethods,
  [CARD_TYPES.EXTERNAL_COLLECTION]: externalCollectionMethods,
  [CARD_TYPES.PRODUCT_RECOMMENDER]: productRecommenderMethods,
  [CARD_TYPES.PRODUCT_RECOMMENDER_TAXONOMY]: productRecommenderMethods,
  [CARD_TYPES.TOP_TRENDING]: topTrendingMethods,
  [CARD_TYPES.SNKRS_DROPS]: snkrsUpcomingDropsMethods,
  [CARD_TYPES.MERCH_MENU]: merchMenuMethods,
  [CARD_TYPES.LOCAL_NAV]: localNavMethods,
  [CARD_TYPES.FILMSTRIP]: filmstripMethods,
  [CARD_TYPES.RELATED_FILMSTRIP]: relatedFilmstripMethods,
  [CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP]: relatedFilmstripMethods,
  [CARD_TYPES.SLIDESHOW]: slideshowMethods,
  [CARD_TYPES.TEAM_SELECTOR]: teamSelectorMethods,
  [CARD_TYPES.PRODUCT_WALL]: productWallMethods,
  [CARD_TYPES.TITLE]: titleMethods,
  [CARD_TYPES.SIZE_CHARTS]: sizeChartsMethods,
  [CARD_TYPES.DYNAMIC_CAROUSEL]: dynamicContentCarouselMethods,
  [CARD_TYPES.DYNAMIC_GRID]: dynamicGridMethods,
  [CARD_TYPES.ARTICLE_FOOTER]: articleFooterMethods,
  [CARD_TYPES.CMS_COLLECTION]: cmsCollection,
  [CARD_TYPES.FAQ]: faqContainer,
  [CARD_TYPES.LISTICLE]: listicleMethods,
  [CARD_TYPES.META_BOX]: metaBoxMethods,
  [CARD_TYPES.TOOLS_AND_SUPPLIES]: toolsAndSupplies,
  [CARD_TYPES.SHOP_THE_LOOK]: shopTheLook,
  [CARD_TYPES.BRA_SIZE_FINDER]: braSizeFinder,
  [CARD_TYPES.INTERESTS]: interestsContainer,
};

export default cardMethods;
