import {
  APIError,
  customFetch,
  transformParamsToString,
  ErrorMessage,
  FetchParams,
  DEFAULT_API_URL,
} from '../../common';

export const getThreadPreviewById = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: Thread.RequestParamsFilters & {
    threadId: string;
    token: string;
    layout: boolean;
  } & FetchParams;
}): Promise<Thread.ThreadObject> => {
  const {
    callerId,
    threadId,
    token,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
    ...restParams
  } = params;
  const paramsString = transformParamsToString({
    preview: true,
    ...restParams,
  });
  const path = 'product_feed/threads/v2';
  const url = `${baseUrl}/${[path]}/${threadId}/${paramsString}`;
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      'nike-api-caller-id': callerId,
    },
    method: 'GET',
    path,
    timeout,
    vipAddress: 'product_feed-threads-v2',
  };

  const result = await customFetch({
    fetchFn,
    options: requestOptions,
    url,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      originalMessage: e.message,
      requestOptions,
      url,
    });
  }
};
