import { call, put, select } from 'redux-saga/effects';
import { CHANNEL_IDS } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import { getBannerNodes, getBannerPreview } from '@nike/ciclp-data-fetch-layer';
import { normalizeBanner } from './helpers';
import { bannerFeedApiActions } from '../../actions';
import { showPromoBannerSelector } from '../../store/data/bannerSelectors';
import {
  channelIdSelector,
  threadIdSelector,
  isPreviewSelector,
  isBannerChannelIdSelector,
  previewTokenSelector,
} from '../../store/router/routerSelector';
import {
  marketplaceSelector,
  languageCodeSelector,
} from '../../store/state/stateSelector';

export const fetchBannerPreview = function* () {
  const marketplace = yield select(marketplaceSelector);
  const language = yield select(languageCodeSelector);
  const threadId = yield select(threadIdSelector);
  const token = yield select(previewTokenSelector);
  const channelId = yield select(channelIdSelector);

  return yield call(getBannerPreview, {
    fetchFn: globalThis.extendedFetch,
    params: {
      bannerId: threadId,
      channelId,
      fetchParams: {
        baseUrl: process.env.NEXT_PUBLIC_PREVIEW_API_BASE_URL,
        timeout: 5000,
      },
      language,
      marketplace,
      preview: true,
      token,
    },
  });
};

export const fetchBannerPublished = function* () {
  const marketplace = yield select(marketplaceSelector);
  const language = yield select(languageCodeSelector);

  return yield call(getBannerNodes, {
    fetchFn: globalThis.extendedFetch,
    params: {
      channelId: CHANNEL_IDS.DEFAULT,
      fetchParams: {
        baseUrl: process.env.NEXT_PUBLIC_CONTENT_SERVICE_API_BASE_URL,
        timeout: 5000,
      },
      language,
      location: 'MARKETING_PAGE',
      marketplace,
    },
  });
};

export const bannerThreadSaga = function* () {
  const isBannerChannelId = yield select(isBannerChannelIdSelector);
  const isPreviewRoute = yield select(isPreviewSelector);
  const showBanner = yield select(showPromoBannerSelector);

  const isBannerPreviewRoute = isBannerChannelId && isPreviewRoute;

  if (!isBannerPreviewRoute && !showBanner) {
    return;
  }

  try {
    const fetchFunction = isBannerPreviewRoute
      ? fetchBannerPreview
      : fetchBannerPublished;
    const bannerResult = yield call(fetchFunction);

    const norm = normalizeBanner(bannerResult.nodes);

    yield put(bannerFeedApiActions.success(norm));
  } catch (ex) {
    const { name, message, stack, details } = ex;

    logger.warn({
      details,
      message,
      name,
      stack,
    });
  }
};
