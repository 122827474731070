import { createSelector } from 'reselect';
import { OPTIMIZATION_NAMESPACE } from './constants';
import { stateSelector } from '../stateSelector';

export const optimizationStateSelector = createSelector(
  stateSelector,
  state => state[OPTIMIZATION_NAMESPACE],
);

export const optimizationAnalyticsSelector = createSelector(
  optimizationStateSelector,
  state => state.analytics,
);
