import striptags from 'striptags';
import { extractIngredientsFromList, getTitle, sanitizeText } from '../helpers';
import { analyticsProperties } from '../../../analytics/constants';
import { getActions, getCommonAnalyticsProps } from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = cardData => ({
  ...getCommonProps(cardData),
  ...getTitle(cardData),
  subtitle: sanitizeText(cardData?.properties?.subtitle ?? ''),
});

export { mergeSectionHeadlineTitle as mergeProperties } from '../helpers/carouselHelper/carouselHelper';

export const prepareTranslations = () => ({});
export const getUrlIngredients = card =>
  extractIngredientsFromList(card?.actions);

export const getAnalyticsPlacements = card => ({
  isSectionHeadline: true,
  ...getCommonAnalyticsProps(card),
  [analyticsProperties.CTA_TITLE]: striptags(card.title),
});

export const getAnalyticsActions = card => getActions(card.actions);

export { getBuyingToolsIngredients } from './common';
