////////////////////
////////
/////////////////////////////
//////////////////////////////////
////////////////////////
///////////////////////
//////////////////////////////////////////
/////////
import { CARD_TYPES } from '../../../constants';
import { prepareGalleryCardData } from '../helpers/carouselHelper';
import {
  getCommonAnalyticsProps,
  getGalleryActions,
} from '../helpers/analytics';
import {
  analyticsCardTypes,
  analyticsProperties,
  componentTypes,
} from '../../../analytics/constants';

export const prepareCardData = (cardData, data) => {
  const galleryCardData = prepareGalleryCardData(
    cardData,
    data,
    CARD_TYPES.BRA_SIZE_FINDER,
  );
  const cardSubtitle = cardData?.properties?.subtitle;
  if (galleryCardData?.sectionHeadline) {
    galleryCardData.sectionHeadline.subtitle = cardSubtitle;
  }
  return galleryCardData;
};

export const prepareTranslations = translationsStrings => ({
  bra_size_finder_alpha_caption:
    translationsStrings.bra_size_finder_alpha_caption,
  bra_size_finder_hybrid_caption:
    translationsStrings.bra_size_finder_hybrid_caption,
  bra_size_finder_restart: translationsStrings.bra_size_finder_restart,
  bra_size_finder_select_placeholder:
    translationsStrings.bra_size_finder_select_placeholder,
  bra_size_finder_shop_link: translationsStrings.bra_size_finder_shop_link,
  bra_size_finder_underbust_input_caption:
    translationsStrings.bra_size_finder_underbust_input_caption,
  next: translationsStrings.next,
  units_cm: translationsStrings.units_cm,
  units_inch: translationsStrings.units_inch,
});

export const mergeProperties = ({
  cardData,
  urls,
  translations,
  countryCode,
}) => {
  const urlsMap = urls.reduce((acc, [{ sizesKey }, url]) => {
    return {
      ...acc,
      [sizesKey]: url,
    };
  }, {});

  return {
    ...cardData,
    countryCode,
    translations,
    urls: urlsMap,
  };
};

export const getUrlIngredients = (_, countryCode) => {
  const uniqueResults = {};
//////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////////////
///
//////////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////////////////////////
///////
///////
/////

////////////////////////////////////////////////////////////////////
/////////////////
///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////////////////////////////////////////////////
//////
/////////////
/////////////////////
//////
///////////
};

export const getAnalyticsPlacements = card => {
  const { slides = [] } = card;
  return slides.map(slide => {
    return {
      ...getCommonAnalyticsProps(slide),
      [analyticsProperties.LEGACY_COMPONENT_TYPE]:
        componentTypes[CARD_TYPES.BRA_SIZE_FINDER],
      [analyticsProperties.COMPONENT_TYPE]:
        analyticsCardTypes[CARD_TYPES.BRA_SIZE_FINDER],
    };
  });
};

export { getGalleryActions as getAnalyticsActions };

export { getBuyingToolsIngredients } from './common';
