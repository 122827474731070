import {
  assoc,
  chain,
  lensProp,
  map,
  over,
  pipe,
  replace,
  range,
  addIndex,
} from 'ramda';
import striptags from 'striptags';
import {
  analyticsCardTypes,
  analyticsProperties,
  componentTypes,
  eventNames,
} from '../../../analytics/constants';
import cardMethods from '../cardMethods';
import { CARD_TYPES, PRODUCT_AVAILABILITY } from '../../../constants';

const mapIndexed = addIndex(map);
const chainIndexed = addIndex(chain);

/**
 * Returns clickActivity property for analytics, all arguments are optional
 * @param {string} [destinationType] type of link, could be 'url', 'pdp', 'gridwall', 'page' or 'legacy_gridwall'
 * @param {number} [index] inner placement of link
 * @param {string} [label] link text
 * @param {string} [componentType] in strange format, currently it's 'con', 'merch menu' or 'local nav'
 * @returns {string}
 */
export const getClickActivity = (
  destinationType,
  index,
  label,
  componentType = 'con',
) =>
  striptags(
    [
      componentType,
      index ?? '',
      destinationType || (label ? 'custom' : 'nocta'),
      label ?? 'null',
    ]
      .join(':')
      .replace('::', ':')
      .toLowerCase(),
  );

/**
 * Returns common analytics props
 * @param card prepared card data
 * @returns {{copyId: *, cardId: *, cardVersion: *, cardKey: Object.analytics.hashKey}}
 */
export const getCommonAnalyticsProps = card => ({
  [analyticsProperties.CARD_ID]: card.id,
  [analyticsProperties.CARD_KEY]: card.hashKey,
  [analyticsProperties.COPY_ID]: card.copyId,
  [analyticsProperties.COMPONENT_TYPE]:
    analyticsCardTypes[
      card.useFallback ? CARD_TYPES.TOP_TRENDING : card.containerType
    ],
  [analyticsProperties.LEGACY_COMPONENT_TYPE]:
    componentTypes[card.containerType],
});

/**
 * Returns placements for section headline object
 * @param {Object} card prepared card data
 * @returns {Object[]} Array of placements
 */
export const getSectionHeadlinePlacements = card =>
  card.sectionHeadline?.title
    ? [
        {
          isSectionHeadline: true, // this flag is required to modify placement id in analyticsDataComputationSaga
          ...getCommonAnalyticsProps(card),
          [analyticsProperties.CARD_ID]: card.id,
          [analyticsProperties.CTA_TITLE]: striptags(
            card.sectionHeadline.title,
          ),
        },
      ]
    : [];

const getSlidePlacement = (card, slide, tabName) => {
  const styleColor = slide.urlIngredients?.styleColor;

  const modelInfo = {};
  if (card.metadata?.modelId && card.metadata?.version) {
    modelInfo[analyticsProperties.MODEL_ID] = card.metadata.modelId;
    modelInfo[analyticsProperties.MODEL_VERSION] = card.metadata.version;
  }

  return {
    ...getCommonAnalyticsProps(card),
    [analyticsProperties.ASSET_ID]: slide.squarishId,
    [analyticsProperties.PRODUCT_ID]: slide.productId,
    [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(),
    [analyticsProperties.CTA_TITLE]: striptags(
      card.sectionHeadline?.title ?? '',
    ),
    [analyticsProperties.CURRENT_CURRENCY]: slide.currency,
    [analyticsProperties.CURRENT_PRICE]: slide.salePrice,
    [analyticsProperties.PRICING_STATUS]: slide.isOnSale
      ? 'reduced'
      : 'regular',
    [analyticsProperties.STYLE_COLOR]: styleColor,
    [analyticsProperties.PLACEMENT_GROUP]: tabName,
    ...modelInfo,
  };
};

/**
 * Returns placements for product containers including section headline
 * @param {Object} card prepared card data
 * @returns {Object[]} Array of placements
 */
export const getProductContainerPlacements = card => [
  ...getSectionHeadlinePlacements(card),
  ...(card.slides ?? []).map(slide =>
    getSlidePlacement(
      card,
      slide,
      card.slidesUpcoming?.length ? PRODUCT_AVAILABILITY.IN_STOCK : undefined,
    ),
  ),
  ...(card.slidesUpcoming ?? []).map(slideUpcoming =>
    getSlidePlacement(card, slideUpcoming, PRODUCT_AVAILABILITY.UPCOMING),
  ),
];

/**
 * Returns placements for gallery cards (filmstrip, carousel)
 * @param {Object} card prepared card data
 * @returns {Object[]} Array of placements
 */
export const getGalleryPlacements = card => [
  ...getSectionHeadlinePlacements(card),
  ...pipe(
    chain(slide =>
      cardMethods[slide.containerType]?.getAnalyticsPlacements?.(slide),
    ),
    map(
      pipe(
        assoc(
          analyticsProperties.LEGACY_COMPONENT_TYPE,
          componentTypes[card.containerType],
        ),
        // combining parent and child component types
        // c_c_x + c_x_i -> c_c_i
        over(
          lensProp(analyticsProperties.COMPONENT_TYPE),
          replace(
            /^.+(_[a-z])$/,
            `${analyticsCardTypes[card.containerType]?.replace(/_x$/, '')}$1`,
          ),
        ),
      ),
    ),
  )(card.featuredCard ? [card.featuredCard, ...card.slides] : card.slides),
];

/**
 * Returns actions for card link
 * @param {Object} card prepared card data
 * @returns {{id: string, c3: string}[]} Array of actions
 */
export const getCardLinkActions = card =>
  card.destinationType
    ? [
        {
          id: card.cardLinkId,
          [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
            card.destinationType,
            `0${card.isFeatured ? '_f' : ''}`,
          ),
        },
      ]
    : [];

/**
 * Returns actions for some array with links
 * @param {{id: string, destinationType: string, actionText: string}[]} actions
 * @param {number} startIndex starting inner placement
 * @param {boolean} isFeatured flag to insert _f for featured content
 * @returns {{id: string, c3: string}[]}
 */
export const getActions = (actions, startIndex = 0, isFeatured = false) => {
  const featuredPostfix = isFeatured ? '_f' : '';
  const getActionProps = (action, xIndex, yIndex) => ({
    id: action.id,
    [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
      action.destinationType,
      yIndex
        ? `${xIndex}_${yIndex}${featuredPostfix}`
        : `${xIndex}${featuredPostfix}`,
      action.actionText,
    ),
    [analyticsProperties.ACTION_KEY]: action?.analytics?.hashKey,
  });

  return chainIndexed(
    (action, xIndex) => [
      ...(action.actions?.length
        ? []
        : [getActionProps(action, xIndex + startIndex)]),
      ...mapIndexed(
        (nestedAction, yIndex) =>
          getActionProps(nestedAction, startIndex + xIndex, yIndex + 1),
        action.actions ?? [],
      ),
    ],
    actions,
  );
};

/**
 * Returns actions for gallery card including section headline actions
 * @param {Object} card prepared card data
 * @returns {Object[]}
 */
export const getGalleryActions = card => [
  ...getActions(card.sectionHeadline?.actions ?? []),
  ...pipe(
    chain(slide =>
      cardMethods[slide.containerType]?.getAnalyticsActions?.(slide),
    ),
    map(
      over(
        lensProp(analyticsProperties.CLICK_ACTIVITY),
        replace(
          /:null$/,
          card.sectionHeadline?.title
            ? `:${striptags(card.sectionHeadline.title.toLowerCase())}`
            : ':null',
        ),
      ),
    ),
  )(
    card.featuredCard
      ? [{ ...card.featuredCard, isFeatured: true }, ...card.slides]
      : card.slides,
  ),
];

/**
 * Returns actions for pagination
 * @param {Object} card prepared card data
 * @returns {Object[]}
 */
export const getPaginationActions = card => [
  ...range(1, (card?.pagination?.totalPages ?? 0) + 1).map(action => ({
    eventName: eventNames.NAVIGATION_CLICK,
    id: `pagination_${action}`,
    [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
      '',
      action,
      null,
      'pagination',
    ),
    [analyticsProperties.LOCAL_PLACEMENT_ID]: 1,
    [analyticsProperties.LOCAL_POSITION_ID]: action,
  })),
];
